import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { tick } from '@angular/core/testing';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import {
  ApexAnnotations,
  ApexChart,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
  XAxisAnnotations,
} from 'ng-apexcharts';
import { config } from 'rxjs';
import {
  ManagementService,
  RemainingDays,
  ReportsService,
  RoleBatteryService,
} from 'src/app/api/generated';
import { ModuleConfiguration } from 'src/app/app.module';
import { DisplayInfoImageComponent } from 'src/app/dialogs/display-image/display-info-image.component';
import { ReportModuleComponent } from 'src/app/report-container/report.component';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { ImageViewerComponent } from '../../image-viewer/component/image-viewer.component';
import { GenericRoleBatteryConfigComponent } from '../config/role-battery-config/role-battery-config.component';
@Component({
  selector: 'app-role-battery-generic',
  templateUrl: './role-battery.component.html',
  styleUrls: ['./role-battery.component.scss'],
})
export class GenericRoleBatteryComponent
  implements OnInit, ReportModuleComponent
{
  @Input() config;

  public static moduleDetails: ModuleConfiguration = {
    displayName: '[NEU] Rollenbatterie',
    previewImagePath: 'assets/role_battery_preview.png',
    settingsComponent: GenericRoleBatteryConfigComponent,
  };

  roleBatteryForm: UntypedFormGroup;
  marginDate: Date;
  remainingMargin: string;
  debug: any;

  public datasetsetName: string;
  annotationIDs = [];

  public chartTooltipOptions: ApexTooltip = {
    enabled: false,
  };

  public chartGrid: ApexGrid = {
    show: true,
    strokeDashArray: 1,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  };
  public chartConfig: ApexChart = {
    height: 500,
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  };
  public chartTitle: ApexTitleSubtitle = {
    text: '',
  };
  public chartSeries = [];
  public chartxAxis: ApexXAxis = {
    labels: {
      show: true,
      formatter: (val, opts) => {
        return `${Math.round(Number(val))} mm`;
      },
    },
    type: 'numeric',
    tickAmount: 'dataPoints',
    decimalsInFloat: 0,
    axisTicks: {
      show: true,
      borderType: 'solid',
    },
    title: {
      text: 'Spielraum in Millimeter',
    },
  };

  public chartyAxis: ApexYAxis = {
    min: 0,
    max: 10,
    labels: {
      show: false,
      formatter: (val, _) => {
        return val.toString();
      },
    },
  };
  public chartAnnotaions: ApexAnnotations;
  @ViewChild('chart') chartRef: ChartComponent;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private roleBatteryService: RoleBatteryService,
    private reportModulesService: ReportModulesService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private managementService: ManagementService,
    public reportModuleService: ReportModulesService,
    public userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.config);
    this.roleBatteryForm = this.formBuilder.group({
      measuringDateTime: [
        this.config.user_settings?.measuring_date
          ? new Date(this.config.user_settings?.measuring_date)
          : '',
        [Validators.required],
      ],
      measuredMargin: [
        this.config.user_settings?.measured_margin
          ? this.config.user_settings?.measured_margin
          : '',
        [
          Validators.required,
          Validators.pattern(/^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/),
        ],
      ],
      marginWarning: [
        this.config.user_settings?.margin_warning
          ? this.config.user_settings?.margin_warning
          : '',
        [Validators.pattern(/^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/)],
      ],
      marginDate: [{ value: '', disabled: true }],
      remainingMargin: [{ value: '', disabled: true }],
    });
    this.computeTime({ type: 'click' }, true);
    this.managementService
      .managementDatasourceDetailRead(this.config.dataset)
      .subscribe((res) => {
        this.datasetsetName = res.name;
      });
  }

  public computeTime(event, supressErrors = false) {
    if (event.keyCode == 13 || event.type === 'click') {
      if (this.roleBatteryForm.valid) {
        const measuredMargin = this.roleBatteryService
          .roleBatteryV2List(
            this.datePipe.transform(
              this.roleBatteryForm.controls['measuringDateTime'].value,
              'full',
              undefined,
              'en-US'
            ),
            this.toNumberStringEN(
              this.roleBatteryForm.controls['measuredMargin'].value
            ),
            this.config.dataset
          )
          .subscribe(
            (res) => {
              this.updateTime(res);
            },
            (error) => {
              if (!supressErrors) {
                this.snackBar.open(error.error.detail, 'OK', {
                  duration: 2000,
                });
              }
            }
          );
        this.reportModulesService.updateUserSettings(
          {
            measuring_date: this.datePipe.transform(
              this.roleBatteryForm.controls['measuringDateTime'].value,
              'shortDate',
              undefined,
              'en-US'
            ),
            measured_margin:
              this.roleBatteryForm.controls['measuredMargin'].value,
            margin_warning:
              this.roleBatteryForm.controls['marginWarning'].value,
          },
          this.config.guid
        );
      } else {
        if (!supressErrors) {
          this.snackBar.open('Formular nicht vollständig ausgefüllt', 'OK', {
            duration: 3000,
          });
        }
      }
    }
  }

  private updateTime(computedMargin: RemainingDays) {
    this.debug = computedMargin;
    // this.daysRemaining = computedMargin.remaining_days;
    this.marginDate = new Date(
      Date.now() + 3600 * 1000 * 24 * Number(computedMargin.remaining_days)
    );

    this.remainingMargin = computedMargin.remaining_margin;
    this.initChart(computedMargin);
  }

  public get remainingMarginNegative(): boolean {
    return this.marginDate < new Date(Date.now());
  }

  public get secondErrorMessage(): boolean {
    const measuredMargin = Number(
      this.toNumberStringEN(
        this.roleBatteryForm.controls['measuredMargin'].value
      )
    );
    let rv: boolean =
      (measuredMargin >= 0 &&
        (Number(this.remainingMargin) > measuredMargin ||
          measuredMargin < 0)) ||
      (measuredMargin <= 0 &&
        (Number(this.remainingMargin) < measuredMargin || measuredMargin > 0));
    return rv;
  }

  public get dateNow(): Date {
    return new Date(Date.now());
  }
  public get marginReached() {
    return (
      this.roleBatteryForm.controls['marginWarning'].value &&
      Math.abs(Number(this.remainingMargin)) <=
        Math.abs(
          this.toNumber(this.roleBatteryForm.controls['marginWarning'].value)
        )
    );
  }
  private toNumberStringEN(number: string): string {
    const n = number.replace(/\./g, '').replace(',', '.');
    return parseFloat(n).toString();
  }

  private toNumberStringDE(number: string): string {
    const n = number.replace(/\,/g, '').replace('.', ',');
    return parseFloat(n).toString();
  }

  private toNumber(number: string): number {
    const n = number.replace(/\./g, '').replace(',', '.');
    return Number(n);
  }

  private initChart(computedMargin: RemainingDays) {
    while (this.annotationIDs.length > 0) {
      this.chartRef.removeAnnotation(this.annotationIDs.pop());
    }
    // this.chartRef.clearAnnotations();
    this.chartSeries.length = 0;
    const measuredMargin = Number(
      this.toNumberStringEN(
        this.roleBatteryForm.controls['measuredMargin'].value
      )
    );

    let chartLowerBound: number;
    let chartUpperBound: number;
    if (Math.sign(measuredMargin) === -1) {
      // prettier-ignore
      chartLowerBound = Math.sign(measuredMargin) * Math.ceil(Math.abs(measuredMargin) / 10) * 10 - 20;
      chartUpperBound = 20;
    } else {
      // prettier-ignore
      chartUpperBound = Math.sign(measuredMargin) * Math.ceil(Math.abs(measuredMargin) / 10) * 10 + 20;
      chartLowerBound = -20;
    }

    const chartData = [];
    // this.chartxAxis.min = chartLowerBound;
    // this.chartxAxis.max = chartUpperBound;
    chartData.push({
      x: chartLowerBound,
      y: 0,
    });
    chartData.push({
      x: chartUpperBound,
      y: 0,
    });

    // prettier-ignore
    const tickAmount = (Math.abs(chartUpperBound) + Math.abs(chartLowerBound)) / 10;
    // Fill data array so the tick amount is computed right.
    // Setting the tick amount manually does not work
    // this.chartxAxis.tickAmount = xy;
    for (let i = 0; i < tickAmount - 1; i++) {
      chartData.push({
        x: 0,
        y: 0,
      });
    }

    let xMargin = Number(computedMargin.remaining_margin);
    let xGrenzwert = Number(
      this.toNumberStringEN(
        this.roleBatteryForm.controls['marginWarning'].value
      )
    );
    this.addAnotation(measuredMargin, '', 0, 'green', true);
    this.addAnotation(xMargin, '', 0, 'blue', true);
    this.addAnotation(xGrenzwert, '', 0, 'orange', true);
    this.addAnotation(0, '', 0, 'black', true);

    this.chartSeries.push({
      data: chartData,
      name: 'Rollenbatterie',
      color: '#FFFFFF',
    });

    // Add measuring datetime
    this.addAnotation(
      measuredMargin,
      `${this.datePipe.transform(
        this.roleBatteryForm.controls['measuringDateTime'].value,
        'dd.MM.yyyy',
        undefined,
        'de-AT'
      )}`,
      50,
      'green',
      false
    );
    this.addAnotation(measuredMargin, `Messzeitpunkt`, 75, 'green', false);

    // Current margin
    this.addAnotation(
      xMargin,
      `${this.datePipe.transform(
        new Date(Date.now()),
        'dd.MM.yyyy',
        undefined,
        'de-AT'
      )}`,
      100,
      'blue',
      false
    );

    this.addAnotation(xMargin, `Aktuell`, 125, 'blue', false);

    // Add Grenzwert

    this.addAnotation(
      xGrenzwert,
      `${this.datePipe.transform(
        this.marginDate,
        'dd.MM.yyyy',
        undefined,
        'de-AT'
      )}`,
      150,
      'orange',
      false
    );

    this.addAnotation(xGrenzwert, 'Warnwert', 175, 'orange', false);

    this.chartRef.render();
  }

  private addAnotation(
    xPos: Number | string,
    text: string,
    offsetY: Number,
    color: string,
    border: boolean
  ) {
    // let id = this.annotationIDs.length || 0;
    let id = 'id';
    this.chartRef.addXaxisAnnotation(<any>{
      id: id,
      strokeDashArray: 0,
      x: xPos,
      label: {
        text: text,
        orientation: 'horizontal',
        offsetY: offsetY,
        style: {
          fontSize: '1rem',
          background: color,
          color: 'white',
        },
        // textAnchor: 'start',
      },
      borderWidth: 5,
      borderColor: border ? color : 'rgba(0,0,0,0)',
    });
    this.annotationIDs.push(id);
    return id;
  }

  openInfoDialog() {
    this.dialog.open(DisplayInfoImageComponent);
  }
}
