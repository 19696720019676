import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { PrivateComponent } from './private/private.component';
import { PublicComponent } from './public/public.component';
import { ReportContainerComponent } from './report-container/report-container.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrthofotoComponent } from './modules/orthofoto/component/orthofoto.component';
import { ReportDirective } from './report-container/report.directive';
import { ReportModuleLoaderComponent } from './report-module-loader/report-module-loader.component';
import { ReportModulesService } from './services/report-modules/report-modules.service';
import proj4 from 'proj4';
import { RoleBatteryComponent } from './modules/role-battery/component/role-battery.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';

import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { ImageViewerComponent } from './modules/image-viewer/component/image-viewer.component';
import { MarkdownModuleComponent } from './modules/markdown-module/component/markdown-module.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { CorrelationComponent } from './modules/correlation/component/correlation.component';
import { NgChartsModule } from 'ng2-charts';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { SettingsDialogComponent } from './dialogs/settings-dialog/settings-dialog.component';
import { ProjectDialogComponent } from './dialogs/project-dialog/project-dialog.component';
import { ReportDialogComponent } from './dialogs/report-dialog/report-dialog.component';
import { ClientDialogComponent } from './dialogs/client-dialog/client-dialog.component';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/legacy-dialog';
import { AddSelectDialogComponent } from './dialogs/add-select-dialog/add-select-dialog.component';
import { BaseDialogComponent } from './dialogs/base-dialog/base-dialog.component';
import { OrthofotoConfigComponent } from './modules/orthofoto/config/orthofoto-config.component';
import { RoleBatteryConfigComponent } from './modules/role-battery/config/role-battery-config/role-battery-config.component';
import { ImageViewerConfigComponent } from './modules/image-viewer/config/image-viewer-config/image-viewer-config.component';
import { CorrelationConfigComponent } from './modules/correlation/config/correlation-config/correlation-config.component';
import { MarkdownModuleConfigComponent } from './modules/markdown-module/config/markdown-module-config/markdown-module-config.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DatasourceConfigComponent } from './datasource/datasource-config/datasource-config.component';
import { DatasourceConfigDialogComponent } from './datasource/datasource-config-dialog/datasource-config-dialog.component';

import { register } from 'ol/proj/proj4';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { UserConfigComponent } from './user/user-config/user-config.component';
import { UserConfigDialogComponent } from './user/user-config-dialog/user-config-dialog.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { GlobalHttpInterceptor } from './error-handler/global-http-interceptor.service';
import { NgApexchartsModule } from 'ng-apexcharts';

import * as moment from 'moment';
import { DisplayInfoImageComponent } from './dialogs/display-image/display-info-image.component';
import { CorrelationFissurometerComponent } from './modules/correlation_fissurometer/component/correlation_fissurometer.component';
import { CorrelationFissurometerConfigComponent } from './modules/correlation_fissurometer/config/correlation_fissurometer-config/correlation_fissurometer-config.component';
import { MovementScatterplotComponent } from './modules/movement_scatterplot/component/movement_scatterplot.component';
import { MovementScatterplotConfigComponent } from './modules/movement_scatterplot/config/movement_scatterplot-config/movement_scatterplot-config.component';
import { InverseVelocityComponent } from './modules/inverse_velocity/component/inverse_velocity.component';
import { InverseVelocityConfigComponent } from './modules/inverse_velocity/config/inverse_velocity-config/inverse_velocity-config.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsClientsConfigComponent } from './settings_components/projects-clients-config/projects-clients-config.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSortModule } from '@angular/material/sort';
import { ClientsConfigComponent } from './settings_components/clients-config/clients-config.component';
import { WebcamComponent } from './modules/webcam/component/webcam.component';
import { WebcamConfigComponent } from './modules/webcam/config/webcam-config/webcam-config.component';
import { GenericCorrelationComponent } from './modules/correlation_generic/component/correlation.component';
import { GenericCorrelationConfigComponent } from './modules/correlation_generic/config/correlation-config/correlation-config.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GenericOrthofotoComponent } from './modules/orthofoto_generic/component/orthofoto.component';
import { GenericOrthofotoConfigComponent } from './modules/orthofoto_generic/config/orthofoto-config.component';
import { GenericMovementScatterplotComponent } from './modules/movement_scatterplot_generic/component/movement_scatterplot.component';
import { GenericMovementScatterplotConfigComponent } from './modules/movement_scatterplot_generic/config/movement_scatterplot-config/movement_scatterplot-config.component';
import { MinMaxOrdinateFilter } from './user/tools';
import { GenericRoleBatteryComponent } from './modules/role-battery_generic/component/role-battery.component';
import { GenericRoleBatteryConfigComponent } from './modules/role-battery_generic/config/role-battery-config/role-battery-config.component';
moment.locale('de-AT');

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface ModuleConfiguration {
  displayName: string;
  settingsComponent: any;
  previewImagePath: string;
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.issuer,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
      loadUserProfileAtStartUp: true,
      shouldAddToken: (request) => {
        // Add token only on requests to our own backend
        return request.url.includes(environment.apiEndpoint);
      },
    });
}

function initializeReportModules(reportModuleService: ReportModulesService) {
  return () => {
    reportModuleService.registerModule(WebcamComponent, 'webcam');
    reportModuleService.registerModule(OrthofotoComponent, 'orthophoto');
    reportModuleService.registerModule(
      GenericOrthofotoComponent,
      'orthophoto-generic'
    );
    reportModuleService.registerModule(RoleBatteryComponent, 'role-battery');
    reportModuleService.registerModule(
      GenericRoleBatteryComponent,
      'role-battery-generic'
    );
    reportModuleService.registerModule(ImageViewerComponent, 'image-viewer');
    reportModuleService.registerModule(MarkdownModuleComponent, 'markdown');
    reportModuleService.registerModule(CorrelationComponent, 'correlation');
    reportModuleService.registerModule(
      GenericCorrelationComponent,
      'correlation-generic'
    );
    reportModuleService.registerModule(
      CorrelationFissurometerComponent,
      'correlation_fissurometer'
    );
    reportModuleService.registerModule(
      MovementScatterplotComponent,
      'movement_scatterplot'
    );
    reportModuleService.registerModule(
      GenericMovementScatterplotComponent,
      'movement_scatterplot_generic'
    );
    reportModuleService.registerModule(
      InverseVelocityComponent,
      'inverse_velocity'
    );
  };
}

registerLocaleData(localeDe, 'de');

// Register projections
proj4.defs(
  'EPSG:3857',
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
);

// West
// Source: https://epsg.io/31254
proj4.defs(
  'EPSG:31254',
  '+proj=tmerc +lat_0=0 +lon_0=10.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs'
);

// Center
proj4.defs(
  'EPSG:31255',
  '+proj=tmerc +lat_0=0 +lon_0=13.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs'
);

// East
proj4.defs(
  'EPSG:31256',
  '+proj=tmerc +lat_0=0 +lon_0=16.33333333333333 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs'
);

register(proj4);

@NgModule({
  declarations: [
    AppComponent,
    PrivateComponent,
    PublicComponent,
    ReportContainerComponent,
    OrthofotoComponent,
    ReportDirective,
    ReportModuleLoaderComponent,
    RoleBatteryComponent,
    GenericRoleBatteryComponent,
    ImageViewerComponent,
    MarkdownModuleComponent,
    CorrelationComponent,
    SideNavbarComponent,
    SettingsDialogComponent,
    ProjectDialogComponent,
    ReportDialogComponent,
    ClientDialogComponent,
    AddSelectDialogComponent,
    BaseDialogComponent,
    OrthofotoConfigComponent,
    RoleBatteryConfigComponent,
    GenericRoleBatteryConfigComponent,
    ImageViewerConfigComponent,
    CorrelationConfigComponent,
    MarkdownModuleConfigComponent,
    ConfirmationDialogComponent,
    DatasourceConfigComponent,
    DatasourceConfigDialogComponent,
    SettingsPageComponent,
    UserConfigComponent,
    UserConfigDialogComponent,
    NotFoundComponent,
    DisplayInfoImageComponent,
    CorrelationFissurometerComponent,
    CorrelationFissurometerConfigComponent,
    MovementScatterplotComponent,
    MovementScatterplotConfigComponent,
    InverseVelocityComponent,
    InverseVelocityConfigComponent,
    ProjectsClientsConfigComponent,
    ClientsConfigComponent,
    WebcamComponent,
    WebcamConfigComponent,
    GenericCorrelationComponent,
    GenericCorrelationConfigComponent,
    GenericOrthofotoComponent,
    GenericOrthofotoConfigComponent,
    GenericMovementScatterplotComponent,
    GenericMovementScatterplotConfigComponent,
    MinMaxOrdinateFilter,
  ],
  imports: [
    MatInputModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    KeycloakAngularModule,
    MatDialogModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSliderModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    LMarkdownEditorModule,
    NgApexchartsModule,
    NgChartsModule,
    NgbModule,
    DragDropModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeReportModules,
      multi: true,
      deps: [ReportModulesService],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
    { provide: LOCALE_ID, useValue: 'de-AT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },

    DatePipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
