<h2 *ngIf="!editMode" mat-dialog-title>Modul Rollenbatterie hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>Modul Rollenbatterie editieren</h2>
<mat-dialog-content>
  <div>
    <form
      [formGroup]="roleBatteryForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" required />
      </mat-form-field>

      <mat-form-field>
        <mat-select required formControlName="datasource">
          <mat-option
            matInput
            *ngFor="let datasource of availableDataSources"
            [value]="datasource.id"
            >{{ datasource.name }}</mat-option
          >
        </mat-select>
        <mat-label>Datenquelle</mat-label>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!roleBatteryForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
