import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { concatMap, delay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Clients, Projects, Report } from './api/generated';

export interface ReportConfigChanged {
  reportID: string;
  moduleID: string;
}

export enum HEADLINE_TYPE {
  REPORT,
  GENERIC,
}

export interface HeadlineChanged {
  type: HEADLINE_TYPE;
  headline: string;
  image?: string;
  subheadline?: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class DataChangedService {
  private dataChanged = new Subject<any>();
  private reportConfigChanged = new Subject<ReportConfigChanged>();
  private adminConfigChanged = new Subject<any>();
  private _currentClientsWithProjects: Clients[] = [];

  private _headline = new ReplaySubject<HeadlineChanged>();

  constructor() {
    // Set default Text
    this._headline.next({
      headline: 'Willkommen',
      image: null,
      type: HEADLINE_TYPE.GENERIC,
    });
  }

  configUpdated(reportID: string, moduleID: string) {
    this.reportConfigChanged.next({
      reportID: reportID,
      moduleID: moduleID,
    });
  }

  adminConfigChangedObservable(): Observable<any> {
    return this.adminConfigChanged.asObservable();
  }

  adminConfigUpdated(): void {
    this.adminConfigChanged.next({});
  }

  configChangedObservable(): Observable<ReportConfigChanged> {
    return this.reportConfigChanged.asObservable();
  }

  dataUpdated(message: string) {
    this.dataChanged.next({ detail: message });
  }

  getData(): Observable<any> {
    return this.dataChanged.asObservable();
  }

  /**
   * @returns Headline changed as observable
   */
  get healdine(): Observable<HeadlineChanged> {
    // Make sure change dedection is working
    // https://github.com/angular/angular/issues/16921
    return from(this._headline).pipe(
      concatMap((item) => of(item).pipe(delay(0)))
    );
  }

  /**
   * Sets the observable next value according to HEADLINE_TYPE
   * @param headline
   * @returns
   */
  async setHeadline(headline: HeadlineChanged) {
    if (headline.type === HEADLINE_TYPE.GENERIC) {
      this._headline.next(headline);
    } else if (headline.type === HEADLINE_TYPE.REPORT) {
      // Await race condition when user is deep-linked to report
      while (true) {
        if (this._currentClientsWithProjects.length > 0) {
          break;
        }
        await timer(100).pipe(take(1)).toPromise();
      }
      const [client, project, report] = this.getProjectAndClientForReport(
        headline.headline
      );

      // Check if function found Client, Project and Report
      // Return if not, otherwise application is crashing
      if ([client, project, report].some((e) => e === null)) {
        console.error('Null values in clientsWithProjects');
        return;
      }

      const headlineFormatted = `${report.name}`;

      const imagePath = `${client.logo_path}`;

      this._headline.next({
        headline: headlineFormatted,
        type: HEADLINE_TYPE.REPORT,
        image: imagePath,
        subheadline: [client.name, project.name, report.name],
      });
    }
  }

  /**
   * Sets the current clients with projects value
   * @param Clients[] List of clients
   */
  set currentClients(clients: Clients[]) {
    this._currentClientsWithProjects = clients;
  }

  /**
   * Finds the Client and Project the Report with reportID belongs to
   * null for each otherwise
   * @param reportID string
   * @returns [Clients, Projects, Report] instance of each
   */
  public getProjectAndClientForReport(
    reportID: string
  ): [Clients, Projects, Report] {
    for (let client of this._currentClientsWithProjects) {
      for (let project of client.projects) {
        for (let report of project.reports) {
          if (reportID === report.id) {
            return [client, project, report];
          }
        }
      }
    }
    return [null, null, null];
  }
}
