<h1 mat-dialog-title>{{ data.headlineText }}</h1>
<div mat-dialog-content>
  <p>{{ data.displayText }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-button [mat-dialog-close]="false">
    {{ data.rejectButtonText }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ data.confirmationButtonText }}
  </button>
</div>
