<h2 *ngIf="!editMode" mat-dialog-title>[NEU] Modul Vektorgraphik hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>[NEU] Modul Vektorgraphik editieren</h2>
<mat-dialog-content>
  <div>
    <form [formGroup]="orthofotoForm" (ngSubmit)="updateOrCreateModule()" ngNativeValidate class="d-flex flex-column">
      <!-- <pre>Form values: {{ orthofotoForm?.value | json }}</pre> -->

      <h5 class="">Allgemeine Konfiguration</h5>
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" required />
        <mat-error *ngIf="orthofotoForm.controls.headline.hasError('required')">
          Überschrift angeben
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle formControlName="timePeriodFiltered">Zeitraum filtern</mat-slide-toggle>

      <mat-form-field>
        <mat-label>Startzeit</mat-label>
        <input [attr.disabled]="timeFilterDisabled" matInput formControlName="timePeriodFilteredFrom" />
        <mat-error *ngIf="
            orthofotoForm.controls.timePeriodFilteredFrom.hasError('validTime')
          ">
          Zeitraum im Format [hh:mm] angeben
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Endzeit</mat-label>
        <input [attr.disabled]="timeFilterDisabled" matInput formControlName="timePeriodFilteredTill" />
        <mat-error *ngIf="
            orthofotoForm.controls.timePeriodFilteredTill.hasError('validTime')
          ">
          Zeitraum im Format [hh:mm] angeben
        </mat-error>
      </mat-form-field>

      <h5 class="mt-4">Datenquellen</h5>
      <mat-checkbox class="mb-2" formControlName="allDatasourcesChecked">
        Alle auswählen
      </mat-checkbox>
      <div class="list-group">
        <ng-container formArrayName="datasources">
          <div class="list-group-item" *ngFor="let datasource of availableDataSources; let i = index"
            [formGroupName]="i">
            <mat-checkbox formControlName="checked">
              {{ datasource.display_name }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <h5 class="mt-12">Definition Farben</h5>

      <mat-form-field>
        <mat-label>Zeitraum für Farbänderung Sensor ohne Messung</mat-label>
        <input type="number" matInput formControlName="differenceHours" />
      </mat-form-field>
      <p class="mb-2">
        <a target="_blank" href="https://html-color-codes.info/color-names/">Valide HTML Farbe
          <mat-icon inline="true"> arrow_outward </mat-icon></a>
        oder
        <a target="_blank" href="https://html-color-codes.info/">HEX Code <mat-icon inline="true"> arrow_outward
          </mat-icon></a>
      </p>
      <mat-form-field>
        <mat-label>Farbe Messwerte aktuell</mat-label>
        <input matInput formControlName="colorOk" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Farbe Sensor ohne Messung</mat-label>
        <input matInput formControlName="colorWarning" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Farbe Verschiebung Höhe</mat-label>
        <input matInput formControlName="colorHeightVector" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Farbe Verschiebung 2D</mat-label>
        <input matInput formControlName="color2dVector" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Skalierungsfaktor Maßstab [%]</mat-label>
        <input matInput formControlName="scaleSizeFactor" type="number" />
        <span matSuffix>%</span>
      </mat-form-field>

      <h5 class="mt-4">Bahnachsen</h5>
      <div class="user-selection-group">
        <div *ngFor="let axis of axisFromFormGroup().controls" class="row row-cols-1 row-cols-sm-2" [formGroup]="axis">
          <!-- X1 -->
          <mat-form-field class="col">
            <mat-label>Rechtswert 1</mat-label>
            <input matInput formControlName="x1" />
            <mat-error *ngIf="axis.controls.x1.hasError('required')">
              Feld wird benötigt
            </mat-error>
          </mat-form-field>

          <!-- Y1 -->
          <mat-form-field class="col">
            <mat-label>Hochwert 1</mat-label>
            <input matInput formControlName="y1" />
            <mat-error *ngIf="axis.controls.y1.hasError('required')">
              Feld wird benötigt
            </mat-error>
          </mat-form-field>
          <!-- X2 -->
          <mat-form-field class="col">
            <mat-label>Rechtswert 2</mat-label>
            <input matInput formControlName="x2" />
            <mat-error *ngIf="axis.controls.x2.hasError('required')">
              Feld wird benötigt
            </mat-error>
          </mat-form-field>

          <!-- Y2 -->
          <mat-form-field class="col">
            <mat-label>Hochwert 2</mat-label>
            <input matInput formControlName="y2" />
            <mat-error *ngIf="axis.controls.y2.hasError('required')">
              Feld wird benötigt
            </mat-error>
          </mat-form-field>

          <!-- Bahnachse Text -->
          <mat-form-field class="col">
            <mat-label>Anzeigetext</mat-label>
            <input matInput formControlName="text" type="text" />
            <mat-error *ngIf="axis.controls.text.hasError('required')">
              Feld wird benötigt
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row justify-content-center">
          <button mat-mini-fab (click)="addAxis($event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <button mat-raised-button color="primary" type="submit" [disabled]="!orthofotoForm.valid">
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>