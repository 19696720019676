<form [formGroup]="reportForm" (ngSubmit)="updateOrCreateReport()">
  <mat-dialog-content>
    <div>
      <div>
        <h2 *ngIf="!editMode" mat-dialog-title>Report anlegen</h2>
        <h2 *ngIf="editMode" mat-dialog-title>Report editieren</h2>
        <!-- <h4 *ngIf="!data.id">Neues Projekt anlegen</h4>
    <h4 *ngIf="data.id">Projekt {{ data.name }} editieren</h4> -->
        <mat-divider></mat-divider>

        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Projekt</mat-label>
          <mat-select formControlName="projects" required>
            <mat-option
              matInput
              *ngFor="let project of availableProjects"
              [value]="project.id"
              >{{ project.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <span>Reihenfolge Module:</span>
    <div
      cdkDropList
      class="reordering-modules-list w-full"
      (cdkDropListDropped)="reorderElementsDropEvent($event)"
    >
      <div
        class="reodering-list-box w-full"
        *ngFor="let mir of modulesInReport"
        cdkDrag
      >
        <div class="reodering-list-label w-full" *cdkDragPlaceholder></div>
        <span
          >{{ displayNamesModules.get(mir.type) }}:
          <b>{{ mir.headline }}</b></span
        >
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!reportForm.valid"
    >
      <ng-template [ngIf]="data.id && !editMode">Report anlegen</ng-template>
      <ng-template [ngIf]="editMode">Speichern</ng-template>
    </button>
    <button mat-raised-button *ngIf="editMode" (click)="deleteReport($event)">
      {{ currentReport.name }} löschen
    </button>
    <button mat-raised-button [mat-dialog-close]="true">Schließen</button>
  </mat-dialog-actions>
</form>
