<form [formGroup]="userForm" (ngSubmit)="updateOrCreateUser()" ngNativeValidate>
  <h2 *ngIf="!editMode" mat-dialog-title>Benutzer hinzufügen</h2>
  <h2 *ngIf="editMode" mat-dialog-title>Benutzer editieren</h2>
  <mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email" required />
        <mat-error *ngIf="userForm.controls.email.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput formControlName="firstName" required />
        <mat-error *ngIf="userForm.controls.firstName.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput formControlName="lastName" required />
        <mat-error *ngIf="userForm.controls.lastName.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-checkbox formControlName="admin"> Administrator </mat-checkbox>
      <mat-error *ngIf="userForm.controls.admin.hasError('required')"
        >Feld wird benötigt</mat-error
      >
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!userForm.valid"
    >
      <ng-template [ngIf]="!data?.id">Benutzer anlegen</ng-template>
      <ng-template [ngIf]="data?.id">Speichern</ng-template>
    </button>
    <button
      type="button"
      mat-raised-button
      (click)="resetPassword()"
      *ngIf="editMode"
    >
      Passwort zurücksetzen
    </button>
    <button
      type="button"
      mat-raised-button
      (click)="deleteUser()"
      *ngIf="editMode"
    >
      Benutzer löschen
    </button>
    <button mat-raised-button [mat-dialog-close]="true">Schließen</button>
  </mat-dialog-actions>
</form>
