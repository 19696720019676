import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ManagementService, TrigonosDatasource } from 'src/app/api/generated';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { AxisConfig, OrthofotoConfig } from '../orthophoto.tools';
import * as uuid from 'uuid';
import { DataChangedService } from 'src/app/data-changed.service';
import { validTime } from 'src/app/tools/validators';

@Component({
  selector: 'app-orthofoto-config',
  templateUrl: './orthofoto-config.component.html',
  styleUrls: ['./orthofoto-config.component.scss'],
})
export class OrthofotoConfigComponent implements OnInit {
  public orthofotoForm: UntypedFormGroup;
  public availableDataSources: TrigonosDatasource[] = [];
  public editMode = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrthofotoConfig,
    private dialogRef: MatDialogRef<OrthofotoConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    this.managementService
      .managementDatasourceReportRead(this.reportModuleService.currentReport.id)
      .subscribe((datasources) => {
        this.availableDataSources = datasources;
        // New module is created
        if (!this.data?.guid) {
          this.availableDataSources.forEach((datasource) => {
            this.datasourcesFromFormGroup().push(new UntypedFormControl(false));
          });
        } else {
          this.availableDataSources.forEach((datasource) => {
            const enabled = this.data.dataset.indexOf(datasource.id) !== -1;
            this.datasourcesFromFormGroup().push(new UntypedFormControl(enabled));
          });
        }
        // @ToDo else case when module is edited
        // Check if datasource is already in report config
      });
    this.orthofotoForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data.guid : uuid.v4()],
      type: ['orthophoto'],
      reportID: [
        this.reportModuleService.currentReport.id,
        Validators.required,
      ],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      datasources: this.formBuilder.array([]),
      timePeriodFiltered:
        this.data?.filter.enabled !== undefined
          ? this.data?.filter.enabled
          : true,
      timePeriodFilteredFrom: [
        this.data?.filter.from ? this.data?.filter.from : '22:00',
        [Validators.required, validTime()],
      ],
      timePeriodFilteredTill: [
        this.data?.filter.till ? this.data?.filter.till : '06:00',
        [Validators.required, validTime()],
      ],
      differenceHours: [
        this.data?.dynamic_center_point.difference_hours
          ? this.data?.dynamic_center_point.difference_hours
          : 6,
        [Validators.required],
      ],
      colorOk: [
        this.data?.dynamic_center_point.color_ok
          ? this.data?.dynamic_center_point.color_ok
          : 'blue',
        [Validators.required],
      ],
      colorWarning: [
        this.data?.dynamic_center_point.color_warning
          ? this.data?.dynamic_center_point.color_warning
          : 'red',
        [Validators.required],
      ],
      colorHeightVector: [
        this.data?.color_height_vector
          ? this.data?.color_height_vector
          : 'yellow',
        [Validators.required],
      ],
      color2dVector: [
        this.data?.color_2d_vector ? this.data?.color_2d_vector : 'red',
        [Validators.required],
      ],
      scaleSizeFactor: [
        this.data?.scale_size_factor ? this.data?.scale_size_factor : 100,
        [Validators.required],
      ],
      axis: this.formBuilder.array([]),
    });

    for (let axis of this.data.axis) {
      this.axisFromFormGroup().push(
        this.formBuilder.group({
          x1: [axis.x1, [Validators.required]],
          y1: [axis.y1, [Validators.required]],
          x2: [axis.x2, [Validators.required]],
          y2: [axis.y2, [Validators.required]],
          text: [axis.text, [Validators.required]],
        })
      );
    }
  }

  public updateOrCreateModule() {
    let datasets = this.orthofotoForm.value.datasources
      .map((enabled, index) => {
        if (enabled) {
          return this.availableDataSources[index].id;
        }
      })
      .filter(Boolean);

    if (this.orthofotoForm.valid) {
      let axis: AxisConfig[] = this.axisFromFormGroup().controls.map(
        (control) => {
          return {
            x1: this.toNumber(control.get('x1').value),
            y1: this.toNumber(control.get('y1').value),
            x2: this.toNumber(control.get('x2').value),
            y2: this.toNumber(control.get('y2').value),
            text: control.get('text').value,
          };
        }
      );
      this.reportModuleService
        .addOrEditModuleToReport(
          <OrthofotoConfig>{
            guid: this.orthofotoForm.value.guid,
            type: this.orthofotoForm.value.type,
            headline: this.orthofotoForm.value.headline,
            dataset: datasets,
            filter: {
              enabled: this.orthofotoForm.value.timePeriodFiltered,
              from: this.orthofotoForm.value.timePeriodFilteredFrom,
              till: this.orthofotoForm.value.timePeriodFilteredTill,
            },
            user_settings: {
              selected_date: null,
            },
            axis: axis || [],
            dynamic_center_point: {
              difference_hours: this.orthofotoForm.value.differenceHours,
              color_ok: this.orthofotoForm.value.colorOk,
              color_warning: this.orthofotoForm.value.colorWarning,
            },
            color_2d_vector: this.orthofotoForm.value.color2dVector,
            color_height_vector: this.orthofotoForm.value.colorHeightVector,
            scale_size_factor: this.orthofotoForm.value.scaleSizeFactor,
          },
          this.editMode
        )
        .subscribe(
          (success) => {
            this.dialogRef.close();
            this.dataChangedService.configUpdated(
              this.reportModuleService.currentReport.id,
              this.orthofotoForm.value.guid
            );
          },
          (error) => {}
        );
    }
  }

  addAxis(event): void {
    this.axisFromFormGroup().push(
      this.formBuilder.group({
        x1: ['', [Validators.required]],
        y1: ['', [Validators.required]],
        x2: ['', [Validators.required]],
        y2: ['', [Validators.required]],
        text: ['', [Validators.required]],
      })
    );
  }

  get timeFilterDisabled(): string | null {
    return this.orthofotoForm.get('timePeriodFiltered').value ? null : '';
  }

  private datasourcesFromFormGroup(): UntypedFormArray {
    return this.orthofotoForm.get('datasources') as UntypedFormArray;
  }

  public axisFromFormGroup(): UntypedFormArray {
    return this.orthofotoForm.get('axis') as UntypedFormArray;
  }

  private toNumber(number: string): number {
    let n = number;
    try {
      n = number.replace(',', '.');
    } catch {}
    return Number(n);
  }
}
