import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ManagementService,
  Projects,
  Report,
  TrigonosProjectDetail,
  TrigonosReportDetail,
} from 'src/app/api/generated';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

declare module ReportDialogSettings {
  interface UserSettings {
    measuring_date: string;
    measured_margin: string;
    margin_warning: string;
  }

  interface Config {
    guid: string;
    type: string;
    dataset: string;
    headline: string;
    user_settings: UserSettings;
  }

  interface Report {
    id: string;
    name: string;
    config: Config[];
    project: string;
  }

  interface Project {
    id: string;
    name: string;
    description: string;
    transformation_from: string;
    transformation_to: string;
    assigned_client: string;
    reports: Report[];
  }
}

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss'],
})
export class ReportDialogComponent implements OnInit {
  public reportForm: UntypedFormGroup;
  public availableProjects: TrigonosProjectDetail[] = [];
  public editMode = false;
  public currentReport: Report;
  public modulesInReport: ReportDialogSettings.Config[] = [];
  public displayNamesModules: Map<string, string> = new Map();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private router: Router,
    private reportModuleService: ReportModulesService,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.data?.mode === 'edit') {
      this.editMode = true;
      this.currentReport = this.reportModuleService.currentReport;
      this.modulesInReport = this.currentReport
        .config as ReportDialogSettings.Config[];

      for (let module of this.modulesInReport) {
        this.displayNamesModules.set(
          module.type,
          this.displayNameForModule(module)
        );
      }

      console.log(this.displayNamesModules);
    }
    this.data = this.data?.data;
    this.reportForm = this.formBuilder.group({
      name: [this.editMode ? this.currentReport.name : '', Validators.required],
      projects: new UntypedFormControl(),
    });

    if (this.data.assigned_client) {
      this.managementService
        .managementProjectsForClientRead(this.data.assigned_client)
        .subscribe((projects) => {
          this.availableProjects = projects;
          this.reportForm.get('projects').setValue(this.data.id);
        });
    }
  }

  displayNameForModule(module: ReportDialogSettings.Config) {
    let component = this.reportModuleService.getModule(module.type);
    return (component as any).moduleDetails.displayName;
  }

  reorderElementsDropEvent(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.modulesInReport,
      event.previousIndex,
      event.currentIndex
    );
  }

  public updateOrCreateReport() {
    let updateOrCreateObservable: Observable<TrigonosReportDetail>;
    if (this.editMode) {
      updateOrCreateObservable = this.managementService.managementReportUpdate(
        this.currentReport.id,
        <Report>{
          name: this.reportForm.get('name').value,
          project: this.reportForm.get('projects').value,
          config: this.modulesInReport,
        }
      );
    } else {
      updateOrCreateObservable =
        this.managementService.managementReportCreateCreate(<Report>{
          name: this.reportForm.get('name').value,
          project: this.reportForm.get('projects').value,
          config: [],
        });
    }

    updateOrCreateObservable.subscribe((success: TrigonosReportDetail) => {
      // Update sidenav
      this.dataChangedService.dataUpdated('reports');
      // Notify that config has changed
      this.dataChangedService.configUpdated(
        this.reportModuleService.currentReport.id,
        ''
      );
      // Navigate to report
      this.router.navigate(['report', success.id]);
      // Close dialog
      this.dialogRef.close();

      if (this.editMode) {
        this.matSnackbar.open('Report aktualisiert', null, { duration: 2000 });
      } else {
        this.matSnackbar.open('Report erstellt', null, { duration: 2000 });
      }
    });
  }

  public deleteReport(event: MouseEvent) {
    if (!this.editMode) {
      return;
    }

    let deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        headlineText: `Report ${this.currentReport.name} endgültig entfernen? `,
        confirmationButtonText: 'Ja',
        rejectButtonText: 'Nein',
        displayText: `ACHTUNG: Der Report wird engültig gelöscht.`,
      },
    });

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.managementService
          .managementReportDelete(this.currentReport.id)
          .subscribe(() => {
            // Update sidenav
            this.dataChangedService.dataUpdated('reports');
            // Navigate to report
            this.router.navigate(['/']);
            // Close dialog
            this.dialogRef.close();

            this.matSnackbar.open(
              `Report ${this.currentReport.name} gelöscht`,
              null,
              { duration: 2000 }
            );
          });
      }
    });

    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
