import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ChartOptions, LinearScale } from 'chart.js';

import { Chart } from 'chart.js';

import { BaseChartDirective } from 'ng2-charts';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DataService,
  ManagementService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import 'chartjs-adapter-moment';

import { DataPoint } from 'src/app/api/generated';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { InverseVelocityConfigComponent } from '../config/inverse_velocity-config/inverse_velocity-config.component';
import {
  InverseVelocityConfig,
  parseGNSSData,
  DataPointInverseVelocity,
  selectableTimePeriods,
  selectableLowPassFilterPeriods,
  getParameterDisplayName,
  getMinMaxFromConfig,
  CHART_COLORS,
} from '../inverse_velocity.tools';
import * as moment from 'moment';

// import { DataFrame, toJSON } from 'danfojs';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { CsvExportService } from 'src/app/csv-export.service';

@Component({
  selector: 'app-correlation',
  templateUrl: './inverse_velocity.component.html',
  styleUrls: ['./inverse_velocity.component.scss'],
})
export class InverseVelocityComponent implements OnInit, AfterViewInit {
  @Input() config: InverseVelocityConfig;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  public static moduleDetails: ModuleConfiguration = {
    displayName: '1/V Graphik',
    previewImagePath: 'assets/correlation_preview.png',
    settingsComponent: InverseVelocityConfigComponent,
  };

  public chartData: [] = [];
  public chartLabels: any = [];
  private labelsComplete: any = [];
  private loadedDatasets: Map<string, DataPointInverseVelocity[]> = new Map<
    string,
    DataPointInverseVelocity[]
  >();
  public chartWidth = '100%';

  public correlationForm: UntypedFormGroup;
  public selectedFieldControl = new UntypedFormControl();
  public lowPassDateRange = new UntypedFormControl();

  public selectableTimePeriods = selectableTimePeriods;
  public selectableLowPassFilterPeriods = selectableLowPassFilterPeriods;
  public getParameterDisplayName = getParameterDisplayName;
  public preDefinedDatePeriod = new UntypedFormControl(
    selectableTimePeriods[5].value
  );
  public preDefinedLowPassFilterPeriods = new UntypedFormControl(
    selectableLowPassFilterPeriods[0].value
  );

  public datasetDetails: Map<string, TrigonosDatasource> = new Map<
    string,
    TrigonosDatasource
  >();

  public maxDate: Date; // = new Date(Date.now());
  public minDate: Date; //= new Date(0);
  public customDateRange = false;
  private lastCustomDateRange = Date.now();
  public relativeZeroFiltering = false;

  public chartOptions: ChartOptions = {};
  onInitFinished: boolean = false;

  constructor(
    private readonly dataService: DataService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public readonly reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    public userService: UserService,
    private csvExportService: CsvExportService
  ) {}

  private beforeScaleFit(scale) {
    // https://stackoverflow.com/questions/37004611/how-to-update-chartjs2-option-scale-tick-max
    let { startDate, endDate } = this.getStartEndDate();
    scale.options.ticks.max = new Date(endDate);
    scale.options.ticks.min = new Date(startDate);
  }

  private beforeYScaleFit(scale: LinearScale) {
    let minMax = getMinMaxFromConfig(
      this.correlationForm.get('selectedField').value,
      this.config.min_max
    );

    if (minMax.min !== 0) {
      scale.options.min = minMax.min;
    } else {
      scale.options.min = undefined;
    }

    if (minMax.max !== 0) {
      scale.options.max = minMax.max;
    } else {
      scale.options.max = undefined;
    }
  }

  async ngOnInit() {
    this.correlationForm = this.formBuilder.group({
      selectedField: this.selectedFieldControl,
      preDefinedDateField: this.preDefinedDatePeriod,
      preDefinedLowPassFilter: this.preDefinedLowPassFilterPeriods,
      filterDateStart: [''],
      filterDateEnd: [''],
    });

    this.selectedFieldControl.setValue(this.config.selected_fields[0]);

    let minDates: Date[] = [];
    let maxDates: Date[] = [];
    for (let dataset of this.config.dataset) {
      let daterange = await this.dataService
        .dataDaterangeRead(dataset)
        .toPromise();
      minDates.push(new Date(daterange.start_date));
      maxDates.push(new Date(daterange.end_date));

      let datasetDetails: TrigonosDatasource = await this.managementService
        .managementDatasourceDetailRead(dataset)
        .toPromise();

      this.datasetDetails.set(dataset, datasetDetails);
    }

    this.maxDate = new Date(Math.max.apply(null, maxDates));
    this.minDate = new Date(Math.min.apply(null, minDates));

    this.correlationForm.controls['filterDateStart'].setValue(this.minDate);
    this.correlationForm.controls['filterDateEnd'].setValue(this.maxDate);

    this.beforeScaleFit = this.beforeScaleFit.bind(this);
    this.beforeYScaleFit = this.beforeYScaleFit.bind(this);

    this.chartOptions = {
      // devicePixelRatio: 10,
      elements: {
        point: {
          // borderWidth: 0,
          // pointStyle: 'crossRot',
          // pointStyle: ''
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          intersect: true,
          callbacks: {
            title: (object) => {
              let data = object[0];
              return this.datePipe.transform(
                data.dataset.data[data.dataIndex]['x'],
                'short',
                undefined,
                'de-AT'
              );
            },
            label: (data: any) => {
              let label = data.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += (
                data.dataset.data[data.dataIndex]['y'] as number
              ).toFixed(3);
              return label;
            },
          },
        },
      },

      animation: {
        duration: 0,
      },
      hover: {},
      responsive: true,
      scales: {
        y: {
          beforeFit: this.beforeYScaleFit,
          title: {
            display: true,
            text: this.config.y_label,
          },
          ticks: {
            callback: (value, index, values) => {
              let prefix = 'm';
              if (
                ['vl', 'vq', 'vz'].includes(this.selectedFieldControl.value)
              ) {
                prefix = 'Tag / mm';
              }
              return `${value} ${prefix}`;
            },
          },
        },
        x: {
          beforeFit: this.beforeScaleFit,
          type: 'time',
          bounds: 'ticks',
          ticks: {
            source: 'auto',

            // callback: (value, index, values) => {
            //   this.datePipe.transform(value, 'de-AT');
            //   return '';
            // },
          },
          time: {
            displayFormats: {
              hour: 'dd. HH:mm',
            },
          },
          title: {
            display: true,
            text: this.config.x_label,
          },
        },
      },
    };

    this.onInitFinished = true;
  }

  async ngAfterViewInit() {
    while (true) {
      if (this.onInitFinished) {
        break;
      }
      await timer(100).pipe(take(1)).toPromise();
    }
    this.reloadGraph();
  }

  private getStartEndDate(): { startDate: string; endDate: string } {
    let startDate: string;
    let endDate: string;
    if (this.customDateRange) {
      startDate = this.datePipe.transform(
        this.correlationForm.controls['filterDateStart'].value,
        'full',
        undefined,
        'en-US'
      );
      endDate = this.datePipe.transform(
        this.correlationForm.controls['filterDateEnd'].value,
        'full',
        undefined,
        'en-US'
      );
    } else {
      startDate = this.preDefinedDatePeriod.value.toISOString();
      endDate = new Date(Date.now()).toISOString();
    }
    return { startDate: startDate, endDate: endDate };
  }

  private loadDatasetAndVisualise() {
    let { startDate, endDate } = this.getStartEndDate();

    for (let datasetName of this.config.dataset) {
      let loadedDataset: Observable<DataPoint[]>;
      if (this.config.filter.enabled) {
        loadedDataset = this.dataService.dataDataRead(
          datasetName,
          startDate,
          endDate,
          [this.selectedFieldControl.value],
          this.preDefinedLowPassFilterPeriods.value.toISOString(),
          this.config.filter.from,
          this.config.filter.till
        );
      } else {
        loadedDataset = this.dataService.dataDataRead(
          datasetName,
          startDate,
          endDate,
          [this.selectedFieldControl.value],
          this.preDefinedLowPassFilterPeriods.value.toISOString()
        );
      }

      loadedDataset.subscribe(
        (value) => {
          this.loadedDatasets.set(datasetName, parseGNSSData(value));
          this.updateGraph(datasetName);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    this.chart.chart.update();
  }

  public updateGraph(datasetName: string) {
    let dataset = this.loadedDatasets.get(datasetName);
    if (!dataset) {
      return;
    }

    let columnName = this.selectedFieldControl.value;
    // Check if dataset needs to be transformed
    if (this.relativeZeroFiltering) {
      // let df = new DataFrame(dataset);
      // df = df.asType(columnName, 'float32');
      // let mean = df.iloc({ rows: ['0:10'] })[columnName].mean();
      // df[columnName] = df[columnName].sub(mean);
      // dataset = toJSON(df) as DataPointInverseVelocity[];
    }

    const datapoints = dataset.map(
      (datapoint) =>
        <any>{
          x: datapoint.epoch,
          y: datapoint[this.selectedFieldControl.value],
        }
    );

    const datapointColor =
      CHART_COLORS[this.config.dataset.indexOf(datasetName)];

    const data = {
      data: datapoints,
      label: `${this.datasetDetails.get(datasetName).name}`,
      fill: false,
      lineTension: 0,
      pointBorderWidth: 0,
      pointBorderColor: 'rgba(0, 0, 0, 0)',
      pointRadius: 1.5,
      borderWidth: 1.5,
      hoverRadius: 3,
      pointBackgroundColor: datapointColor,
      backgroundColor: datapointColor,
      borderColor: datapointColor,
    };

    this.chartData.push(data as never);

    if (this.chart) {
      // this.chart.updateColors();
      this.chart.chart.update();
    }
  }

  public selectionChanged(event) {
    this.reloadGraph();
  }

  public relativeZeroChanged(event: MatSlideToggleChange) {
    this.relativeZeroFiltering = event.checked;
    this.emptyArray(this.chartData);
    this.emptyArray(this.chartLabels);
    this.emptyArray(this.labelsComplete);

    for (let datasetName of this.config.dataset) {
      this.updateGraph(datasetName);
    }
  }

  public dateRangeSelectionChanged(event) {
    if (event.value === -1) {
      this.customDateRange = true;
    } else {
      this.customDateRange = false;
    }
    this.reloadGraph();
  }

  public customDateFilterChanged(event) {
    if (Date.now() - this.lastCustomDateRange > 100) {
      this.lastCustomDateRange = Date.now();
      this.reloadGraph();
    }
  }

  private reloadGraph() {
    this.emptyArray(this.chartData);
    this.emptyArray(this.chartLabels);
    this.emptyArray(this.labelsComplete);

    this.loadedDatasets.clear();

    this.loadDatasetAndVisualise();
  }

  private emptyArray(array: any[]) {
    while (array.length) {
      array.pop();
    }
  }

  // Set white background color so the download looks nice
  // https://stackoverflow.com/a/50126796/
  private fillCanvasBackgroundWithColor(canvas, color) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  public async exportCurrentView(event) {
    this.chart.chart.options.devicePixelRatio = 2;
    this.chartWidth = '1920px';
    let countdown = 0;
    while (countdown < 2) {
      await timer(100).pipe(take(1)).toPromise();
      countdown++;
    }
    this.fillCanvasBackgroundWithColor(this.chart.chart.canvas, '#FFFFFF');

    let a = document.createElement('a');
    a.href = this.chart.chart.toBase64Image('image/png', 1);
    let date = new Date();
    a.download = `${this.reportModuleService.currentReport.name}_${date
      .toLocaleDateString('de-AT')
      .replace(/\./g, '_')}.png`;

    a.click();
    this.chart.chart.options.devicePixelRatio = 1;
    this.chartWidth = '100%';
  }

  public async exportDatasets(event) {
    // this.csvExportService.inverseVelocityCsvExport(
    //   this.config,
    //   this.getStartEndDate(),
    //   this.relativeZeroFiltering
    // );
  }
}
