import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  ImageUpload,
  ManagementService,
  ReportsService,
} from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { ImageViewerConfig } from '../../image-viewer.tools';

import * as uuid from 'uuid';
import { validURL } from 'src/app/tools/validators';
import { environment } from 'src/environments/environment';

enum SELECTED_TAB {
  Webcam = 0,
  ImageUpload,
}

@Component({
  selector: 'app-image-viewer-config',
  templateUrl: './image-viewer-config.component.html',
  styleUrls: ['./image-viewer-config.component.scss'],
})
export class ImageViewerConfigComponent implements OnInit, AfterViewInit {
  public imagePreviewURL: SafeUrl;
  public imageViewerForm: UntypedFormGroup;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('image', { static: false }) image: ElementRef;

  public editMode = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerConfig,
    private dialogRef: MatDialogRef<ImageViewerConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private dataChangedService: DataChangedService,
    private sanitizer: DomSanitizer,
    private reportService: ReportsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    this.imageViewerForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data.guid : uuid.v4()],
      type: ['image-viewer'],
      webcamURL: [
        this.data?.refresh ? this.data?.resource_url : '',
        [Validators.required, validURL()],
      ],
      webcamRefreshRate: [
        this.data?.refresh_timer ? this.data?.refresh_timer / 60 : '',
        [Validators.required],
      ],
      imageSource: ['', [Validators.required]],
      headline: [this.data?.headline ? this.data?.headline : ''],
      imageCaption: [this.data?.description ? this.data?.description : ''],
      selectedTab: [0, Validators.required],
    });
  }

  async ngAfterViewInit() {
    if (this.editMode) {
      if (!this.data?.refresh) {
        await this.fetchImageAndDisplay();
      } else {
        this.tabChanged({
          index: SELECTED_TAB.Webcam,
          tab: undefined,
        });
      }
    } else {
      this.tabChanged({
        index: SELECTED_TAB.Webcam,
        tab: undefined,
      });
    }
  }

  async fetchImageAndDisplay() {
    const imageURL = `${environment.apiEndpoint}${this.data.resource_url}`;
    const reader = new FileReader();

    let response = await fetch(imageURL as string);
    let blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.imagePreviewURL = sanitizedUrl;
    this.imageViewerForm.get('imageSource').patchValue(blob);

    this.cdr.detectChanges();
    // Not sure why this is not working
    setTimeout(() => {
      this.tabGroup.selectedIndex = SELECTED_TAB.ImageUpload;
      this.tabChanged({
        index: SELECTED_TAB.ImageUpload,
        tab: undefined,
      });
    }, 200);
  }

  public onFileChange(event) {
    const fileReader = new FileReader();

    if (event.target.files?.length > 0) {
      const [file] = event.target.files;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result]);
        const url = URL.createObjectURL(blob);
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.imagePreviewURL = sanitizedUrl;
        this.imageViewerForm.get('imageSource').patchValue(blob);
        // this.image.nativeElement.src = this.imagePreviewURL;
        this.cdr.detectChanges();
      };
    }
  }

  public async updateOrCreateModule() {
    // Object.keys(this.imageViewerForm.controls).forEach((key) => {
    //   const controlErrors: ValidationErrors =
    //     this.imageViewerForm.get(key).errors;
    //   if (controlErrors != null) {
    //     Object.keys(controlErrors).forEach((keyError) => {
    //       console.log(
    //         'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
    //         controlErrors[keyError]
    //       );
    //     });
    //   }
    // });
    // return;
    if (this.imageViewerForm.valid) {
      let resourceURL = '';
      if (this.imageViewerForm.value.selectedTab === SELECTED_TAB.ImageUpload) {
        const uploadedImage = await this.reportService
          .uploadsReportImage(this.imageViewerForm.value.imageSource)
          .toPromise();
        resourceURL = uploadedImage.image_path;
      } else {
        resourceURL = this.imageViewerForm.value.webcamURL;
      }

      this.reportModuleService
        .addOrEditModuleToReport(
          <ImageViewerConfig>{
            guid: this.imageViewerForm.value.guid,
            type: this.imageViewerForm.value.type,
            description: this.imageViewerForm.value.imageCaption,
            headline: this.imageViewerForm.value.headline,
            refresh:
              this.imageViewerForm.value.selectedTab === SELECTED_TAB.Webcam,
            refresh_timer:
              Number(this.imageViewerForm.value.webcamRefreshRate) * 60,
            resource_url: resourceURL,
          },
          this.editMode
        )
        .subscribe(
          (success) => {
            this.dialogRef.close();
            this.dataChangedService.configUpdated(
              this.reportModuleService.currentReport.id,
              this.imageViewerForm.value.guid
            );
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  public tabChanged(event: MatTabChangeEvent) {
    this.imageViewerForm.get('selectedTab').setValue(event.index);

    if (event.index === SELECTED_TAB.Webcam) {
      this.imageViewerForm
        .get('webcamURL')
        .setValidators([Validators.required, validURL()]);
      this.imageViewerForm
        .get('webcamRefreshRate')
        .setValidators([Validators.required]);

      this.imageViewerForm.get('imageSource').setValidators([]);
    } else {
      this.imageViewerForm.get('webcamURL').setValidators([]);
      this.imageViewerForm.get('webcamRefreshRate').setValidators([]);

      this.imageViewerForm
        .get('imageSource')
        .setValidators([Validators.required]);
    }

    this.imageViewerForm.get('webcamURL').updateValueAndValidity();
    this.imageViewerForm.get('webcamRefreshRate').updateValueAndValidity();
    this.imageViewerForm.get('imageSource').updateValueAndValidity();
  }
}
