<h2 *ngIf="!editMode" mat-dialog-title>
  Modul Zeit-Verformungsgraphik hinzufügen
</h2>
<h2 *ngIf="editMode" mat-dialog-title>
  Modul Zeit-Verformungsgraphik editieren
</h2>
<mat-dialog-content>
  <div>
    <form
      [formGroup]="correlationForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" required />
        <mat-error
          *ngIf="correlationForm.controls.headline.hasError('required')"
          >Überschrift angeben</mat-error
        >
      </mat-form-field>

      <mat-label>Visualisierbare Daten</mat-label>
      <div class="user-selection-group">
        <ng-container formArrayName="datasources">
          <div *ngFor="let datasource of availableDataSources; let i = index">
            <mat-checkbox [formControlName]="i">
              {{ datasource.name }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <mat-label>Visualisierbare Daten</mat-label>
      <div class="user-selection-group">
        <ng-container formArrayName="visualisableFields">
          <div *ngFor="let field of availableFields; let i = index">
            <mat-checkbox [formControlName]="i">
              {{ field.label }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <mat-form-field>
        <mat-label>X-Achse Beschriftung</mat-label>
        <input matInput formControlName="labelXAxis" required />
        <mat-error
          *ngIf="correlationForm.controls.labelXAxis.hasError('required')"
        >
          Beschriftung für X-Achse angeben
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Y-Achse Beschriftung</mat-label>
        <input matInput formControlName="labelYAxis" required />
        <mat-error
          *ngIf="correlationForm.controls.labelYAxis.hasError('required')"
        >
          Beschriftung für Y-Achse angeben
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle formControlName="timePeriodFiltered"
        >Zeitraum filtern</mat-slide-toggle
      >
      <div class="d-flex flex-row">
        <mat-form-field class="p-2">
          <mat-label>Startzeit</mat-label>
          <input
            [attr.disabled]="timeFilterDisabled"
            matInput
            formControlName="timePeriodFilteredFrom"
          />
          <mat-error
            *ngIf="
              correlationForm.controls.timePeriodFilteredFrom.hasError(
                'validTime'
              )
            "
            >Zeitraum im Format [hh:mm] angeben</mat-error
          >
        </mat-form-field>

        <mat-form-field class="p-2">
          <mat-label>Endzeit</mat-label>
          <input
            [attr.disabled]="timeFilterDisabled"
            matInput
            formControlName="timePeriodFilteredTill"
          />
          <mat-error
            *ngIf="
              correlationForm.controls.timePeriodFilteredTill.hasError(
                'validTime'
              )
            "
          >
            Zeitraum im Format [hh:mm] angeben</mat-error
          >
        </mat-form-field>
      </div>

      <mat-slide-toggle formControlName="relativeZero" class="mt-2 mb-2"
        >Darstellung der relativen Verformung erlauben</mat-slide-toggle
      >

      <mat-label>Minimal- und Maximalwert Ordinate</mat-label>
      <div class="user-selection-group">
        <ng-container formArrayName="yAxisMinValues">
          <div
            *ngFor="
              let field of correlationForm.get('yAxisMinValues')['controls'];
              let i = index
            "
            class=""
          >
            <div
              [formGroup]="field"
              class="flex flex-row space-x-2 justify-end"
            >
              <div
                class="mr-auto flex flex-col-rev justify-end content-center pt-3 w-full h-full"
              >
                <mat-label>{{ availableFields[i].label }}</mat-label>
              </div>

              <div>
                <mat-form-field>
                  <mat-label>Min [{{ availableFields[i].unit }}]</mat-label>
                  <input
                    step="0.0001"
                    type="number"
                    matInput
                    formControlName="min"
                  />
                </mat-form-field>
              </div>

              <div class="mr-16">
                <mat-form-field>
                  <mat-label>Max [{{ availableFields[i].unit }}]</mat-label>
                  <input
                    step="0.0001"
                    type="number"
                    matInput
                    formControlName="max"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!correlationForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
