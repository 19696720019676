import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  ManagementService,
  ReportsService,
  SensorsSensor,
  SensorsService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import {
  getMinMaxFromConfig,
  MinMaxValue,
  MOVEMENT_SCATTER_FIELDS,
  GenericMovementScatterplotConfig,
} from '../../movement_scatter.tools';

import * as uuid from 'uuid';
import { validTime } from 'src/app/tools/validators';
import { toNumberFromENString, toNumberStringDE } from 'src/app/user/tools';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface VisualisableField {
  id: string;
  label: string;
  unit: string;
}

@Component({
  selector: 'app-generic-movement-scatterplot-config',
  templateUrl: './movement_scatterplot-config.component.html',
  styleUrls: ['./movement_scatterplot-config.component.scss'],
})
export class GenericMovementScatterplotConfigComponent
  implements OnInit, AfterViewInit
{
  public imagePreviewURL: SafeUrl;
  public correlationForm: UntypedFormGroup;
  public editMode = false;
  public availableDataSources: SensorsSensor[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenericMovementScatterplotConfig,
    private dialogRef: MatDialogRef<GenericMovementScatterplotConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private reportService: ReportsService,
    private sensorService: SensorsService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.editMode = this.data?.guid !== undefined;
    this.correlationForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data?.guid : uuid.v4()],
      type: ['movement_scatterplot_generic'],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      datasources: this.formBuilder.array([]),
      timePeriodFiltered:
        this.data?.filter.enabled === undefined
          ? false
          : this.data?.filter.enabled,
      timePeriodFilteredFrom: [
        this.data?.filter.from ? this.data?.filter.from : '06:00',
        [Validators.required, validTime()],
      ],
      timePeriodFilteredTill: [
        this.data?.filter.till ? this.data?.filter.till : '22:00',
        [Validators.required, validTime()],
      ],
      imageSource: ['', [Validators.required]],
    });

    this.sensorService
      .sensorsProjectRead(this.reportModuleService.currentReport.project)
      .subscribe((sensors) => {
        this.availableDataSources = this.filterSensors(sensors);
        this.availableDataSources.forEach((sensor) => {
          (this.correlationForm.get('datasources') as FormArray).push(
            new FormGroup({
              id: new UntypedFormControl(sensor.influx_name),
              name: new UntypedFormControl(sensor.display_name),
              checked: new UntypedFormControl(
                this.isSensorCheckedForFieldInConfig(sensor.influx_name)
              ),
            })
          );
        });
      });
  }

  async ngAfterViewInit() {
    if (this.editMode) {
      this.fetchImageAndDisplay();
    }
  }

  public onFileChange(event) {
    const fileReader = new FileReader();

    if (event.target.files?.length > 0) {
      const [file] = event.target.files;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result]);
        const url = URL.createObjectURL(blob);
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.imagePreviewURL = sanitizedUrl;
        this.correlationForm.get('imageSource').patchValue(blob);
        // this.image.nativeElement.src = this.imagePreviewURL;
        this.cdr.detectChanges();
      };
    }
  }

  public async updateOrCreateModule() {
    if (!this.correlationForm.valid) {
      return;
    }

    let datasets = this.correlationForm.value.datasources
      .map((enabled, index) => {
        if (enabled) {
          return this.availableDataSources[index].id;
        }
      })
      .filter(Boolean);

    // Upload image and get path
    const uploadedImage = await this.reportService
      .uploadsReportImage(this.correlationForm.value.imageSource)
      .toPromise();
    const resourceURL = uploadedImage.image_path;

    this.reportModuleService
      .addOrEditModuleToReport(
        // FIX ME!
        <any>{
          guid: this.correlationForm.value.guid,
          type: this.correlationForm.value.type,
          headline: this.correlationForm.value.headline,
          filter: {
            enabled: this.correlationForm.value.timePeriodFiltered,
            from: this.correlationForm.value.timePeriodFilteredFrom,
            till: this.correlationForm.value.timePeriodFilteredTill,
          },
          user_settings: {
            start_date: null,
            end_date: null,
          },
          dataset: this.correlationForm.value.datasources,
          bg_image_resource_url: resourceURL,
        },
        this.editMode
      )
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.dataChangedService.configUpdated(
            this.reportModuleService.currentReport.id,
            this.correlationForm.value.guid
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  async fetchImageAndDisplay() {
    const imageURL = `${environment.apiEndpoint}${this.data.bg_image_resource_url}`;
    const reader = new FileReader();

    let response = await fetch(imageURL as string);
    let blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.imagePreviewURL = sanitizedUrl;
    this.correlationForm.get('imageSource').patchValue(blob);

    this.cdr.detectChanges();
  }

  get timeFilterDisabled(): string | null {
    return this.correlationForm.get('timePeriodFiltered').value ? null : '';
  }

  private yAxisMinValuesFormGroup(): UntypedFormArray {
    return this.correlationForm.get('yAxisMinValues') as UntypedFormArray;
  }

  private datasourcesFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('datasources') as UntypedFormArray;
  }

  private visualisableFieldsFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('visualisableFields') as UntypedFormArray;
  }

  private filterSensors(sensors: SensorsSensor[]): SensorsSensor[] {
    return sensors.filter((sensor) => {
      const fields = sensor.available_fields.map((field) => field.identifier);

      return (
        fields.length > 0 &&
        MOVEMENT_SCATTER_FIELDS.every((field) => fields.includes(field))
      );
    });
  }

  public isSensorCheckedForFieldInConfig(sensor: string): boolean {
    if (!this.editMode) return false;
    const sensors = this.data.dataset.filter(
      (sf) => sf.id === sensor && sf.checked
    );
    return sensors.length > 0;
  }
}
