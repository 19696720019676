<mat-card>
  <mat-card-header>
    <div class="">
      <p>{{ config.headline }}</p>
      <p>{{ datasetsetName }}</p>
    </div>
  </mat-card-header>
  <mat-card-content class="d-flex flex-column">
    <form [formGroup]="roleBatteryForm" class="d-flex justify-content-left flex-wrap roleBatteryForm"
      (keydown)="computeTime($event)">
      <mat-form-field appearance="outline">
        <mat-label>Messzeitpunkt</mat-label>
        <input type="text" matInput formControlName="measuringDateTime" placeholder="dd.mm.yyyy" required
          [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Schließen</button>
            <button mat-raised-button color="primary" matDatepickerApply>
              Auswählen
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>

        <mat-error *ngIf="
            roleBatteryForm.controls.measuringDateTime.hasError('required')
          ">
          Messzeitpunkt wird benötigt
        </mat-error>
        <mat-error *ngIf="roleBatteryForm.controls.measuringDateTime.hasError('pattern')">
          Bitte im Format [dd.mm.yyyy] eingeben
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Gemessener Spielraum [mm] </mat-label>
        <input required type="text" matInput formControlName="measuredMargin" lang="de-DE" />

        <mat-error *ngIf="roleBatteryForm.controls.measuredMargin.hasError('required')">
          Gemessener Spielraum wird benötigt
        </mat-error>
        <mat-error *ngIf="roleBatteryForm.controls.measuredMargin.hasError('pattern')">
          Gültige Zahl eingeben
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label> Warnwert [mm]</mat-label>
        <input type="text" matInput formControlName="marginWarning" lang="de-DE" />
        <mat-error *ngIf="roleBatteryForm.controls.marginWarning.hasError('pattern')">
          Gültige Zahl eingeben
        </mat-error>
      </mat-form-field>
    </form>

    <form class="d-flex flex-wrap justify-content-left roleBatteryForm">
      <mat-form-field appearance="outline">
        <mat-label>Warnwert wird erreicht am</mat-label>
        <input type="text" disabled matInput [value]="(marginDate | date) || ''" />
      </mat-form-field>
      <mat-form-field appearance="outline" [class.mat-form-field-invalid]="marginReached">
        <mat-label>Spielraum</mat-label>
        <input type="text" matInput disabled [value]="((remainingMargin | number: '1.2-2':'de-AT') || '') + 'mm'" />
      </mat-form-field>
    </form>
    <p *ngIf="remainingMarginNegative" class="userErrorMessage">
      Die Stütze bewegt sich aktuell entgegen der erwarteten Richtung
    </p>
    <p *ngIf="secondErrorMessage" class="userErrorMessage">
      Der aktuelle Wert liegt außerhalb des gemessenen Spielraums
    </p>

    <div class="info-compute-button-wrapper">
      <button mat-raised-button color="primary" (click)="computeTime($event)" class="computeMargin">
        <div class="button-icon-align">Berechnen</div>
      </button>
      <button mat-raised-button color="" (click)="openInfoDialog()" class="computeMargin">
        <div class="button-icon-align">
          <span class="material-icons-outlined"> info </span>
          <span> Information zu Grafik </span>
        </div>
      </button>
    </div>
    <apx-chart #chart [series]="chartSeries" [chart]="chartConfig" [title]="chartTitle" [xaxis]="chartxAxis"
      [yaxis]="chartyAxis" [tooltip]="false" [legend]="false" [tooltip]="chartTooltipOptions"
      [grid]="chartGrid"></apx-chart>

    <!-- Print `debug` as JSON -->
    <pre>{{ debug | json }}</pre>
  </mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button mat-button (click)="reportModuleService.openEditReportModuleForm(config.guid)">
      BEARBEITEN
    </button>
    <button (click)="reportModuleService.removeModuleFormReport(config.guid)" mat-button>
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
