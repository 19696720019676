import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabGroup as MatTabGroup,
} from '@angular/material/legacy-tabs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  ImageUpload,
  ManagementService,
  ReportsService,
} from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { WebcamConfig } from '../../webcam.tools';

import * as uuid from 'uuid';
import { DefaultService } from 'src/app/api/webcam';

@Component({
  selector: 'app-webcam-viewer-config',
  templateUrl: './webcam-config.component.html',
  styleUrls: ['./webcam-config.component.scss'],
})
export class WebcamConfigComponent implements OnInit, AfterViewInit {
  public imagePreviewURL: SafeUrl;
  public webcamForm: UntypedFormGroup;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('image', { static: false }) image: ElementRef;

  public editMode = false;
  public availableLocations: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WebcamConfig,
    private dialogRef: MatDialogRef<WebcamConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private dataChangedService: DataChangedService,
    private sanitizer: DomSanitizer,
    private reportService: ReportsService,
    private cdr: ChangeDetectorRef,
    private webcamService: DefaultService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    console.log(this.data);
    this.webcamForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data.guid : uuid.v4()],
      headline: [this.data?.headline ? this.data.headline : ''],
      imageCaption: [this.data?.description ? this.data.description : ''],
      type: ['webcam'],
      location: [this.data?.location ? this.data.location : 1],
    });

    this.webcamService.getLocationsApiLocationsGet().subscribe((locations) => {
      for (const location of locations) {
        this.availableLocations.push(location);
      }
    });
  }

  get locations(): UntypedFormArray {
    return this.webcamForm.get('locations') as UntypedFormArray;
  }

  async ngAfterViewInit() {}

  public async updateOrCreateModule() {
    if (this.webcamForm.valid) {
      this.reportModuleService
        .addOrEditModuleToReport(
          <WebcamConfig>{
            guid: this.webcamForm.value.guid,
            type: this.webcamForm.value.type,
            description: this.webcamForm.value.imageCaption,
            headline: this.webcamForm.value.headline,
            location: this.webcamForm.value.location,
          },
          this.editMode
        )
        .subscribe(
          (success) => {
            this.dialogRef.close();
            this.dataChangedService.configUpdated(
              this.reportModuleService.currentReport.id,
              this.webcamForm.value.guid
            );
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }
}
