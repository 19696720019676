<mat-card>
  <mat-card-header>
    <mat-card-title>{{ config.headline }}</mat-card-title>
    <mat-card-subtitle> </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="relative">
      <div *ngIf="isLoading">
        <ngx-spinner
          [fullScreen]="false"
          type="ball-scale-multiple"
          size="medium"
        >
        </ngx-spinner>
      </div>

      <!-- filter, terrain, landscape for layer switcher -->
      <div class="container" [@visibilityChanged]="controlErrorMessage.status">
        <div class="alert alert-danger" role="alert">
          Verwenden Sie Strg+Scrollen zum Zoomen der Karte
        </div>
      </div>
      <div class="map" #mapElement>
        <div class="overlay-scale-factor">
          <div>
            <div>
              Verschiebung Höhe:
              <svg width="5" height="20" style="margin-left: 5px">
                <line
                  pointer-events="none"
                  x1="0"
                  y1="0"
                  y2="20"
                  x2="0"
                  stroke-width="5"
                  [attr.stroke]="config.color_height_vector"
                ></line>
              </svg>
            </div>

            <div>
              Verschiebung Lage:
              <svg width="20" height="10">
                <line
                  pointer-events="none"
                  x1="0"
                  y1="0"
                  y2="0"
                  x2="20"
                  stroke-width="5"
                  [attr.stroke]="config.color_2d_vector"
                ></line>
              </svg>
            </div>
            <div>
              Messwert Aktuell:
              <!-- SVG Circle -->
              <svg width="20" height="20">
                <circle
                  pointer-events="none"
                  cx="10"
                  cy="10"
                  r="6"
                  [attr.stroke]="config.dynamic_center_point.color_ok"
                  [attr.fill]="config.dynamic_center_point.color_ok"
                ></circle>
              </svg>
            </div>
            <div>
              Messwert älter als
              {{ config.dynamic_center_point.difference_hours }} Stunden:
              <svg width="20" height="20">
                <circle
                  pointer-events="none"
                  cx="10"
                  cy="10"
                  r="6"
                  [attr.stroke]="config.dynamic_center_point.color_warning"
                  [attr.fill]="config.dynamic_center_point.color_warning"
                ></circle>
              </svg>
            </div>
          </div>
          <svg
            [attr.width]="referenceScaleWidth + 20"
            height="50"
            style="margin-top: 5px"
          >
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="0"
                refY="1.5"
                orient="auto"
              >
                <polygon points="0 0, 3 1.5, 0 3" />
              </marker>
            </defs>
            <line
              *ngFor="let width of referenceScaleValues; let i = index"
              pointer-events="none"
              [attr.x1]="
                referenceScaleWidth -
                (referenceScaleWidth / referenceScaleValues.length) * (i + 1)
              "
              y1="25"
              y2="25"
              [attr.x2]="
                referenceScaleWidth -
                (referenceScaleWidth / referenceScaleValues.length) * (i + 1) +
                referenceScaleWidth / referenceScaleValues.length
              "
              stroke-width="5"
              [attr.stroke]="i % 2 === 0 ? 'grey' : 'black'"
            ></line>

            <style>
              .text-small {
                font: italic 13px sans-serif;
              }
            </style>
            <text
              *ngFor="
                let width of referenceScaleValues.reverse();
                let i = index
              "
              [attr.x]="
                referenceScaleWidth -
                (referenceScaleWidth / referenceScaleValues.length) * (i + 1) +
                referenceScaleWidth / referenceScaleValues.length
              "
              text-anchor="middle"
              [attr.y]="i % 2 == 1 ? 15 : 45"
              class="text-small"
            >
              {{ width | number : "1.2-3" : "de-AT" }}{{ i == 0 ? "m" : "" }}
            </text>
          </svg>
        </div>
        <div class="overlay-date-filter">
          <form [formGroup]="orthofotoForm">
            <mat-form-field [color]="'accent'" appearance="fill">
              <mat-label>Zeitraum filtern</mat-label>
              <mat-date-range-input
                [formGroup]="orthofotoForm"
                [rangePicker]="datepicker"
                [min]="minDate"
                [max]="maxDate"
              >
                <input
                  matStartDate
                  type="text"
                  matInput
                  formControlName="filterDateStart"
                  placeholder="dd.mm.yyyy"
                  (dateChange)="dateFilterChanged()"
                />
                <input
                  matEndDate
                  type="text"
                  matInput
                  formControlName="filterDateEnd"
                  placeholder="dd.mm.yyyy"
                  (dateChange)="dateFilterChanged()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="datepicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #datepicker>
                <mat-datepicker-actions>
                  <button mat-button matDatepickerCancel #datePickerClose>
                    Schließen
                  </button>
                  <button
                    mat-button
                    mat-stroked-button
                    (click)="selectCompleteTimespan($event, datePickerClose)"
                  >
                    Gesamter Zeitraum
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    matDatepickerApply
                    #datePickerApply
                  >
                    OK
                  </button>
                </mat-datepicker-actions>
              </mat-date-range-picker>

              <!-- <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('required')
              "
            >
              Messzeitpunkt wird benötigt
            </mat-error>
            <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('pattern')
              "
            >
              Bitte im Format [dd.mm.yyyy] eingeben
            </mat-error> -->
            </mat-form-field>
            <br />
            <mat-form-field appearance="fill">
              <mat-label>Layer</mat-label>
              <mat-select
                (selectionChange)="changeVisibleLayer($event)"
                [formControl]="layerControl"
                name="layer"
              >
                <mat-option
                  matInput
                  *ngFor="let layer of layers"
                  [value]="layer.value"
                  >{{ layer.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div #popUpContainer class="ol-popup">
          <a href="#" class="ol-popup-closer"></a>
          <div #popUpContent id="popup-content"></div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button
      mat-button
      (click)="reportModuleService.openEditReportModuleForm(config.guid)"
    >
      BEARBEITEN
    </button>
    <button
      (click)="reportModuleService.removeModuleFormReport(config.guid)"
      mat-button
    >
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
