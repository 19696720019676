import { Injectable, Type } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { Report, ReportsService, Projects } from 'src/app/api/generated';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DataChangedService } from 'src/app/data-changed.service';
import { CorrelationConfig } from 'src/app/modules/correlation/correlation.tools';
import { GenericCorrelationConfig } from 'src/app/modules/correlation_generic/correlation.tools';
import { ImageViewerConfig } from 'src/app/modules/image-viewer/image-viewer.tools';
import { MarkdownModuleConfig } from 'src/app/modules/markdown-module/component/markdown-module.component';
import { MovementScatterplotConfig } from 'src/app/modules/movement_scatterplot/movement_scatter.tools';
import { OrthofotoConfig } from 'src/app/modules/orthofoto/orthophoto.tools';
import { OrthofotoConfigGeneric } from 'src/app/modules/orthofoto_generic/orthophoto.tools';
import { RoleBatteryConfig } from 'src/app/modules/role-battery/role-battery.user-settings';
import { WebcamConfig } from 'src/app/modules/webcam/webcam.tools';

@Injectable({
  providedIn: 'root',
})
export class ReportModulesService {
  private availableModules: Map<string, Type<any>> = new Map();
  public currentReport: Report;

  public currentTransformationFrom: Projects.TransformationFromEnum;
  public currentTransformationTo: Projects.TransformationToEnum;

  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private dataChangedService: DataChangedService
  ) {}

  public registerModule(module: Type<any>, configName: string) {
    if (this.availableModules.has(configName)) {
      console.log(`Module with name "${configName}" already registerd`);
      return;
    }
    this.availableModules.set(configName, module);
  }

  public getModule(configName: string): Type<any> {
    return this.availableModules.get(configName);
  }

  public getRegisteredModuleKeys(): IterableIterator<string> {
    return this.availableModules.keys();
  }

  public updateUserSettings(settings: object, module_id: string) {
    // Find module and update user settings
    const index = (this.currentReport.config as any[]).findIndex(
      (module) => module.guid === module_id
    );

    if (index === -1) {
      return;
    }

    (this.currentReport.config as any[])[index].user_settings = settings;

    this.reportsService
      .reportsUpdateModulePreferencesCreate({
        report_id: this.currentReport.id,
        module_id: module_id,
        settings: settings,
      })
      .subscribe(
        (result) => {
          // Do not care about the result when 200
          // console.info(result);
          // @ToDo Display toast?
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public addOrEditModuleToReport(
    config:
      | OrthofotoConfig
      | CorrelationConfig
      | RoleBatteryConfig
      | ImageViewerConfig
      | GenericCorrelationConfig
      | MarkdownModuleConfig
      | MovementScatterplotConfig
      | OrthofotoConfigGeneric
      | WebcamConfig,
    editMode: boolean = false
  ): Observable<any> {
    // console.log(JSON.parse(this.currentReport.config as any));
    // console.log(this.currentReport.config);
    if (editMode) {
      const index = (this.currentReport.config as any[]).findIndex(
        (module) => module.guid === config.guid
      );
      this.currentReport.config[index] = config;
      console.log(this.currentReport.config);
    } else {
      (this.currentReport.config as any[]).push(config);
    }

    return this.reportsService.reportsUdpateCreate(this.currentReport.id, {
      config: JSON.stringify(this.currentReport.config),
    });
  }

  public removeModuleFormReport(moduleID: string) {
    const index = (this.currentReport.config as any[]).findIndex(
      (module) => module.guid === moduleID
    );

    if (index === -1) {
      return;
    }

    const moduleConfig = this.currentReport.config[index];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        headlineText: `Modul ${moduleConfig.headline} entfernen`,
        confirmationButtonText: 'Ja',
        rejectButtonText: 'Nein',
        displayText: '',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        (this.currentReport.config as any[]).splice(index, 1);
      }

      this.reportsService
        .reportsUdpateCreate(this.currentReport.id, {
          config: JSON.stringify(this.currentReport.config),
        })
        .subscribe((success) => {
          this.dataChangedService.configUpdated(
            this.currentReport.id,
            moduleID
          );
        });
    });
  }

  public openEditReportModuleForm(moduleId: string) {
    let moduleConfig: any = (this.currentReport.config as any).filter(
      (element) => {
        return element.guid === moduleId;
      }
    );

    if (moduleConfig.length === 0) {
      return;
    }

    moduleConfig = moduleConfig[0];
    const module: any = this.getModule(moduleConfig.type);
    console.log(module);
    this.dialog.open(module.moduleDetails.settingsComponent, {
      data: moduleConfig,
    });
  }
}
