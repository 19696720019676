import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ChartData, ChartOptions, LegendItem, LinearScale } from 'chart.js';

import { Chart } from 'chart.js';

import { BaseChartDirective } from 'ng2-charts';
import { Observable, forkJoin, merge, of, timer } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DataAvailableDateRange,
  DataService,
  ManagementService,
  SensorsSensor,
  SensorsSensorfield,
  SensorsService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import 'chartjs-adapter-moment';

import { DataPoint } from 'src/app/api/generated';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { GenericCorrelationConfigComponent } from '../config/correlation-config/correlation-config.component';
import {
  GenericCorrelationConfig,
  parseGNSSData,
  DataPointGnss,
  selectableTimePeriods,
  selectableLowPassFilterPeriods,
  getMinMaxFromConfig,
  CHART_COLORS,
  DoubleKeyMap,
  computeZeroFilteredDataset,
  ChartJSNoDataPlugin,
} from '../correlation.tools';
import * as moment from 'moment';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DisplayInfoImageComponent } from 'src/app/dialogs/display-image/display-info-image.component';
import { CsvExportService } from 'src/app/csv-export.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

declare module 'chart.js' {
  interface PluginOptionsByType<TType extends ChartType> {
    textPlugin?: {
      text: string;
      position: { x: number; y: number };
      fontColor?: string;
      fontSize?: string;
      fontFamily?: string;
    };
  }
}

@Component({
  selector: 'app-correlation-generic',
  templateUrl: './correlation.component.html',
  styleUrls: ['./correlation.component.scss'],
})
export class GenericCorrelationComponent implements OnInit {
  @Input() config: GenericCorrelationConfig;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  public static moduleDetails: ModuleConfiguration = {
    displayName: 'Zeit-Verformungsgraphik',
    previewImagePath: 'assets/correlation_preview.png',
    settingsComponent: GenericCorrelationConfigComponent,
  };

  public isLoading = true;
  public chartData: [] = [];
  public chartLabels: any = [];
  private labelsComplete: any = [];
  private loadedDatasets: Map<string, DataPointGnss[]> = new Map<
    string,
    DataPointGnss[]
  >();
  public chartWidth = '100%';

  public correlationForm: UntypedFormGroup;
  public selectedFieldControl = new UntypedFormControl();
  public lowPassDateRange = new UntypedFormControl();

  public selectableTimePeriods = selectableTimePeriods;
  public selectableLowPassFilterPeriods = selectableLowPassFilterPeriods;
  public preDefinedDatePeriod = new UntypedFormControl(
    selectableTimePeriods[5].value
  );
  public preDefinedLowPassFilterPeriods = new UntypedFormControl(
    selectableLowPassFilterPeriods[0].value
  );

  public datasetDetails: Map<string, TrigonosDatasource> = new Map<
    string,
    TrigonosDatasource
  >();

  public loadedData: DoubleKeyMap<string, string, []> = new DoubleKeyMap<
    string, // sensor
    string, // field
    [] // data
  >();

  public maxDate: Date; // = new Date(Date.now());
  public minDate: Date; //= new Date(0);
  public customDateRange = false;
  public relativeZeroFiltering = false;

  public minMaxDates: Map<String, DataAvailableDateRange> = new Map<
    string,
    DataAvailableDateRange
  >();

  public chartOptions: ChartOptions = {};
  onInitFinished: boolean = false;

  constructor(
    private readonly dataService: DataService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public readonly reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    public userService: UserService,
    private csvExportService: CsvExportService,
    private sensorService: SensorsService,
    private spinner: NgxSpinnerService,
    private matSnackBar: MatSnackBar
  ) {}

  private beforeScaleFit(scale) {
    // https://stackoverflow.com/questions/37004611/how-to-update-chartjs2-option-scale-tick-max
    let { startDate, endDate } = this.getStartEndDate();
    if (this.customDateRange && startDate && endDate) {
      scale.options.suggestedMin = startDate;
      scale.options.suggestedMax = endDate;
    } else if (
      this.preDefinedDatePeriod.value !== -1 &&
      new Date(this.preDefinedDatePeriod.value).getTime() !==
        new Date(0).getTime()
    ) {
      scale.options.suggestedMin =
        this.preDefinedDatePeriod.value.toISOString();
      scale.options.suggestedMax = new Date(Date.now()).toISOString();
    } else {
      scale.options.suggestedMin = undefined;
      scale.options.suggestedMax = new Date(Date.now()).toISOString();
    }
  }

  private beforeYScaleFit(scale: LinearScale) {
    if (!this.correlationForm.get('selectedField').value?.identifier) {
      return;
    }
    let minMax = getMinMaxFromConfig(
      this.correlationForm.get('selectedField').value.identifier,
      this.config.min_max
    );

    if (minMax.min !== 0) {
      scale.options.min = minMax.min;
    } else {
      scale.options.min = undefined;
    }

    if (minMax.max !== 0) {
      scale.options.max = minMax.max;
    } else {
      scale.options.max = undefined;
    }
  }

  public availableFields: Map<string, SensorsSensorfield> = new Map<
    string,
    SensorsSensorfield
  >();

  public availableSensors: Map<string, SensorsSensor> = new Map<
    string,
    SensorsSensor
  >();

  onDataLoadedComplete = () => {
    // Deterministically set the selected field as the first in the config
    const fieldNames = this.config.sensorFields.map((field) => field.fieldName);

    if (fieldNames.length > 0) {
      if (
        this.config.defaultSelectedField &&
        this.availableFields.has(this.config.defaultSelectedField)
      ) {
        console.log(this.config.defaultSelectedField);
        this.selectedFieldControl.setValue(
          this.availableFields.get(this.config.defaultSelectedField)
        );
      } else {
        this.selectedFieldControl.setValue(
          this.availableFields.get(Array.from(this.availableFields.keys())[0])
        );
      }
    }

    this.loadDatasetAndVisualise();

    this.isLoading = false;
  };

  public async loadData(initialLoading: boolean) {
    let allRequests = [];

    if (initialLoading) {
      // Load Field Information
      const sensorFieldInfoRequests = this.config.sensorFields.map((field) =>
        this.sensorService.sensorsFieldRead(field.fieldName).pipe(
          tap((result) => {
            if (this.fieldHasEnabledSensors(field.fieldName)) {
              this.availableFields.set(field.fieldName, result);
            }
          })
        )
      );

      // Load Information about the sensors
      const sensorInfoRequests = this.config.sensorFields
        .map((field) =>
          field.sensors
            .filter((sensor) => sensor.checked)
            .map((sensor) => {
              return this.sensorService
                .sensorsSensorRead(sensor.influx_name)
                .pipe(
                  tap((result) => {
                    if (sensor.checked) {
                      this.availableSensors.set(sensor.influx_name, result);
                    }
                  })
                );
            })
        )
        .flat();

      // Load the data
      let { startDate, endDate } = this.getStartEndDate();
      let { hourStart, hourEnd } = this.getHourFilter();

      const dataRequests = this.config.sensorFields
        .map((field) =>
          field.sensors
            .filter((sensor) => sensor.checked)
            .map((sensor) => {
              return this.dataService
                .dataMeasurementsList(
                  field.fieldName,
                  sensor.influx_name,
                  startDate,
                  endDate,
                  hourStart,
                  hourEnd,
                  this.correlationForm.get('aggregation').value,
                  this.correlationForm.get('preDefinedLowPassFilter').value
                )
                .pipe(
                  tap((result) => {
                    if (sensor.checked) {
                      this.loadedData.set(
                        field.fieldName,
                        sensor.influx_name,
                        result as any
                      );
                    }
                  })
                );
            })
        )
        .flat();

      // if (!this.customDateRange) allRequests.push(...dateRangeRequests);
      allRequests.push(...sensorFieldInfoRequests);
      allRequests.push(...dataRequests);
      allRequests.push(...sensorInfoRequests);
    }

    const allRequestsHandled = allRequests.map((req$) =>
      req$.pipe(
        catchError((err) => {
          // Probably fix this in the future
          return of(null);
        })
      )
    );

    const merged$ = forkJoin([...allRequestsHandled]);

    this.onDataLoadedComplete.bind(this);

    merged$.subscribe({
      complete: () => this.onDataLoadedComplete(),
    });
  }

  async ngOnInit() {
    this.spinner.show();

    this.correlationForm = this.formBuilder.group({
      selectedField: this.selectedFieldControl,
      preDefinedDateField: this.preDefinedDatePeriod,
      preDefinedLowPassFilter: this.preDefinedLowPassFilterPeriods,
      aggregation: [true],
      filterDateStart: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
      ],
      filterDateEnd: [new Date()],
    });

    await this.loadData(true);

    this.beforeScaleFit = this.beforeScaleFit.bind(this);
    this.beforeYScaleFit = this.beforeYScaleFit.bind(this);

    this.chartOptions = {
      locale: 'de-AT',
      // devicePixelRatio: 10,
      elements: {
        point: {
          // borderWidth: 0,
          // pointStyle: 'crossRot',
          // pointStyle: ''
        },
      },
      plugins: {
        textPlugin: {
          text: 'Keine Daten in diesem Zeitraum vorhanden',
          fontColor: 'rgba(0,0,0,0.4)',
        },
        legend: {
          display: true,
          labels: {
            sort: (a: LegendItem, b: LegendItem, data: ChartData): number => {
              if (a.text < b.text) {
                return -1;
              }
              if (a.text > b.text) {
                return 1;
              }
              return 0;
            },
          },
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
          intersect: true,
          callbacks: {
            title: (object) => {
              let data = object[0];
              return this.datePipe.transform(
                data.dataset.data[data.dataIndex]['x'],
                'short',
                undefined,
                'de-AT'
              );
            },
            label: (data: any) => {
              let label = data.dataset.label || '';
              if (label) {
                label += ': ';
              }
              label += (
                data.dataset.data[data.dataIndex]['y'] as number
              ).toFixed(3);
              return label;
            },
          },
        },
      },

      animation: {
        duration: 0,
      },
      hover: {},
      responsive: true,
      scales: {
        y: {
          beforeFit: this.beforeYScaleFit,
          title: {
            display: true,
            text: this.config.y_label,
          },
          ticks: {
            callback: (value: string | number, index, values) => {
              let range = Math.abs(
                values[0].value - values[values.length - 1].value
              );

              value = parseFloat(value as string);

              const labels = values.map((v) => v.label);
              // Check if labels has duplicates
              new Set(labels).size !== labels.length;

              if (range <= 20) {
                if (new Set(labels).size !== labels.length) {
                  value = value.toFixed(3);
                } else {
                  value = value.toFixed(2);
                }
              } else {
                value = value.toFixed(0);
              }

              let suffix = this.selectedFieldControl.value?.field_unit || '';
              return `${value} ${suffix}`;
            },
            includeBounds: true,
          },
        },
        x: {
          beforeFit: this.beforeScaleFit,
          type: 'time',
          bounds: 'ticks',
          // suggestedMin: new Date(0).toISOString(),
          ticks: {
            source: 'auto',
            includeBounds: true,

            // callback: (value, index, values) => {
            //   this.datePipe.transform(value, 'de-AT');
            //   return '';
            // },
          },
          time: {
            displayFormats: {
              hour: 'DD.MM.YYYY - HH:mm',
              day: 'DD.MM.YYYY',
              week: 'DD.MM.YYYY',
            },
          },
          title: {
            display: true,
            text: this.config.x_label,
          },
        },
      },
    };

    const noDataPlugin = ChartJSNoDataPlugin();
    this.chart.plugins.push(noDataPlugin);

    this.onInitFinished = false;
  }

  private getHourFilter(): { hourStart: number; hourEnd: number } {
    if (this.config.filter.enabled) {
      return {
        hourStart: this.config.filter.hourStart,
        hourEnd: this.config.filter.hourEnd,
      };
    }
    return { hourStart: 0, hourEnd: 23 };
  }
  private getStartEndDate(): { startDate: string; endDate: string } {
    let startDate: string;
    let endDate: string;
    if (this.customDateRange || this.preDefinedDatePeriod.value === -1) {
      let filterDateStartValue =
        this.correlationForm.controls['filterDateStart'].value;
      let filterDateEndValue =
        this.correlationForm.controls['filterDateEnd'].value;

      // Convert the date object to a moment object
      filterDateStartValue = moment(filterDateStartValue);
      filterDateEndValue = moment(filterDateEndValue);

      startDate = filterDateStartValue
        ? filterDateStartValue.startOf('day').utcOffset(0, true).toISOString()
        : new Date(0).toISOString();
      endDate = filterDateEndValue
        ? filterDateEndValue.startOf('day').utcOffset(0, true).toISOString()
        : new Date(0).toISOString();
    } else {
      startDate = this.preDefinedDatePeriod.value.toISOString();
      endDate = new Date(Date.now()).toISOString();
    }
    return { startDate, endDate };
  }

  private loadDatasetAndVisualise() {
    // Currently selected field
    let selectedField = this.selectedFieldControl.value as SensorsSensorfield;

    const minMaxDate = this.minMaxDates.get(selectedField.identifier);

    if (minMaxDate) {
      if (!this.customDateRange) {
        this.correlationForm.controls['filterDateStart'].setValue(
          minMaxDate.start_date
        );
        this.correlationForm.controls['filterDateEnd'].setValue(
          minMaxDate.end_date
        );
      }
    }

    for (let key of this.loadedData.map.get(selectedField.identifier).keys()) {
      this.updateGraph(selectedField.identifier, key);
    }

    this.chart.chart.update();
  }

  public updateGraph(field: string, sensor: string) {
    let dataset = this.loadedData.get(field, sensor) as any;

    if (!dataset) {
      return;
    }

    if (this.relativeZeroFiltering) {
      dataset = computeZeroFilteredDataset(dataset, field);
    }

    const datapoints = dataset.map(
      (datapoint) =>
        <any>{
          x: datapoint._time,
          y: datapoint[this.selectedFieldControl.value.identifier],
        }
    );

    const datapointColor = this.availableSensors.get(sensor).color;

    const data = {
      data: datapoints,
      label: `${this.availableSensors.get(sensor).display_name}`,
      fill: false,
      lineTension: 0,
      pointBorderWidth: 0,
      pointBorderColor: 'rgba(0, 0, 0, 0)',
      pointRadius: 1.5,
      borderWidth: 1.5,
      hoverRadius: 3,
      pointBackgroundColor: datapointColor,
      backgroundColor: datapointColor,
      borderColor: datapointColor,
    };

    this.chartData.push(data as never);

    if (this.chart) {
      // this.chart.updateColors();
      this.chart.chart.update();
    }
  }

  public selectionChanged(event) {
    this.reloadGraph(true);
  }

  public selectionParameterChanged(event) {
    this.reloadGraph();
  }

  public relativeZeroChanged(event: MatSlideToggleChange) {
    this.relativeZeroFiltering = event.checked;
    this.reloadGraph();
  }

  private async loadDateRange() {
    // Load date ranges
    const dateRangeRequests = this.config.sensorFields
      .map((field) =>
        field.sensors
          .filter((sensor) => sensor.checked)
          .map((sensor) => {
            return this.dataService
              .dataDaterangeInfluxList(sensor.influx_name, field.fieldName)
              .pipe(
                tap((result) => {
                  // console.log(field.fieldName, result);
                  result = {
                    start_date: new Date(result.start_date),
                    end_date: new Date(result.end_date),
                  } as any;

                  if (!this.minMaxDates.has(field.fieldName)) {
                    this.minMaxDates.set(field.fieldName, result);
                  } else {
                    let currentMinMax = this.minMaxDates.get(field.fieldName);
                    if (result.end_date > currentMinMax.end_date) {
                      currentMinMax.end_date = result.end_date;
                    }
                    if (result.start_date < currentMinMax.start_date) {
                      currentMinMax.start_date = result.start_date;
                    }
                  }
                })
              );
          })
      )
      .flat();

    const dateRangeRequestsHandled = dateRangeRequests.map((req$) =>
      req$.pipe(
        catchError((err) => {
          // Probably fix this in the future
          return of(null);
        })
      )
    );

    const merged$ = forkJoin([...dateRangeRequestsHandled]);

    merged$.subscribe({
      complete: () => {
        this.onDataLoadedComplete();
        this.customDateRange = true;
        this.isLoading = false;
      },
    });
  }

  public async dateRangeSelectionChanged(event) {
    if (event.value === -1) {
      // Start loading screen
      this.customDateRange = true;
      this.isLoading = true;
      this.loadDateRange();
    } else {
      this.customDateRange = false;
      this.reloadGraph(true);
    }
  }

  public customDateFilterChanged(event) {
    this.reloadGraph(true);
  }

  private reloadGraph(dateChange: boolean = false) {
    this.isLoading = true;
    this.spinner.show();
    this.emptyArray(this.chartData);
    this.emptyArray(this.chartLabels);
    this.emptyArray(this.labelsComplete);

    // Fetch all the data again with the new date range
    if (dateChange) {
      this.isLoading = true;
      this.loadedData.clear();
      this.availableFields.clear();
      this.availableSensors.clear();

      this.loadData(true);
    }

    this.loadedDatasets.clear();

    // Gets called async by the data loader
    if (!dateChange) {
      this.loadDatasetAndVisualise();
      this.isLoading = false;
    }
  }

  private emptyArray(array: any[]) {
    while (array.length) {
      array.pop();
    }
  }

  // Set white background color so the download looks nice
  // https://stackoverflow.com/a/50126796/
  private fillCanvasBackgroundWithColor(canvas, color) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  public async exportCurrentView(event) {
    this.chart.chart.options.devicePixelRatio = 2;
    this.chartWidth = '1920px';
    let countdown = 0;
    while (countdown < 2) {
      await timer(100).pipe(take(1)).toPromise();
      countdown++;
    }
    this.fillCanvasBackgroundWithColor(this.chart.chart.canvas, '#FFFFFF');

    let a = document.createElement('a');
    a.href = this.chart.chart.toBase64Image('image/png', 1);
    let date = new Date();
    a.download = `${this.reportModuleService.currentReport.name}_${date
      .toLocaleDateString('de-AT')
      .replace(/\./g, '_')}.png`;

    a.click();
    this.chart.chart.options.devicePixelRatio = 1;
    this.chartWidth = '100%';
  }

  public async exportAsCSV(event) {
    this.isLoading = true;
    let { startDate, endDate } = this.getStartEndDate();
    let { hourStart, hourEnd } = this.getHourFilter();
    console.log(this.selectedFieldControl);
    this.dataService
      .dataCsvExportList(
        this.selectedFieldControl.value.identifier,
        startDate,
        endDate,
        hourStart,
        hourEnd,
        Array.from(this.availableSensors.values())
          .filter((sensor) =>
            sensor.available_fields
              .map((field) => field.identifier)
              .includes(this.selectedFieldControl.value.identifier)
          )
          .map((sensor) => sensor.influx_name)
      )
      .subscribe((result) => {
        // Result is a CSV blob, download it
        let a = document.createElement('a');
        a.href = URL.createObjectURL(result);
        let date = new Date();
        a.download = `${this.reportModuleService.currentReport.name}_${date
          .toLocaleDateString('de-AT')
          .replace(/\./g, '_')}.csv`;

        a.click();
        this.isLoading = false;
      });
  }

  public fieldHasEnabledSensors(field: string): boolean {
    let sensors = this.config.sensorFields.find((f) => f.fieldName === field);
    if (!sensors) {
      return false;
    }

    let enabledSensors = sensors.sensors.filter((s) => s.checked);
    return enabledSensors.length > 0;
  }

  get numberEnabledSensors(): number {
    let enabledSensors = this.config.sensorFields
      .map((field) => field.sensors.filter((s) => s.checked))
      .flat();
    return enabledSensors.length;
  }

  aggregationChanged(event: MatSlideToggleChange) {
    this.reloadGraph(true);
  }

  valuesCurrentlyDisplay(): number {
    if (!this.chart?.chart?.data?.datasets) return 0;
    if (!this.loadedData) return 0;
    if (!this.selectedFieldControl?.value) return 0;

    const selectedField = this.selectedFieldControl.value as SensorsSensorfield;

    if (this.loadedData.map.get(selectedField.identifier) === undefined)
      return 0;

    return Array.from(
      this.loadedData.map.get(selectedField.identifier).values()
    ).reduce((max, currentArray) => Math.max(max, currentArray.length), 0);
  }
}
