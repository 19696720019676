<div *ngIf="selectedDatasource">
  {{ selectedDatasource.name }}
</div>
<div class="container">
  <mat-list>
    <div
      *ngFor="let client of availableClients"
      id="client-datasource-collection"
    >
      <div mat-subheader>{{ client.name }}</div>
      <mat-divider></mat-divider>
      <mat-list-item
        *ngFor="let datasource of datasourcesForClient(client)"
        (click)="editOrCreateDataSource(datasource)"
      >
        <mat-icon mat-list-icon>file_present</mat-icon>
        <div mat-line>{{ datasource.name }}</div>
        <!-- <div mat-line>{{ folder.updated | date }}</div> -->
      </mat-list-item>
    </div>
  </mat-list>

  <div class="justify-content-center d-flex">
    <div class="add-report-button d-flex flex-row align-items-center">
      <div class="p-2 button-text">Datenquelle hinzufügen</div>
      <button
        class="ml-auto"
        mat-fab
        color="primary"
        aria-label="Element hinzufügen"
        (click)="editOrCreateDataSource(null)"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
