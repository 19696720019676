<mat-card>
  <mat-card-header>
    <!-- <mat-card-title>
      {{ config.headline }}
    </mat-card-title> -->
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form novalidate #form="ngForm">
      <md-editor
        #md="ngModel"
        name="content"
        [(ngModel)]="content"
        [mode]="mode"
        [options]="editorOptions"
        required
        maxlength="600"
        height="400px"
      ></md-editor></form
  ></mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button
      mat-button
      (click)="reportModuleService.openEditReportModuleForm(config.guid)"
    >
      BEARBEITEN
    </button>
    <button
      (click)="reportModuleService.removeModuleFormReport(config.guid)"
      mat-button
    >
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
