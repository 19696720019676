import { DataPoint } from './orthofoto.service';
import LineString from 'ol/geom/LineString';
import { Feature } from 'ol';
import { Style, Icon, Stroke, Text, Fill } from 'ol/style';
import { Point } from 'ol/geom';

export function generateSVG(dx, dy, color, referenceScaleSize) {
  dx += referenceScaleSize / 2;
  dy += referenceScaleSize / 2;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" pointer-events="none" width="${referenceScaleSize}" height="${referenceScaleSize}">
    <line pointer-events="none" x1="${referenceScaleSize / 2}" y1="${
    referenceScaleSize / 2
  }" y2="${dy}" x2="${dx}" stroke-width="2" stroke="${color}"></line></svg>`;
  return svg;
}

export function circleSVG(
  color: string = 'blue',
  width: number = 20,
  height: number = 20,
  radius: number = 4
) {
  return `<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="${radius}" fill="${color}"/>
    </svg>`;
}

export interface AxisConfig {
  x1: Number;
  y1: Number;
  x2: Number;
  y2: Number;
  text: string;
}

export interface DataFilterConfig {
  enabled: boolean;
  from: string;
  till: string;
}

export interface OrthofotoUserPreferences {
  selected_date: string;
}

export interface OrthofotoConfig {
  guid: string;
  type: string;
  dataset: Array<string>;
  headline: string;
  axis: Array<AxisConfig>;
  filter: DataFilterConfig;
  user_settings: OrthofotoUserPreferences;
  dynamic_center_point: {
    difference_hours: number;
    color_ok: string;
    color_warning: string;
  };
  color_height_vector: string;
  color_2d_vector: string;
  scale_size_factor: number;
}

// Parsing config is not required in future methods
export function parseConfig(config: any): OrthofotoConfig {
  let axis: Array<AxisConfig> = config.axis.map((element) => {
    return {
      x1: Number(element.x1),
      y1: Number(element.y1),
      x2: Number(element.x2),
      y2: Number(element.y2),
      text: element.text,
    };
  });
  return {
    guid: config.guid,
    type: config.type,
    dataset: config.dataset,
    headline: config.headline,
    axis: axis,
    filter: {
      enabled: config.filter.enabled === 'true',
      from: config.filter.from,
      till: config.filter.till,
    },
    user_settings: {
      selected_date: config.user_settings?.selected_date,
    },
    dynamic_center_point: {
      difference_hours: config.dynamic_center_point.difference_hours,
      color_ok: config.dynamic_center_point.color_ok,
      color_warning: config.dynamic_center_point.color_warning,
    },
    color_height_vector: config.color_height_vector,
    color_2d_vector: config.color_2d_vector,
    scale_size_factor: config.scale_size_factor,
  };
}

export function largestValueInDataPoint(datapoint: DataPoint): number {
  return Math.max(Math.abs(datapoint.dx), Math.abs(datapoint.lengthdXdY));
}

export function getBahnachseFeature(p1, p2, displayText) {
  const lineString = new LineString([p1, p2]);

  var lineStringFeature = new Feature({
    geometry: lineString,
  });

  lineStringFeature.setStyle(() => {
    var styles = [];
    lineString.forEachSegment((start, end) => {
      // var zoom = map.getView().getZoom();
      // var scale = clamp(1.6 - (19 - zoom) / 1.2, 0, 1.6);
      // console.log(zoom, scale);
      var dx = end[0] - start[0];
      var dy = end[1] - start[1];
      var rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(end),
          image: new Icon({
            src: 'assets/orthophoto/arrow.svg',
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
          text: new Text({
            text: displayText,
            rotateWithView: true,
            rotation: -rotation + Math.PI,
            stroke: new Stroke({
              color: 'black',
            }),
            fill: new Fill({
              color: 'white',
            }),

            scale: 1.3,
            overflow: false,
            offsetY: -10,
          }),
        })
      );
    });
    return styles;
  });
  return lineStringFeature;
}

export function getCustomControl(referenceScaleSize): HTMLElement {
  const div = document.createElement('div');

  div.innerHTML = `<div id="scale-factor"><div>Maßstab: <span id="scaleFactorValue"></span>m</div><svg width="${
    referenceScaleSize / 2
  }" height="5">
    <line pointer-events="none" x1="0" y1="0" y2="0" x2="${
      referenceScaleSize / 2
    }" stroke-width="5" stroke="red"></line></svg></div>

    <div id="scale-factor">Bewegung Höhe <svg width="5" height="20">
    <line pointer-events="none" x1="0" y1="0" y2="20" x2="0" stroke-width="5" stroke="yellow"></line></svg></div>

    <div id="scale-factor">Bewegung Lage <svg width="20" height="10">
    <line pointer-events="none" x1="0" y1="0" y2="0" x2="20" stroke-width="5" stroke="red"></line></svg></div>
    `;
  div.style.width = `${referenceScaleSize / 2}px`;
  div.style.padding = '5px';
  div.style.marginLeft = '10px';
  div.style.marginTop = '10px';
  div.style.position = 'aboslute';
  div.style.bottom = '10px';
  div.style.fontFamily = 'sans-serif';
  div.className = 'ol-control ol-unselectable bottom-left';
  div.style.backgroundColor = 'rgba(255, 255, 255, 0.3)';
  return div;
}
