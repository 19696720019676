import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';

import { RoleBatteryConfig } from 'src/app/modules/role-battery/role-battery.user-settings';

import * as uuid from 'uuid';
import { ManagementService, TrigonosDatasource } from 'src/app/api/generated';

@Component({
  selector: 'app-role-battery-config',
  templateUrl: './role-battery-config.component.html',
  styleUrls: ['./role-battery-config.component.scss'],
})
export class RoleBatteryConfigComponent implements OnInit {
  public roleBatteryForm: UntypedFormGroup;
  public availableDataSources: TrigonosDatasource[] = [];
  public editMode = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoleBatteryConfig,
    private dialogRef: MatDialogRef<RoleBatteryConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private dataChangedService: DataChangedService,
    private managementService: ManagementService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    this.roleBatteryForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data.guid : uuid.v4()],
      type: ['role-battery'],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      datasource: ['', Validators.required],
    });

    // Fetch available datasources
    this.managementService
      .managementDatasourceReportRead(this.reportModuleService.currentReport.id)
      .subscribe((datasources) => {
        this.availableDataSources = datasources;
        if (this.data?.dataset) {
          this.roleBatteryForm.get('datasource').setValue(this.data.dataset);
        } else {
          this.roleBatteryForm
            .get('datasource')
            .setValue(this.availableDataSources[0].id);
        }
      });
  }

  public updateOrCreateModule() {
    if (this.roleBatteryForm.valid) {
      this.reportModuleService
        .addOrEditModuleToReport(
          <RoleBatteryConfig>{
            guid: this.roleBatteryForm.value.guid,
            dataset: this.roleBatteryForm.value.datasource,
            headline: this.roleBatteryForm.value.headline,
            type: this.roleBatteryForm.value.type,
            user_settings: {
              margin_warning: this.data?.user_settings.margin_warning
                ? this.data?.user_settings.margin_warning
                : null,
              measured_margin: this.data?.user_settings.measured_margin
                ? this.data?.user_settings.measured_margin
                : null,
              measuring_date: this.data?.user_settings.measuring_date
                ? this.data?.user_settings.measuring_date
                : null,
            },
          },
          this.editMode
        )
        .subscribe(
          (success) => {
            this.dialogRef.close();
            this.dataChangedService.configUpdated(
              this.reportModuleService.currentReport.id,
              this.roleBatteryForm.value.guid
            );
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }
}
