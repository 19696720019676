import { Component, OnInit } from '@angular/core';
import { DataChangedService, HEADLINE_TYPE } from '../data-changed.service';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
  constructor(private dataChangedService: DataChangedService) {}

  ngOnInit(): void {
    this.dataChangedService.setHeadline({
      headline: 'Willkommen',
      type: HEADLINE_TYPE.GENERIC,
      image: null,
      subheadline: null,
    });
  }
}
