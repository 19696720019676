import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ChartOptions, LinearScale, Plugin } from 'chart.js';

import Gradient from 'javascript-color-gradient';

import { BaseChartDirective } from 'ng2-charts';
import { Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DataService,
  ManagementService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import 'chartjs-adapter-moment';

import { DataPoint } from 'src/app/api/generated';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { MovementScatterplotConfigComponent } from '../config/movement_scatterplot-config/movement_scatterplot-config.component';
import {
  MovementScatterplotConfig,
  parseGNSSData,
  DataPointGnss,
  selectableTimePeriods,
  CHART_COLORS,
  ChartJSImagePlugin,
  htmlLegendPlugin,
  round,
} from '../movement_scatter.tools';
import * as moment from 'moment';

import { CsvExportService } from 'src/app/csv-export.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-movement-scatterplot',
  templateUrl: './movement_scatterplot.component.html',
  styleUrls: ['./movement_scatterplot.component.scss'],
})
export class MovementScatterplotComponent implements OnInit {
  @Input() config: MovementScatterplotConfig;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @ViewChild('legendCanvas') legendCanvas: ElementRef<HTMLCanvasElement>;
  public legendCanvasContext: CanvasRenderingContext2D;

  @ViewChild('legendDiv') legendDiv: ElementRef<HTMLDivElement>;

  public static moduleDetails: ModuleConfiguration = {
    displayName: 'Bewegungs Scatterplot',
    previewImagePath: 'assets/movement_scatter_preview.png',
    settingsComponent: MovementScatterplotConfigComponent,
  };

  public chartData: [] = [];
  public chartLabels: any = [];
  private labelsComplete: any = [];
  private loadedDatasets: Map<string, DataPointGnss[]> = new Map<
    string,
    DataPointGnss[]
  >();
  public chartWidth = '100%';
  public legendDivHeight = '100%';

  public settingsForm: UntypedFormGroup;
  public selectedFieldControl = new UntypedFormControl();
  public lowPassDateRange = new UntypedFormControl();

  public selectableTimePeriods = selectableTimePeriods;
  public preDefinedDatePeriod = new UntypedFormControl(selectableTimePeriods[5].value);

  public datasetDetails: Map<string, TrigonosDatasource> = new Map<
    string,
    TrigonosDatasource
  >();

  public maxDates: Map<string, Date> = new Map();
  public minDates: Map<string, Date> = new Map();
  public customDateRange = false;
  private lastCustomDateRange = Date.now();
  public relativeZeroFiltering = false;
  public gradientColors = {
    start: '#3F2CAF',
    end: '#e9FF6a',
  };

  public htmlLegendPlugin = htmlLegendPlugin;

  public chartOptions: ChartOptions = {};
  onInitFinished: boolean = false;

  constructor(
    private readonly dataService: DataService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public readonly reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    public userService: UserService,
    private csvExportService: CsvExportService
  ) {}

  private getlargestValueFromDataset(): number {
    let data = this.loadedDatasets.get(this.selectedFieldControl.value);
    if (!data) {
      return 0;
    }
    const maxdl = Math.max(...data.map((o) => Math.abs(o.dl)));
    const maxdq = Math.max(...data.map((o) => Math.abs(o.dq)));
    const max = Math.max(...[maxdl, maxdq]);
    return round(max * 1.2, 2);
  }

  private beforeXScaleFit(scale: LinearScale) {
    const max = this.getlargestValueFromDataset();
    if (scale.options.max !== max) {
      scale.options.max = max;
      scale.options.min = -max;
      this.chart.update();
    }
  }

  private beforeYScaleFit(scale: LinearScale) {
    const max = this.getlargestValueFromDataset();
    if (scale.options.max !== max) {
      scale.options.max = max;
      scale.options.min = -max;
      this.chart.update();
    }
  }

  async ngOnInit() {
    this.settingsForm = this.formBuilder.group({
      preDefinedDateField: this.preDefinedDatePeriod,
      filterDateStart: [''],
      filterDateEnd: [''],
    });

    this.selectedFieldControl.setValue(this.config.dataset[0], {
      onlySelf: true,
    });

    for (let dataset of this.config.dataset) {
      let daterange = await this.dataService
        .dataDaterangeRead(dataset)
        .toPromise();

      this.minDates.set(dataset, new Date(daterange.start_date));
      this.maxDates.set(dataset, new Date(daterange.end_date));

      let datasetDetails: TrigonosDatasource = await this.managementService
        .managementDatasourceDetailRead(dataset)
        .toPromise();

      this.datasetDetails.set(dataset, datasetDetails);
    }

    this.settingsForm.controls['filterDateStart'].setValue(
      this.minDates.values[0]
    );
    this.settingsForm.controls['filterDateEnd'].setValue(
      this.minDates.values[0]
    );

    this.beforeXScaleFit = this.beforeXScaleFit.bind(this);
    this.beforeYScaleFit = this.beforeYScaleFit.bind(this);

    this.chartOptions = {
      elements: {
        point: {},
      },
      plugins: {
        legend: {
          display: false,
          onClick: (event, legendItem, legend) => {},
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          intersect: true,
          callbacks: {},
        },
      },

      animation: {
        duration: 0,
      },
      hover: {
        mode: null,
      },
      responsive: true,
      scales: {
        y: {
          // min: -0.1,
          // max: 0.1,
          beforeFit: this.beforeYScaleFit,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, values) => {
              value = round(value, 2);
              let prefix = 'm';
              return `${value} ${prefix}`;
            },
          },
          title: {
            text: 'Verschiebung Quer',
            display: true,
          },
        },
        x: {
          // min: -0.1,
          // max: 0.1,
          beforeFit: this.beforeXScaleFit,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value, index, values) => {
              value = round(value, 2);
              let prefix = 'm';
              return `${value} ${prefix}`;
            },
          },
          title: {
            text: 'Verschiebung Längs',
            display: true,
          },
        },
      },
    };

    this.onInitFinished = true;
  }

  async ngAfterViewInit() {
    while (true) {
      if (this.onInitFinished) {
        break;
      }
      await timer(100).pipe(take(1)).toPromise();
    }
    this.reloadGraph();
    this.legendCanvasContext = this.legendCanvas.nativeElement.getContext('2d');
    const gradient = this.legendCanvasContext.createLinearGradient(
      0,
      0,
      0,
      170
    );
    gradient.addColorStop(0, this.gradientColors.start);
    gradient.addColorStop(1, this.gradientColors.end);
    this.legendCanvasContext.fillStyle = gradient;
    this.legendCanvasContext.fillRect(0, 0, 9999, 9999);

    // Register background image plugin
    const imagePlugin: Plugin = ChartJSImagePlugin(
      `${environment.apiEndpoint}${this.config.bg_image_resource_url}`
    );
    this.chart.plugins.push(imagePlugin);

    // Make sure that the legend has the right height
    this.onChartResize(undefined);
  }

  private getStartEndDate(): { startDate: string; endDate: string } {
    let startDate: string;
    let endDate: string;
    if (this.customDateRange) {
      startDate = this.datePipe.transform(
        this.settingsForm.controls['filterDateStart'].value,
        'full',
        undefined,
        'en-US'
      );
      endDate = this.datePipe.transform(
        this.settingsForm.controls['filterDateEnd'].value,
        'full',
        undefined,
        'en-US'
      );
    } else {
      startDate = this.preDefinedDatePeriod.value.toISOString();
      endDate = new Date(Date.now()).toISOString();
    }
    return { startDate: startDate, endDate: endDate };
  }

  private loadDatasetAndVisualise() {
    let { startDate, endDate } = this.getStartEndDate();

    for (let datasetName of this.config.dataset) {
      let loadedDataset: Observable<DataPoint[]>;
      if (this.config.filter.enabled) {
        loadedDataset = this.dataService.dataDataRead(
          datasetName,
          startDate,
          endDate,
          ['dl', 'dq'],
          new Date(0).toISOString(),
          this.config.filter.from,
          this.config.filter.till
        );
      } else {
        loadedDataset = this.dataService.dataDataRead(
          datasetName,
          startDate,
          endDate,
          ['dl', 'dq'],
          new Date(0).toISOString()
        );
      }

      loadedDataset.subscribe(
        (value) => {
          this.loadedDatasets.set(datasetName, parseGNSSData(value));
          this.updateGraph(datasetName);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    this.chart.chart.update();
  }

  public updateGraph(datasetName: string) {
    let dataset = this.loadedDatasets.get(datasetName);
    if (!dataset) {
      return;
    }

    const datapoints = dataset.map(
      (datapoint) =>
        <any>{
          x: datapoint.dl,
          y: datapoint.dq,
        }
    );

    const datapointColor =
      CHART_COLORS[this.config.dataset.indexOf(datasetName)];

    const data = {
      meta: datasetName,
      hidden: this.selectedFieldControl.value != datasetName,
      data: datapoints,
      label: `${this.datasetDetails.get(datasetName).name}`,
      fill: false,
      lineTension: 0,
      pointBorderWidth: 0.2,
      pointBorderColor: 'black',
      pointRadius: 2.2,
      borderWidth: 0.5,
      hoverRadius: 3,
      pointBackgroundColor: (context) => {
        const gradientArray = new Gradient()
          .setColorGradient('#3F2CAF', '#e9FF6a')
          .setMidpoint(context.dataset.data.length)
          .getColors();

        return gradientArray[context.dataIndex];
      },

      backgroundColor: datapointColor,
      borderColor: 'black',
      showLine: false,
    };

    this.chartData.push(data as never);

    if (this.chart) {
      this.chart.chart.update();
    }
  }

  /**
   * Shows only selected dataset on the plot
   * @param event Dropdown menu selection changed
   */
  public selectionChanged(event) {
    for (let dataset of this.chart.chart.data.datasets) {
      if ((dataset as any).meta == event.value) {
        dataset.hidden = false;
      } else {
        dataset.hidden = true;
      }
    }
    this.chart.update();
  }

  public dateRangeSelectionChanged(event) {
    if (event.value === -1) {
      this.customDateRange = true;
    } else {
      this.customDateRange = false;
    }
    this.reloadGraph();
  }

  public customDateFilterChanged(event) {
    if (Date.now() - this.lastCustomDateRange > 100) {
      this.lastCustomDateRange = Date.now();
      this.reloadGraph();
    }
  }

  private reloadGraph() {
    this.emptyArray(this.chartData);
    this.emptyArray(this.chartLabels);
    this.emptyArray(this.labelsComplete);

    this.loadedDatasets.clear();

    this.loadDatasetAndVisualise();
  }

  private emptyArray(array: any[]) {
    while (array.length) {
      array.pop();
    }
  }

  // Set white background color so the download looks nice
  // https://stackoverflow.com/a/50126796/
  private fillCanvasBackgroundWithColor(canvas, color) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  public onChartResize(event) {
    // Subtract 64px for the height of the legend
    this.legendDivHeight = `${this.chart.chart.height - 64}px`;
  }

  public async exportCurrentView(event) {
    this.chart.chart.options.devicePixelRatio = 2;
    this.chartWidth = '1920px';
    let countdown = 0;
    while (countdown < 2) {
      await timer(100).pipe(take(1)).toPromise();
      countdown++;
    }
    this.fillCanvasBackgroundWithColor(this.chart.chart.canvas, '#FFFFFF');

    let a = document.createElement('a');
    a.href = this.chart.chart.toBase64Image('image/png', 1);
    let date = new Date();
    a.download = `${this.reportModuleService.currentReport.name}_${date
      .toLocaleDateString('de-AT')
      .replace(/\./g, '_')}.png`;

    a.click();
    this.chart.chart.options.devicePixelRatio = 1;
    this.chartWidth = '100%';
  }

  public getParameterDisplayName(field): string {
    if (this.datasetDetails.size === 0) {
      return '';
    } else if (this.datasetDetails.has(field)) {
      return this.datasetDetails.get(field).table_name;
    }
    return 'N/A';
  }

  public getMinDate(): Date {
    let result = this.minDates.get(this.selectedFieldControl.value);
    if (!result) {
      result = new Date();
    }

    return result;
  }

  public getMaxDate(): Date {
    let result = this.maxDates.get(this.selectedFieldControl.value);
    if (!result) {
      result = new Date();
    }

    return result;
  }
}
