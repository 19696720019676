import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { isMatch } from 'date-fns';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  TIMESTAMP_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/;

  constructor(
    public router: Router,
    private matSnackBar: MatSnackBar,
    private keycloakService: KeycloakService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.webcamEndpoint)) {
      req = req.clone({
        url: decodeURIComponent(req.url),
        headers: req.headers.set(
          'Authorization',
          `25caf3d42ff007964551504d7cd2139c14c7a91b`
        ),
      });

      const found = req.url.match(this.TIMESTAMP_REGEX);

      if (found && isMatch(found[0], "yyyy-MM-dd'T'HH:mm:ssX")) {
        req = req.clone({
          url: decodeURIComponent(req.url),
          headers: req.headers.set(
            'Authorization',
            `25caf3d42ff007964551504d7cd2139c14c7a91b`
          ),
          responseType: 'blob' as 'json',
        });
      }
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Blob) {
          return throwError(error);
        }
        // API IS OFFLINE
        if (error.status === 0) {
          // @ToDo
          // Disable Snackbar for now

          // this.matSnackBar
          //   .open('Keine Verbindung möglich.', 'Seite neu laden', {
          //     // duration: 10000,
          //   })
          //   .afterDismissed()
          //   .subscribe((info) => {
          //     if (info.dismissedByAction) {
          //       window.location.reload();
          //     }
          //   });
          return;
        } else if (error.status === 401) {
          // USER NOT AUTHORIZED
          // Redirect User to login page
          this.keycloakService.login();
        }
        console.log('error is intercept');
        console.error(error);
        return throwError(error.message);
      })
    );
  }
}
