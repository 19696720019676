import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  ManagementService,
  ReportsService,
  TrigonosDatasource,
} from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import {
  getMinMaxFromConfig,
  MinMaxValue,
  MovementScatterplotConfig,
} from '../../movement_scatter.tools';

import * as uuid from 'uuid';
import { validTime } from 'src/app/tools/validators';
import { toNumberFromENString, toNumberStringDE } from 'src/app/user/tools';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface VisualisableField {
  id: string;
  label: string;
  unit: string;
}

@Component({
  selector: 'app-movement-scatterplot-config',
  templateUrl: './movement_scatterplot-config.component.html',
  styleUrls: ['./movement_scatterplot-config.component.scss'],
})
export class MovementScatterplotConfigComponent
  implements OnInit, AfterViewInit
{
  public imagePreviewURL: SafeUrl;
  public correlationForm: UntypedFormGroup;
  public availableDataSources: TrigonosDatasource[] = [];
  public editMode = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MovementScatterplotConfig,
    private dialogRef: MatDialogRef<MovementScatterplotConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private reportService: ReportsService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    this.correlationForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data?.guid : uuid.v4()],
      type: ['movement_scatterplot'],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      datasources: this.formBuilder.array([]),
      timePeriodFiltered:
        this.data?.filter.enabled === undefined
          ? true
          : this.data?.filter.enabled,
      timePeriodFilteredFrom: [
        this.data?.filter.from ? this.data?.filter.from : '22:00',
        [Validators.required, validTime()],
      ],
      timePeriodFilteredTill: [
        this.data?.filter.till ? this.data?.filter.till : '22:00',
        [Validators.required, validTime()],
      ],
      imageSource: ['', [Validators.required]],
    });

    this.managementService
      .managementDatasourceReportRead(this.reportModuleService.currentReport.id)
      .subscribe((datasources) => {
        this.availableDataSources = datasources;
        // New module is created
        if (!this.data?.guid) {
          this.availableDataSources.forEach((datasource) => {
            this.datasourcesFromFormGroup().push(new UntypedFormControl(false));
          });
        } else {
          this.availableDataSources.forEach((datasource) => {
            const enabled = this.data.dataset.indexOf(datasource.id) !== -1;
            this.datasourcesFromFormGroup().push(new UntypedFormControl(enabled));
          });
        }
      });
  }

  async ngAfterViewInit() {
    if (this.editMode) {
      this.fetchImageAndDisplay();
    }
  }

  public onFileChange(event) {
    const fileReader = new FileReader();

    if (event.target.files?.length > 0) {
      const [file] = event.target.files;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result]);
        const url = URL.createObjectURL(blob);
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.imagePreviewURL = sanitizedUrl;
        this.correlationForm.get('imageSource').patchValue(blob);
        // this.image.nativeElement.src = this.imagePreviewURL;
        this.cdr.detectChanges();
      };
    }
  }

  public async updateOrCreateModule() {
    if (!this.correlationForm.valid) {
      return;
    }

    let datasets = this.correlationForm.value.datasources
      .map((enabled, index) => {
        if (enabled) {
          return this.availableDataSources[index].id;
        }
      })
      .filter(Boolean);

    // Upload image and get path
    const uploadedImage = await this.reportService
      .uploadsReportImage(this.correlationForm.value.imageSource)
      .toPromise();
    const resourceURL = uploadedImage.image_path;

    this.reportModuleService
      .addOrEditModuleToReport(
        <MovementScatterplotConfig>{
          guid: this.correlationForm.value.guid,
          type: this.correlationForm.value.type,
          headline: this.correlationForm.value.headline,
          filter: {
            enabled: this.correlationForm.value.timePeriodFiltered,
            from: this.correlationForm.value.timePeriodFilteredFrom,
            till: this.correlationForm.value.timePeriodFilteredTill,
          },
          user_settings: {
            start_date: null,
            end_date: null,
          },
          dataset: datasets,
          bg_image_resource_url: resourceURL,
        },
        this.editMode
      )
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.dataChangedService.configUpdated(
            this.reportModuleService.currentReport.id,
            this.correlationForm.value.guid
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  async fetchImageAndDisplay() {
    const imageURL = `${environment.apiEndpoint}${this.data.bg_image_resource_url}`;
    const reader = new FileReader();

    let response = await fetch(imageURL as string);
    let blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.imagePreviewURL = sanitizedUrl;
    this.correlationForm.get('imageSource').patchValue(blob);

    this.cdr.detectChanges();
  }

  get timeFilterDisabled(): string | null {
    return this.correlationForm.get('timePeriodFiltered').value ? null : '';
  }

  private yAxisMinValuesFormGroup(): UntypedFormArray {
    return this.correlationForm.get('yAxisMinValues') as UntypedFormArray;
  }

  private datasourcesFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('datasources') as UntypedFormArray;
  }

  private visualisableFieldsFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('visualisableFields') as UntypedFormArray;
  }
}
