<form [formGroup]="projectForm" (ngSubmit)="updateOrCreateProject()">
  <mat-dialog-content>
    <div>
      <h4 *ngIf="!data.id">Neues Projekt anlegen</h4>
      <h4 *ngIf="data.id">Projekt {{ data.name }} editieren</h4>
      <mat-divider></mat-divider>

      <div>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
          <mat-error *ngIf="projectForm.controls.name.hasError('required')">
            Name des Projektes angeben
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Beschreibung</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Auftraggeber</mat-label>
          <mat-select formControlName="client" required>
            <mat-option
              matInput
              *ngFor="let client of availableClients"
              [value]="client.id"
              >{{ client.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-label>Ausgewählte Benutzer</mat-label>
        <div class="user-selection-group">
          <ng-container formArrayName="users">
            <div *ngFor="let user of availableUsers; let i = index">
              <mat-checkbox [formControlName]="i">
                {{ user.email }}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
        <mat-error
          *ngIf="projectForm.controls.users.hasError('requiresAtLeastOneUser')"
        >
          Mindestens einen Benutzer angeben
        </mat-error>

        <mat-form-field>
          <mat-label>Transformation</mat-label>
          <mat-select formControlName="transformationFrom" required>
            <mat-option
              matInput
              *ngFor="let transformation of availableTransformations"
              [value]="transformation.key"
              >{{ transformation.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div class="mat-form-field">
          <div class="flex flex-row">
            <span class="pr-4"> Projekt aktiv:</span>
            <mat-checkbox formControlName="active"> </mat-checkbox>
          </div>
        </div>

        <!-- <mat-form-field>
          <mat-label>Transformation zu</mat-label>
          <mat-select formControlName="transformationTo" required>
            <mat-option
              matInput
              *ngFor="let transformation of availableTransformations"
              [value]="transformation.key"
              >{{ transformation.value }}</mat-option
            >
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!projectForm.valid"
    >
      <ng-template [ngIf]="!data.id">Projekt anlegen</ng-template>
      <ng-template [ngIf]="data.id">Speichern</ng-template>
    </button>
    <!-- Buttons are disabled to avoid confusion when re-creating projects -->
    <!-- <button mat-raised-button *ngIf="data.id" (click)="deleteProject($event)">
      {{ data.name }} löschen
    </button> -->
    <button mat-raised-button [mat-dialog-close]="true">Schließen</button>
  </mat-dialog-actions>
</form>
