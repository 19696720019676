export const environment = {
  production: true,
  keycloak: {
    issuer: 'https://login2.staging.trigonos.lumoe.io/auth/',
    realm: 'Trigonos',
    clientId: 'alpine-monitoring-app',
  },
  basemapURL: 'https://basemap.at/wmts/1.0.0/WMTSCapabilities.xml',
  apiEndpoint: 'https://api.staging.trigonos.lumoe.io',
  webcamEndpoint: 'https://webcam-trigonos.dyndns.org:5003',
};
