/**
 * Trigonos REST API
 * Trigonos REST API for the Alpine Monitoring Application
 *
 * The version of the OpenAPI document: v1
 * Contact: lukas@moelschl.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DataAvailableDateRange } from '../model/models';
import { DataPoint } from '../model/models';
import { DatapointGNSS } from '../model/models';
import { InfluxOrthoDataPoint } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DataService {

    protected basePath = 'https://api.staging.trigonos.lumoe.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get measurements for a specific field and station-
     * @param field Query Field
     * @param filterStart Datetime Filter Start
     * @param filterEnd Datetime Filter End
     * @param hourFilterStart Filter for hour start
     * @param hourFilterEnd Filter for hour end
     * @param sensors List of sensors to fetch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataCsvExportList(field: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, sensors: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Blob>;
    public dataCsvExportList(field: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, sensors: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Blob>>;
    public dataCsvExportList(field: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, sensors: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Blob>>;
    public dataCsvExportList(field: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, sensors: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (field === null || field === undefined) {
            throw new Error('Required parameter field was null or undefined when calling dataCsvExportList.');
        }
        if (filterStart === null || filterStart === undefined) {
            throw new Error('Required parameter filterStart was null or undefined when calling dataCsvExportList.');
        }
        if (filterEnd === null || filterEnd === undefined) {
            throw new Error('Required parameter filterEnd was null or undefined when calling dataCsvExportList.');
        }
        if (hourFilterStart === null || hourFilterStart === undefined) {
            throw new Error('Required parameter hourFilterStart was null or undefined when calling dataCsvExportList.');
        }
        if (hourFilterEnd === null || hourFilterEnd === undefined) {
            throw new Error('Required parameter hourFilterEnd was null or undefined when calling dataCsvExportList.');
        }
        if (sensors === null || sensors === undefined) {
            throw new Error('Required parameter sensors was null or undefined when calling dataCsvExportList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (field !== undefined && field !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>field, 'field');
        }
        if (filterStart !== undefined && filterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStart, 'filter_start');
        }
        if (filterEnd !== undefined && filterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEnd, 'filter_end');
        }
        if (hourFilterStart !== undefined && hourFilterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hourFilterStart, 'hour_filter_start');
        }
        if (hourFilterEnd !== undefined && hourFilterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hourFilterEnd, 'hour_filter_end');
        }
        if (sensors) {
            queryParameters = this.addToHttpParams(queryParameters,
                sensors.join(COLLECTION_FORMATS['csv']), 'sensors');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/data/csv-export/`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Class used for the correlation module Provides functionallity to filter for date and time periods
     * @param dataset 
     * @param filterStartDate Time in format: yyyy:MM:dd
     * @param filterEndDate Time in format: yyyy:MM:dd
     * @param dataFields Time in format: yyyy:MM:dd
     * @param lowPassFilterDate Time in format: yyyy:MM:dd
     * @param filterStartTime Time in format: %H:%M
     * @param filterEndTime Time in format: %H:%M
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDataRead(dataset: string, filterStartDate: string, filterEndDate: string, dataFields: Array<'dx' | 'dy' | 'dz' | 'dl' | 'dq' | 'd2d' | 'd3d' | 'dy_k' | 'dx_k' | 'dz_k' | 'd2d_k' | 'dl_k' | 'dq_k' | 'd3d_k' | 'vl' | 'vq' | 'vz' | 'x' | 'y' | 'z' | 'mA' | 'mm' | 'dmA' | 'dmm'>, lowPassFilterDate: string, filterStartTime?: string, filterEndTime?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DataPoint>>;
    public dataDataRead(dataset: string, filterStartDate: string, filterEndDate: string, dataFields: Array<'dx' | 'dy' | 'dz' | 'dl' | 'dq' | 'd2d' | 'd3d' | 'dy_k' | 'dx_k' | 'dz_k' | 'd2d_k' | 'dl_k' | 'dq_k' | 'd3d_k' | 'vl' | 'vq' | 'vz' | 'x' | 'y' | 'z' | 'mA' | 'mm' | 'dmA' | 'dmm'>, lowPassFilterDate: string, filterStartTime?: string, filterEndTime?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DataPoint>>>;
    public dataDataRead(dataset: string, filterStartDate: string, filterEndDate: string, dataFields: Array<'dx' | 'dy' | 'dz' | 'dl' | 'dq' | 'd2d' | 'd3d' | 'dy_k' | 'dx_k' | 'dz_k' | 'd2d_k' | 'dl_k' | 'dq_k' | 'd3d_k' | 'vl' | 'vq' | 'vz' | 'x' | 'y' | 'z' | 'mA' | 'mm' | 'dmA' | 'dmm'>, lowPassFilterDate: string, filterStartTime?: string, filterEndTime?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DataPoint>>>;
    public dataDataRead(dataset: string, filterStartDate: string, filterEndDate: string, dataFields: Array<'dx' | 'dy' | 'dz' | 'dl' | 'dq' | 'd2d' | 'd3d' | 'dy_k' | 'dx_k' | 'dz_k' | 'd2d_k' | 'dl_k' | 'dq_k' | 'd3d_k' | 'vl' | 'vq' | 'vz' | 'x' | 'y' | 'z' | 'mA' | 'mm' | 'dmA' | 'dmm'>, lowPassFilterDate: string, filterStartTime?: string, filterEndTime?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataset === null || dataset === undefined) {
            throw new Error('Required parameter dataset was null or undefined when calling dataDataRead.');
        }
        if (filterStartDate === null || filterStartDate === undefined) {
            throw new Error('Required parameter filterStartDate was null or undefined when calling dataDataRead.');
        }
        if (filterEndDate === null || filterEndDate === undefined) {
            throw new Error('Required parameter filterEndDate was null or undefined when calling dataDataRead.');
        }
        if (dataFields === null || dataFields === undefined) {
            throw new Error('Required parameter dataFields was null or undefined when calling dataDataRead.');
        }
        if (lowPassFilterDate === null || lowPassFilterDate === undefined) {
            throw new Error('Required parameter lowPassFilterDate was null or undefined when calling dataDataRead.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterStartTime !== undefined && filterStartTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStartTime, 'filter_start_time');
        }
        if (filterEndTime !== undefined && filterEndTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEndTime, 'filter_end_time');
        }
        if (filterStartDate !== undefined && filterStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStartDate, 'filter_start_date');
        }
        if (filterEndDate !== undefined && filterEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEndDate, 'filter_end_date');
        }
        if (dataFields) {
            queryParameters = this.addToHttpParams(queryParameters,
                dataFields.join(COLLECTION_FORMATS['csv']), 'data_fields');
        }
        if (lowPassFilterDate !== undefined && lowPassFilterDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lowPassFilterDate, 'low_pass_filter_date');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DataPoint>>(`${this.configuration.basePath}/data/data/${encodeURIComponent(String(dataset))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param stationId ID of the measurement station (i.e. _measurement)
     * @param fieldId ID of the _field parameter
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDaterangeInfluxList(stationId: string, fieldId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataAvailableDateRange>;
    public dataDaterangeInfluxList(stationId: string, fieldId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataAvailableDateRange>>;
    public dataDaterangeInfluxList(stationId: string, fieldId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataAvailableDateRange>>;
    public dataDaterangeInfluxList(stationId: string, fieldId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (stationId === null || stationId === undefined) {
            throw new Error('Required parameter stationId was null or undefined when calling dataDaterangeInfluxList.');
        }
        if (fieldId === null || fieldId === undefined) {
            throw new Error('Required parameter fieldId was null or undefined when calling dataDaterangeInfluxList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (stationId !== undefined && stationId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stationId, 'station_id');
        }
        if (fieldId !== undefined && fieldId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fieldId, 'field_id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DataAvailableDateRange>(`${this.configuration.basePath}/data/daterange-influx/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the available daterange for a given dataset
     * @param datasetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDaterangeRead(datasetId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataAvailableDateRange>;
    public dataDaterangeRead(datasetId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataAvailableDateRange>>;
    public dataDaterangeRead(datasetId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataAvailableDateRange>>;
    public dataDaterangeRead(datasetId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (datasetId === null || datasetId === undefined) {
            throw new Error('Required parameter datasetId was null or undefined when calling dataDaterangeRead.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DataAvailableDateRange>(`${this.configuration.basePath}/data/daterange/${encodeURIComponent(String(datasetId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Class used for data for the orthofoto component
     * @param dataset 
     * @param filterStartTime Time in format: %H:%M
     * @param filterEndTime Time in format: %H:%M
     * @param filterDateStart Time in format: yyyy:MM:dd
     * @param filterDateEnd Time in format: yyyy:MM:dd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataGnssRead(dataset: string, filterStartTime?: string, filterEndTime?: string, filterDateStart?: string, filterDateEnd?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DatapointGNSS>;
    public dataGnssRead(dataset: string, filterStartTime?: string, filterEndTime?: string, filterDateStart?: string, filterDateEnd?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DatapointGNSS>>;
    public dataGnssRead(dataset: string, filterStartTime?: string, filterEndTime?: string, filterDateStart?: string, filterDateEnd?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DatapointGNSS>>;
    public dataGnssRead(dataset: string, filterStartTime?: string, filterEndTime?: string, filterDateStart?: string, filterDateEnd?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dataset === null || dataset === undefined) {
            throw new Error('Required parameter dataset was null or undefined when calling dataGnssRead.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (filterStartTime !== undefined && filterStartTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStartTime, 'filter_start_time');
        }
        if (filterEndTime !== undefined && filterEndTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEndTime, 'filter_end_time');
        }
        if (filterDateStart !== undefined && filterDateStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateStart, 'filter_date_start');
        }
        if (filterDateEnd !== undefined && filterDateEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterDateEnd, 'filter_date_end');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DatapointGNSS>(`${this.configuration.basePath}/data/gnss/${encodeURIComponent(String(dataset))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get measurements for a specific field and station-
     * @param station Station ID
     * @param filterStart Datetime Filter Start
     * @param filterEnd Datetime Filter End
     * @param fields List of fields to fetch
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataMeasurementsFieldsList(station: string, filterStart: string, filterEnd: string, fields: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DataPoint>>;
    public dataMeasurementsFieldsList(station: string, filterStart: string, filterEnd: string, fields: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DataPoint>>>;
    public dataMeasurementsFieldsList(station: string, filterStart: string, filterEnd: string, fields: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DataPoint>>>;
    public dataMeasurementsFieldsList(station: string, filterStart: string, filterEnd: string, fields: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (station === null || station === undefined) {
            throw new Error('Required parameter station was null or undefined when calling dataMeasurementsFieldsList.');
        }
        if (filterStart === null || filterStart === undefined) {
            throw new Error('Required parameter filterStart was null or undefined when calling dataMeasurementsFieldsList.');
        }
        if (filterEnd === null || filterEnd === undefined) {
            throw new Error('Required parameter filterEnd was null or undefined when calling dataMeasurementsFieldsList.');
        }
        if (fields === null || fields === undefined) {
            throw new Error('Required parameter fields was null or undefined when calling dataMeasurementsFieldsList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (station !== undefined && station !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>station, 'station');
        }
        if (filterStart !== undefined && filterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStart, 'filter_start');
        }
        if (filterEnd !== undefined && filterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEnd, 'filter_end');
        }
        if (fields) {
            queryParameters = this.addToHttpParams(queryParameters,
                fields.join(COLLECTION_FORMATS['csv']), 'fields');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DataPoint>>(`${this.configuration.basePath}/data/measurements/fields`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get measurements for a specific field and station-
     * @param field Query Field
     * @param station Station ID
     * @param filterStart Datetime Filter Start
     * @param filterEnd Datetime Filter End
     * @param hourFilterStart Filter for hour start
     * @param hourFilterEnd Filter for hour end
     * @param aggregation Perform aggregation to maximum number of points
     * @param rollingMean Rolling mean for the data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataMeasurementsList(field: string, station: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, aggregation: boolean, rollingMean: '30D' | '14D' | '7D' | '1D' | '12H' | '0', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DataPoint>>;
    public dataMeasurementsList(field: string, station: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, aggregation: boolean, rollingMean: '30D' | '14D' | '7D' | '1D' | '12H' | '0', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DataPoint>>>;
    public dataMeasurementsList(field: string, station: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, aggregation: boolean, rollingMean: '30D' | '14D' | '7D' | '1D' | '12H' | '0', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DataPoint>>>;
    public dataMeasurementsList(field: string, station: string, filterStart: string, filterEnd: string, hourFilterStart: number, hourFilterEnd: number, aggregation: boolean, rollingMean: '30D' | '14D' | '7D' | '1D' | '12H' | '0', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (field === null || field === undefined) {
            throw new Error('Required parameter field was null or undefined when calling dataMeasurementsList.');
        }
        if (station === null || station === undefined) {
            throw new Error('Required parameter station was null or undefined when calling dataMeasurementsList.');
        }
        if (filterStart === null || filterStart === undefined) {
            throw new Error('Required parameter filterStart was null or undefined when calling dataMeasurementsList.');
        }
        if (filterEnd === null || filterEnd === undefined) {
            throw new Error('Required parameter filterEnd was null or undefined when calling dataMeasurementsList.');
        }
        if (hourFilterStart === null || hourFilterStart === undefined) {
            throw new Error('Required parameter hourFilterStart was null or undefined when calling dataMeasurementsList.');
        }
        if (hourFilterEnd === null || hourFilterEnd === undefined) {
            throw new Error('Required parameter hourFilterEnd was null or undefined when calling dataMeasurementsList.');
        }
        if (aggregation === null || aggregation === undefined) {
            throw new Error('Required parameter aggregation was null or undefined when calling dataMeasurementsList.');
        }
        if (rollingMean === null || rollingMean === undefined) {
            throw new Error('Required parameter rollingMean was null or undefined when calling dataMeasurementsList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (field !== undefined && field !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>field, 'field');
        }
        if (station !== undefined && station !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>station, 'station');
        }
        if (filterStart !== undefined && filterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStart, 'filter_start');
        }
        if (filterEnd !== undefined && filterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEnd, 'filter_end');
        }
        if (hourFilterStart !== undefined && hourFilterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hourFilterStart, 'hour_filter_start');
        }
        if (hourFilterEnd !== undefined && hourFilterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hourFilterEnd, 'hour_filter_end');
        }
        if (aggregation !== undefined && aggregation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>aggregation, 'aggregation');
        }
        if (rollingMean !== undefined && rollingMean !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rollingMean, 'rolling_mean');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DataPoint>>(`${this.configuration.basePath}/data/measurements`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get measurements for a specific field and station-
     * @param stations List of stations to fetch
     * @param filterStart Datetime Filter Start
     * @param filterEnd Datetime Filter End
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrthophotoList(stations: Array<string>, filterStart: string, filterEnd: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<InfluxOrthoDataPoint>>;
    public dataOrthophotoList(stations: Array<string>, filterStart: string, filterEnd: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<InfluxOrthoDataPoint>>>;
    public dataOrthophotoList(stations: Array<string>, filterStart: string, filterEnd: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<InfluxOrthoDataPoint>>>;
    public dataOrthophotoList(stations: Array<string>, filterStart: string, filterEnd: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (stations === null || stations === undefined) {
            throw new Error('Required parameter stations was null or undefined when calling dataOrthophotoList.');
        }
        if (filterStart === null || filterStart === undefined) {
            throw new Error('Required parameter filterStart was null or undefined when calling dataOrthophotoList.');
        }
        if (filterEnd === null || filterEnd === undefined) {
            throw new Error('Required parameter filterEnd was null or undefined when calling dataOrthophotoList.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (stations) {
            queryParameters = this.addToHttpParams(queryParameters,
                stations.join(COLLECTION_FORMATS['csv']), 'stations');
        }
        if (filterStart !== undefined && filterStart !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterStart, 'filter_start');
        }
        if (filterEnd !== undefined && filterEnd !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filterEnd, 'filter_end');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (AlpineMonitoring_REST) required
        credential = this.configuration.lookupCredential('AlpineMonitoring_REST');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<InfluxOrthoDataPoint>>(`${this.configuration.basePath}/data/orthophoto/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
