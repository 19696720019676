<!-- <ul *ngIf="reportItems">
  <orthofoto-module
    *ngFor="let reportItem of reportItems"
    [config]="reportItem"
  ></orthofoto-module>
</ul>
 -->
<div class="report-container" *ngIf="reportItems && reportItems.length !== 0">
  <report-module-loader
    class="report-element"
    *ngFor="let reportItem of reportItems"
    [reportModule]="reportItem"
  ></report-module-loader>
</div>

<div class="justify-content-center d-flex" *ngIf="userService.userIsAdmin">
  <div class="add-report-button d-flex flex-row align-items-center">
    <div class="p-2 button-text">Element hinzufügen</div>
    <button
      class="ml-auto"
      mat-fab
      color="primary"
      aria-label="Element hinzufügen"
      (click)="addComponent($event)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
