<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ config.headline }}
    </mat-card-title>
    <mat-card-subtitle>
      <div *ngIf="config.filter.enabled" class="data-filtered-message">
        <mat-icon>warning</mat-icon> Tagesdaten sind gefiltert
      </div>
    </mat-card-subtitle>
    <div class="ml-auto">
      <button mat-button [matMenuTriggerFor]="correlationContextMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #correlationContextMenu="matMenu">
        <button mat-menu-item (click)="exportCurrentView($event)">
          Ansicht Exportieren <mat-icon>file_download</mat-icon>
        </button>
      </mat-menu>
    </div>
  </mat-card-header>

  <mat-card-content class="relative">
    <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium">
    </ngx-spinner>
    <div class="flex flex-row" [ngStyle]="{ width: chartWidth }">
      <canvas #chart id="chart" baseChart [datasets]="chartData" [labels]="chartLabels" [type]="'scatter'"
        [plugins]="[]" [options]="chartOptions" (window:resize)="onChartResize($event)">
      </canvas>
      <div class="absolute w-5 right-0 lg:right-20 -top-7" #legendDiv>
        <div style="margin-left: -16px">
          {{ getMinDate() | date : "shortDate" }}
        </div>
        <canvas class="" style="width: 100%" #legendCanvas [ngStyle]="{ height: legendDivHeight }"></canvas>
        <div style="margin-left: -16px">
          {{ getMaxDate() | date : "shortDate" }}
        </div>
      </div>
    </div>

    <div class="filter-group">
      <form [formGroup]="settingsForm">
        <!-- Date Range Options -->
        <mat-form-field appearance="fill">
          <mat-label>Darstellungszeitraum</mat-label>
          <mat-select (selectionChange)="dateRangeSelectionChanged($event)" [formControl]="preDefinedDatePeriod"
            name="preDefinedDateField">
            <mat-option matInput *ngFor="let timePeriod of selectableTimePeriods" [value]="timePeriod.value">{{
              timePeriod.viewValue }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Selected Datasource -->
        <mat-form-field appearance="fill">
          <mat-label>Datenquelle</mat-label>
          <mat-select (selectionChange)="selectionChanged($event)" [formControl]="selectedFieldControl"
            name="selectedField">
            <mat-option matInput *ngFor="let field of availableSensors | keyvalue" [value]="field.value.influx_name">{{
              field.value.display_name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Date Range Filter -->
        <mat-form-field [color]="'accent'" appearance="fill" *ngIf="customDateRange">
          <mat-label>Zeitraum filtern</mat-label>
          <mat-date-range-input [formGroup]="settingsForm" [rangePicker]="datepicker" [min]="getMinDate()"
            [max]="getMaxDate()">
            <input matStartDate type="text" matInput formControlName="filterDateStart" placeholder="dd.mm.yyyy"
              (dateChange)="customDateFilterChanged($event)" />
            <input matEndDate type="text" matInput formControlName="filterDateEnd" placeholder="dd.mm.yyyy"
              (dateChange)="customDateFilterChanged($event)" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-date-range-picker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Schließen</button>
              <button mat-raised-button color="primary" matDatepickerApply>
                Auswählen
              </button>
            </mat-datepicker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button mat-button (click)="reportModuleService.openEditReportModuleForm(config.guid)">
      BEARBEITEN
    </button>
    <button (click)="reportModuleService.removeModuleFormReport(config.guid)" mat-button>
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
