import { DataService } from 'src/app/api/generated';
import { Injectable } from '@angular/core';
import { OrthofotoConfig } from './orthophoto.tools';

export interface DataPoint {
  epoch: Date;
  x: number;
  y: number;
  z: number;
  dx: number;
  dy: number;
  dz: number;
  lengthdXdY: number;
  d2d: number;
  d3d: number;
}

@Injectable({
  providedIn: 'root',
})
export class OrthofotoService {
  constructor(private readonly dataService: DataService) {}

  public async getDataForDatset(
    datasetName: string,
    config: OrthofotoConfig,
    dateStart: string,
    dateEnd: string
  ): Promise<DataPoint> {
    let apiData = undefined;
    // Daytime filter enabled
    if (config.filter.enabled) {
      apiData = await this.dataService
        .dataGnssRead(
          datasetName,
          config.filter.from,
          config.filter.till,
          dateStart,
          dateEnd
        )
        .toPromise()
        .catch((reason) => {
          console.log(reason);
        });
    } else {
      apiData = await this.dataService
        .dataGnssRead(datasetName, undefined, undefined, dateStart, dateEnd)
        .toPromise()
        .catch((reason) => {
          console.log(reason);
        });
    }

    return {
      epoch: new Date(apiData.epoch),
      x: Number(apiData.x),
      y: Number(apiData.y),
      z: Number(apiData.z),
      dx: Number(apiData.dx),
      dy: Number(apiData.dy),
      dz: Number(apiData.dz),
      lengthdXdY: Math.sqrt(
        Math.pow(Number(apiData.dx), 2) + Math.pow(Number(apiData.dy), 2)
      ),
      d2d: Number(apiData.d2d),
      d3d: Number(apiData.d3d),
    };
  }
}
