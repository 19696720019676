import { Plugin } from 'chart.js';
import { DataPoint } from 'src/app/api/generated';

export interface Filter {
  enabled: boolean;
  from: string;
  till: string;
}

export interface UserSettings {
  start_date: string;
  end_date: string;
}

export interface MovementScatterplotConfig {
  guid: string;
  type: string;
  headline: string;
  dataset: string[];
  filter: Filter;
  user_settings: UserSettings;
  bg_image_resource_url: string;
}

export const ChartJSImagePlugin = (imageSource) => {
  const image = new Image();
  image.src = imageSource;
  return <Plugin>{
    id: 'lumoe_image_viewer_plugin',
    beforeDraw: (chart, args, options) => {
      if (image.complete) {
        const ctx = chart.ctx;
        const { top, left, width, height } = chart.chartArea;
        const newDimensions = getImageDimensions(image, chart.height - 70);
        image.width = newDimensions.width;
        image.height = newDimensions.height;
        const x = left + width / 2 - image.width / 2;
        const y = top + height / 2 - image.height / 2;
        ctx.drawImage(image, x, y, newDimensions.width, newDimensions.height);
      } else {
        image.onload = () => chart.draw();
      }
    },
  };
};

function getImageDimensions(image: HTMLImageElement, maxHeight: number) {
  const ratio = maxHeight / image.height;
  return {
    width: image.width * ratio,
    height: image.height * ratio,
  };
}

export interface MinMaxValue {
  id: string;
  min: number;
  max: number;
}

export interface DataPointGnss {
  epoch: Date;
  dx?: number | null;
  dy?: number | null;
  dz?: number | null;
  dl?: number | null;
  dq?: number | null;
  d2d?: number | null;
  d3d?: number | null;
}

export function parseGNSSData(data: DataPoint[]): DataPointGnss[] {
  return data.map((value) => {
    // console.log(new Date(Date.parse(value.epoch)).getTimezoneOffset());
    return {
      epoch: new Date(value.epoch),
      dx: Number(value.dx),
      dy: Number(value.dy),
      dz: Number(value.dz),
      dl: Number(value.dl),
      dq: Number(value.dq),
      d2d: Number(value.d2d),
      d3d: Number(value.d3d),

      dy_k: Number(value.dy_k),
      dx_k: Number(value.dx_k),
      dz_k: Number(value.dz_k),
      d2d_k: Number(value.d2d_k),
      dl_k: Number(value.dl_k),
      dq_k: Number(value.dq_k),
      d3d_k: Number(value.d3d_k),
      vl: Number(value.vl),
      vq: Number(value.vq),
      vz: Number(value.vz),
    };
  });
}

const oneDay = 1000 * 60 * 60 * 24;
const oneHour = 1000 * 60 * 60;
export const selectableTimePeriods = [
  { viewValue: 'letzte 24 Stunden', value: new Date(Date.now() - oneDay) },
  { viewValue: 'letzte Woche', value: new Date(Date.now() - oneDay * 7) },
  { viewValue: 'letzte 2 Wochen', value: new Date(Date.now() - oneDay * 14) },
  {
    viewValue: 'letztes Monat',
    value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
  {
    viewValue: 'letztes Jahr',
    value: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  },
  {
    viewValue: 'Von Nullmessung',
    value: new Date(0),
  },
  {
    viewValue: 'Zeitbereich auswählen',
    value: -1,
  },
];

export const selectableLowPassFilterPeriods = [
  { viewValue: 'Keine Glättung', value: new Date(0) },
  { viewValue: '12 Stunden', value: new Date(Date.now() - oneHour * 12) },
  { viewValue: '1 Tag', value: new Date(Date.now() - oneDay) },
  { viewValue: '1 Woche', value: new Date(Date.now() - oneDay * 7) },
  { viewValue: '2 Wochen', value: new Date(Date.now() - oneDay * 14) },
  {
    viewValue: '1 Monat',
    value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
];

export function getMinMaxFromConfig(
  fieldName: string,
  config: MinMaxValue[]
): MinMaxValue {
  if (!config) {
    config = [];
  }
  let minMaxArr = config.filter((field) => {
    if (field.id == fieldName) {
      return true;
    }
  });

  if (minMaxArr.length === 0) {
    return <MinMaxValue>{
      id: fieldName,
      min: 0,
      max: 0,
    };
  }

  return minMaxArr[0];
}

export function getParameterDisplayName(parameter: string): string {
  return {
    dl: 'Längs',
    dq: 'Quer',
    dz: 'Höhe',
    d2d: '2D-Verschiebung',
    d3d: '3D-Verschiebung',

    d2d_k: '2D-Verschiebung Kalman gefiltert',
    d3d_k: '3D-Verschiebung Kalman gefiltert',
    dl_k: 'Verschiebung Längs Kalman gefiltert',
    dq_k: 'Verschiebung Quer Kalman gefiltert',
    dz_k: 'Verschiebung Höhe Kalman gefiltert',
    vl: 'Geschwindigkeit Längs',
    vq: 'Geschwindigkeit Quer',
    vz: 'Geschwindigkeit Höhe',
  }[parameter];
}

export const CHART_COLORS = [
  'rgb(255, 99, 132)', //  red
  'rgb(54, 162, 235)', //  blue
  'rgb(255, 159, 64)', //  orange
  'rgb(75, 192, 192)', //  green
  'rgb(153, 102, 255)', // purple
  'rgb(255, 205, 86)', //  yellow
  'rgb(201, 203, 207)', //  grey
];

export const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, 'legend-container');

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export function round(value: number | string, precision: number) {
  let parsedValue: number;
  if (typeof value === 'string') {
    parsedValue = parseFloat(value);
  } else {
    parsedValue = value;
  }
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(parsedValue * multiplier) / multiplier;
}
