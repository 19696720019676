import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ManagementService, TrigonosDatasource } from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import {
  InverseVelocityConfig,
  getMinMaxFromConfig,
  MinMaxValue,
} from '../../inverse_velocity.tools';

import * as uuid from 'uuid';
import { validTime } from 'src/app/tools/validators';

export interface VisualisableField {
  id: string;
  label: string;
  unit: string;
}

@Component({
  selector: 'app-inverse_velocity-config',
  templateUrl: './inverse_velocity-config.component.html',
  styleUrls: ['./inverse_velocity-config.component.scss'],
})
export class InverseVelocityConfigComponent implements OnInit {
  public correlationForm: UntypedFormGroup;
  public availableDataSources: TrigonosDatasource[] = [];
  public availableFields: VisualisableField[] = [
    { id: 'vq', label: 'Inverse Geschwindigkeit Längs', unit: 'Tage / mm' },
    { id: 'vl', label: 'Inverse Geschwindigkeit Quer', unit: 'Tage / mm' },
    { id: 'vz', label: 'Inverse Geschwindigkeit Höhe', unit: 'Tage / mm' },
  ];
  public editMode = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InverseVelocityConfig,
    private dialogRef: MatDialogRef<InverseVelocityConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    this.correlationForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data?.guid : uuid.v4()],
      type: ['inverse_velocity'],
      headline: [
        this.data?.headline ? this.data?.headline : '',
        Validators.required,
      ],
      labelXAxis: [
        this.data?.x_label ? this.data?.x_label : '',
        Validators.required,
      ],
      labelYAxis: [
        this.data?.y_label ? this.data?.y_label : '',
        Validators.required,
      ],
      datasources: this.formBuilder.array([]),
      visualisableFields: this.formBuilder.array([]),
      timePeriodFiltered:
        this.data?.filter.enabled === undefined
          ? true
          : this.data?.filter.enabled,
      timePeriodFilteredFrom: [
        this.data?.filter.from ? this.data?.filter.from : '22:00',
        [Validators.required, validTime()],
      ],
      timePeriodFilteredTill: [
        this.data?.filter.till ? this.data?.filter.till : '22:00',
        [Validators.required, validTime()],
      ],
      yAxisMinValues: this.formBuilder.array([]),
      relativeZero: [
        this.data?.relative_zero === undefined
          ? false
          : this.data.relative_zero,
      ],
    });

    this.availableFields.forEach((field) => {
      // Add min max fields to form
      let minMax: MinMaxValue = {
        id: field.id,
        min: 0,
        max: 0,
      };

      if (this.data?.guid) {
        minMax = getMinMaxFromConfig(field.id, this.data.min_max);
      }

      this.yAxisMinValuesFormGroup().push(
        new UntypedFormGroup({
          id: new UntypedFormControl(field.id),
          min: new UntypedFormControl(minMax.min, [Validators.required]),
          max: new UntypedFormControl(minMax.max, [Validators.required]),
        })
      );

      // Checkboxes for datasources
      if (!this.data?.guid) {
        this.visualisableFieldsFromFormGroup().push(new UntypedFormControl(false));
      } else {
        const enabled =
          this.data.selected_fields.indexOf(field.id as any) !== -1;
        this.visualisableFieldsFromFormGroup().push(new UntypedFormControl(enabled));
      }
    });

    this.managementService
      .managementDatasourceReportRead(this.reportModuleService.currentReport.id)
      .subscribe((datasources) => {
        this.availableDataSources = datasources;
        // New module is created
        if (!this.data?.guid) {
          this.availableDataSources.forEach((datasource) => {
            this.datasourcesFromFormGroup().push(new UntypedFormControl(false));
          });
        } else {
          this.availableDataSources.forEach((datasource) => {
            const enabled = this.data.dataset.indexOf(datasource.id) !== -1;
            this.datasourcesFromFormGroup().push(new UntypedFormControl(enabled));
          });
        }
      });
  }

  public updateOrCreateModule() {
    if (!this.correlationForm.valid) {
      return;
    }

    let datasets = this.correlationForm.value.datasources
      .map((enabled, index) => {
        if (enabled) {
          return this.availableDataSources[index].id;
        }
      })
      .filter(Boolean);

    let fields = this.correlationForm.value.visualisableFields
      .map((enabled, index) => {
        if (enabled) {
          return this.availableFields[index].id;
        }
      })
      .filter(Boolean);

    let minMaxValues = this.correlationForm.value.yAxisMinValues.map(
      (group, index) => group
    );

    this.reportModuleService
      .addOrEditModuleToReport(
        <InverseVelocityConfig>{
          guid: this.correlationForm.value.guid,
          type: this.correlationForm.value.type,
          headline: this.correlationForm.value.headline,
          x_label: this.correlationForm.value.labelXAxis,
          y_label: this.correlationForm.value.labelYAxis,
          filter: {
            enabled: this.correlationForm.value.timePeriodFiltered,
            from: this.correlationForm.value.timePeriodFilteredFrom,
            till: this.correlationForm.value.timePeriodFilteredTill,
          },
          user_settings: {
            start_date: null,
            end_date: null,
          },
          dataset: datasets,
          selected_fields: fields,
          min_max: minMaxValues,
          relative_zero: this.correlationForm.value.relativeZero,
        },
        this.editMode
      )
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.dataChangedService.configUpdated(
            this.reportModuleService.currentReport.id,
            this.correlationForm.value.guid
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  get timeFilterDisabled(): string | null {
    return this.correlationForm.get('timePeriodFiltered').value ? null : '';
  }

  private yAxisMinValuesFormGroup(): UntypedFormArray {
    return this.correlationForm.get('yAxisMinValues') as UntypedFormArray;
  }

  private datasourcesFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('datasources') as UntypedFormArray;
  }

  private visualisableFieldsFromFormGroup(): UntypedFormArray {
    return this.correlationForm.get('visualisableFields') as UntypedFormArray;
  }
}
