<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ config.headline }}
    </mat-card-title>
    <mat-card-subtitle>
      <div *ngIf="config.filter.enabled" class="data-filtered-message">
        <mat-icon>warning</mat-icon> Tagesdaten sind gefiltert
      </div>
    </mat-card-subtitle>
    <div class="ml-auto">
      <button mat-button [matMenuTriggerFor]="correlationContextMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #correlationContextMenu="matMenu">
        <button mat-menu-item (click)="exportCurrentView($event)">
          Ansicht Exportieren <mat-icon>file_download</mat-icon>
        </button>
        <!-- <button mat-menu-item (click)="exportDatasets($event)">
          Datensatz Exportieren <mat-icon>file_download</mat-icon>
        </button> -->
      </mat-menu>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div [ngStyle]="{ width: chartWidth }">
      <canvas
        #chart
        id="chart"
        baseChart
        [datasets]="chartData"
        [labels]="chartLabels"
        [type]="'line'"
        [plugins]="[]"
        [options]="chartOptions"
      >
      </canvas>
    </div>

    <div class="filter-group">
      <form [formGroup]="correlationForm">
        <mat-form-field appearance="fill">
          <mat-label>Parameter</mat-label>
          <mat-select
            (selectionChange)="selectionChanged($event)"
            [formControl]="selectedFieldControl"
            name="selectedField"
          >
            <mat-option
              matInput
              *ngFor="let field of config.selected_fields"
              [value]="field"
              >{{ getParameterDisplayName(field) }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!-- Date Range Options -->
        <mat-form-field appearance="fill">
          <mat-label>Darstellungszeitraum</mat-label>
          <mat-select
            (selectionChange)="dateRangeSelectionChanged($event)"
            [formControl]="preDefinedDatePeriod"
            name="preDefinedDateField"
          >
            <mat-option
              matInput
              *ngFor="let timePeriod of selectableTimePeriods"
              [value]="timePeriod.value"
              >{{ timePeriod.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <!-- Date Range Filter -->
        <mat-form-field
          [color]="'accent'"
          appearance="fill"
          *ngIf="customDateRange"
        >
          <mat-label>Zeitraum filtern</mat-label>
          <mat-date-range-input
            [formGroup]="correlationForm"
            [rangePicker]="datepicker"
            [min]="minDate"
            [max]="maxDate"
          >
            <input
              matStartDate
              type="text"
              matInput
              formControlName="filterDateStart"
              placeholder="dd.mm.yyyy"
              (dateChange)="customDateFilterChanged($event)"
            />
            <input
              matEndDate
              type="text"
              matInput
              formControlName="filterDateEnd"
              placeholder="dd.mm.yyyy"
              (dateChange)="customDateFilterChanged($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Schließen</button>
              <button mat-raised-button color="primary" matDatepickerApply>
                Auswählen
              </button>
            </mat-datepicker-actions>
          </mat-date-range-picker>

          <!-- <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('required')
              "
            >
              Messzeitpunkt wird benötigt
            </mat-error>
            <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('pattern')
              "
            >
              Bitte im Format [dd.mm.yyyy] eingeben
            </mat-error> -->
        </mat-form-field>

        <!-- Low Pass Filter Options -->
        <mat-form-field appearance="fill">
          <mat-label>Glättungszeitraum</mat-label>
          <mat-select
            (selectionChange)="selectionChanged($event)"
            [formControl]="preDefinedLowPassFilterPeriods"
            name="preDefinedLowPassFilter"
          >
            <mat-option
              matInput
              *ngFor="let lowPassFilterOption of selectableLowPassFilterPeriods"
              [value]="lowPassFilterOption.value"
              >{{ lowPassFilterOption.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle
          *ngIf="config.relative_zero"
          (change)="relativeZeroChanged($event)"
        >
          Relative Verformung
        </mat-slide-toggle>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button
      mat-button
      (click)="reportModuleService.openEditReportModuleForm(config.guid)"
    >
      BEARBEITEN
    </button>
    <button
      (click)="reportModuleService.removeModuleFormReport(config.guid)"
      mat-button
    >
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
