import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ManagementService, User } from 'src/app/api/generated';
import {
  DataChangedService,
  HEADLINE_TYPE,
} from 'src/app/data-changed.service';
import { isUserAdmin } from '../tools';
import { UserConfigDialogComponent } from '../user-config-dialog/user-config-dialog.component';

interface UserTableOverview {
  position: string;
  email: string;
  first_name: string;
  last_name: string;
  admin: string;
  id: string;
  roles: object;
}

let TABLE_DATA: UserTableOverview[] = [];
@Component({
  selector: 'app-user-config',
  templateUrl: './user-config.component.html',
  styleUrls: ['./user-config.component.scss'],
})
export class UserConfigComponent implements OnInit, AfterViewInit {
  availableUsers = new MatTableDataSource(TABLE_DATA);
  displayedColumns: string[] = ['email', 'first_name', 'last_name', 'admin'];
  isUserAdmin = isUserAdmin;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private managementService: ManagementService,
    private matDialog: MatDialog,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.dataChangedService.setHeadline({
      headline: 'Benutzer Einstellungen',
      image: null,
      type: HEADLINE_TYPE.GENERIC,
    });
    this.initUserList();
    this.dataChangedService.getData().subscribe((message) => {
      if (message.detail === 'user') {
        this.initUserList();
      }
    });
  }

  ngAfterViewInit(): void {
    this.availableUsers.sort = this.sort;
  }

  initUserList(): void {
    this.managementService.managementUsersListList().subscribe((users) => {
      let userListTmp: UserTableOverview[] = [];
      for (let id in users) {
        userListTmp.push({
          position: id,
          email: users[id].email,
          first_name: users[id].first_name,
          last_name: users[id].last_name,
          admin: isUserAdmin(users[id]) ? 'Ja' : 'Nein',
          id: users[id].id,
          roles: users[id].roles,
        });
      }

      this.availableUsers.data = userListTmp;
    });
  }

  userClicked(user) {
    this.matDialog.open(UserConfigDialogComponent, {
      data: user,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.availableUsers.filter = filterValue.trim().toLowerCase();
  }
}
