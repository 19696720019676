<h2 *ngIf="!editMode" mat-dialog-title>Modul Webcam / Bild hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>Modul Webcam / Bild editieren</h2>
<mat-dialog-content>
  <div>
    <form
      [formGroup]="imageViewerForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-tab-group
        (selectedTabChange)="tabChanged($event)"
        dynamicHeight
        mat-align-tabs="center"
      >
        <mat-tab label="Webcam">
          <div class="d-flex flex-column">
            <mat-form-field>
              <mat-label>Webcam URL</mat-label>
              <input
                matInput
                formControlName="webcamURL"
                required
                placeholder="https://"
              />
              <mat-error
                *ngIf="imageViewerForm.controls.webcamURL.hasError('required')"
              >
                URL angeben
              </mat-error>
              <mat-error
                *ngIf="
                  imageViewerForm.controls.webcamURL.hasError('invalidUrl')
                "
              >
                Falsches URL Format
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Aktualisiere alle </mat-label>
              <input
                type="number"
                matInput
                formControlName="webcamRefreshRate"
                required
              />
              <span matSuffix>Minuten</span>
              <mat-error
                *ngIf="
                  imageViewerForm.controls.webcamRefreshRate.hasError(
                    'required'
                  )
                "
              >
                Aktualisierungszeit angeben
              </mat-error>
            </mat-form-field>
          </div>
        </mat-tab>
        <mat-tab label="Upload">
          <div style="display: none">
            <mat-label>Bild</mat-label>
            <input
              type="file"
              (change)="onFileChange($event)"
              name="Bild"
              accept="image/*"
              #file
            />
          </div>
          <mat-lab></mat-lab>
          <div
            class="image-preview-wrapper d-flex justify-content-center"
            (click)="file.click()"
          >
            <div class="image-preview">
              <ng-template [ngIf]="imagePreviewURL">
                <img #image [src]="imagePreviewURL" />
              </ng-template>
              <ng-template [ngIf]="!imagePreviewURL">
                <div class="image-placeholder">
                  <mat-icon>file_upload</mat-icon>
                  Bild auswählen
                </div>
              </ng-template>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Bildbeschreibung</mat-label>
        <input matInput formControlName="imageCaption" />
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!imageViewerForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
