import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { ManagementService, Report, ReportsService } from '../api/generated';
import { DataChangedService, HEADLINE_TYPE } from '../data-changed.service';
import { AddSelectDialogComponent } from '../dialogs/add-select-dialog/add-select-dialog.component';
import { ReportModule } from '../report-module-loader/report-module';
import { ReportModulesService } from '../services/report-modules/report-modules.service';
import { UserService } from '../user.service';

@Component({
  selector: 'report-container',
  templateUrl: './report-container.component.html',
  styleUrls: ['./report-container.component.scss'],
})
export class ReportContainerComponent implements OnInit {
  public reportItems: Array<ReportModule> = new Array();
  private currentReport: Report;

  constructor(
    private readonly reportService: ReportsService,
    private readonly reportModulesService: ReportModulesService,
    private readonly managementService: ManagementService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly dataChangeService: DataChangedService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.loadReport(params.reportID);
      this.dataChangeService.setHeadline({
        headline: params.reportID,
        image: null,
        type: HEADLINE_TYPE.REPORT,
      });
    });

    this.dataChangeService.configChangedObservable().subscribe((changes) => {
      console.log(this.reportModulesService.currentReport);
      this.generateComponents(this.reportModulesService.currentReport);
    });
  }

  private generateComponents(reportConfig: any) {
    // Keep reference
    while (this.reportItems.length !== 0) {
      this.reportItems.splice(0);
    }
    reportConfig.config.forEach((reportModule) => {
      this.reportItems.push(
        new ReportModule(
          this.reportModulesService.getModule(reportModule.type),
          reportModule
        )
      );
    });
  }

  private async loadReport(reportID: string) {
    this.reportService.reportsRead(reportID).subscribe(async (report) => {
      this.generateComponents(report);
      this.reportModulesService.currentReport = report;
      const reportDetails = await this.managementService
        .managementProjectsRead(report.project)
        .toPromise();

      this.reportModulesService.currentTransformationFrom =
        reportDetails.transformation_from;
      this.reportModulesService.currentTransformationTo =
        reportDetails.transformation_to;
    });
  }

  public addComponent(event) {
    this.dialog.open(AddSelectDialogComponent);
  }
}
