import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ManagementService } from 'src/app/api/generated';
import { DataChangedService } from 'src/app/data-changed.service';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { MarkdownModuleConfig } from '../../component/markdown-module.component';

import * as uuid from 'uuid';
import { MdEditorOption } from 'ngx-markdown-editor';

@Component({
  selector: 'app-markdown-module-config',
  templateUrl: './markdown-module-config.component.html',
  styleUrls: ['./markdown-module-config.component.scss'],
})
export class MarkdownModuleConfigComponent implements OnInit {
  public markdownForm: UntypedFormGroup;
  public editMode = false;

  public editorOptions: MdEditorOption = {
    showPreviewPanel: true,
    enablePreviewContentClick: true,
    resizable: false,
    showBorder: false,
    markedjsOpt: {
      sanitize: true,
    },
    hideIcons: ['Image', 'TogglePreview', 'FullScreen'],
  };
  public content: string;
  public mode: string = 'editor';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MarkdownModuleConfig,
    private dialogRef: MatDialogRef<MarkdownModuleConfig>,
    private formBuilder: UntypedFormBuilder,
    private reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.guid !== undefined;
    if (this.editMode) {
      this.content = this.data?.content;
    }
    this.markdownForm = this.formBuilder.group({
      guid: [this.data?.guid ? this.data?.guid : uuid.v4()],
      type: ['markdown'],
    });
  }

  public updateOrCreateModule() {
    if (!this.markdownForm.valid) {
      //@ToDo show toast message or display error in form
      return;
    }
    this.reportModuleService
      .addOrEditModuleToReport(
        <MarkdownModuleConfig>{
          guid: this.markdownForm.value.guid,
          type: this.markdownForm.value.type,
          headline: '',
          content: this.content,
        },
        this.editMode
      )
      .subscribe(
        (success) => {
          this.dialogRef.close();
          this.dataChangedService.configUpdated(
            this.reportModuleService.currentReport.id,
            this.markdownForm.value.guid
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
