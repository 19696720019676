import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Router, RoutesRecognized } from '@angular/router';
import { Clients, Projects } from '../api/generated';
import { ManagementService } from '../api/generated/api/management.service';
import { DataChangedService } from '../data-changed.service';
import { ClientDialogComponent } from '../dialogs/client-dialog/client-dialog.component';
import { ProjectDialogComponent } from '../dialogs/project-dialog/project-dialog.component';
import { ReportDialogComponent } from '../dialogs/report-dialog/report-dialog.component';
import { ReportModulesService } from '../services/report-modules/report-modules.service';
import { UserService } from '../user.service';
import { ExampleFlatNode, SidenavElement, TreeNode } from './side-navbar.tools';

@Component({
  selector: 'side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit {
  public activeRouteID: string = '';
  private currentData: Clients[];
  public _SidenavElement = SidenavElement;

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
      type: node.type,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  constructor(
    private managementService: ManagementService,
    private router: Router,
    private dialog: MatDialog,
    private dataChangedService: DataChangedService,
    public userService: UserService,
    private reportModuleService: ReportModulesService
  ) {}

  ngOnInit(): void {
    this.getProjectsFromApiAndBuildSidenav();
    // Subscribe to Router changes
    this.router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        if (event.state.root.firstChild.params.reportID) {
          this.activeRouteID = event.state.root.firstChild.params.reportID;
        }
      }
    });
    // Subscribe to changes from the data model
    this.dataChangedService.getData().subscribe((value) => {
      this.getProjectsFromApiAndBuildSidenav();
    });
  }

  /**
   * Retrieves projects from the API and creates the Tree structure for the sidenav
   */
  private async getProjectsFromApiAndBuildSidenav() {
    this.managementService
      .managementClientsWithProjectsList()
      .subscribe((res) => {
        this.currentData = res;
        this.dataChangedService.currentClients = res;
        let nodes: TreeNode[] = [];
        for (let client of res) {
          let tmp = {
            name: client.name,
            children: [],
            id: client.id,
            type: SidenavElement.CLIENT,
          };
          for (let projectID in client.projects) {
            tmp.children.push({
              name: client.projects[projectID].name,
              children: [],
              id: client.projects[projectID].id,
              type: SidenavElement.PROJECT,
            });
            for (let reportID in client.projects[projectID].reports) {
              tmp.children[projectID].children[reportID] = {
                name: client.projects[projectID].reports[reportID].name,
                children: [],
                id: client.projects[projectID].reports[reportID].id,
                type: SidenavElement.REPORT,
              };
            }
            tmp.children[projectID].children[9999] = {
              name: 'Hinzufügen',
              children: [],
              id: client.projects[projectID].id,
              type: SidenavElement.ADD_REPORT_BUTTON,
            };
          }
          nodes.push(tmp);
        }

        // Sort the nodes alphabetically
        nodes = nodes.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // Filter out nodes with lenght of children == 0
        nodes = nodes.filter((node) => node.children.length > 0);

        this.dataSource.data = nodes;
        this.expandSidenav(this.activeRouteID);
      });
  }

  /**
   * Opens an dialog with the ProjectDialogComponent to edit the clicked report
   * @param projectID
   */
  public editProject(projectID: string, event: MouseEvent) {
    let filteredProjects: Projects[] = [];
    this.currentData.forEach((client) => {
      client.projects
        .filter((project) => project.id == projectID)
        .forEach((filteredProject) => filteredProjects.push(filteredProject));
    });
    this.dialog.open(ProjectDialogComponent, {
      data: filteredProjects[0],
    });

    event.preventDefault();
    event.stopImmediatePropagation();
  }

  public editClient(clientID: string, event: MouseEvent) {
    const client = this.currentData.filter((client) => client.id === clientID);
    this.dialog.open(ClientDialogComponent, {
      data: client[0],
    });
    event.stopImmediatePropagation();
  }

  /**
   * Opens an dialog with the ReportDialogComponent and passes the
   * project id to the component so that the Form can be prefilled
   * @param projectID
   */
  public addReport(projectID: string, event: MouseEvent, mode = 'add') {
    if (mode === 'edit') {
      const [client, project, report] =
        this.dataChangedService.getProjectAndClientForReport(projectID);

      if ([client, project, report].some((e) => e === null)) {
        console.error('Null values in clientsWithProjects');
        return;
      }

      projectID = project.id;
    }
    // @ToDo duplicated code section
    // Find project according to project ID
    let filteredProjects = [];
    this.currentData.forEach((client) => {
      client.projects
        .filter((project) => project.id == projectID)
        .forEach((filteredProject) => filteredProjects.push(filteredProject));
    });
    this.dialog.open(ReportDialogComponent, {
      data: {
        mode: mode,
        data: filteredProjects[0],
      },
    });

    event.preventDefault();
    event.stopImmediatePropagation();
  }

  /**
   * Exands the tree sidenav so that the current enabled report is displayed
   * @param reportID
   */
  private expandSidenav(reportID: string): void {
    const path = this.findPathToReport(reportID);
    for (let node of this.treeControl.dataNodes) {
      if (path?.includes(node.id)) {
        this.treeControl.expand(node);
      }
    }
  }

  /**
   * Returns an array of ids for which represents the path
   * to the report.
   * @param reportID
   * @returns string[] array of ids
   */
  private findPathToReport(reportID: string): string[] {
    let path = [];
    for (let client of this.currentData) {
      path = [client.id];
      for (let project of client.projects) {
        path.push(project.id);
        for (let report of project.reports) {
          if (report.id === reportID) {
            path.push(report.id);
            return path;
          }
        }
      }
    }
  }
}
