<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ config.headline }}
    </mat-card-title>
    <mat-card-subtitle>
      <div *ngIf="config.filter.enabled" class="data-filtered-message">
        <mat-icon class="mr-1">info</mat-icon> Tagesdaten sind gefiltert
      </div>
      <br />
      <div *ngIf="
          correlationForm.get('aggregation').value
        " class="data-filtered-message">
        <mat-icon class="mr-1">info</mat-icon> Daten sind ausgedünnt
      </div>
    </mat-card-subtitle>
    <div class="ml-auto">
      <button mat-button [matMenuTriggerFor]="correlationContextMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #correlationContextMenu="matMenu">
        <button mat-menu-item (click)="exportCurrentView($event)">
          Ansicht Exportieren <mat-icon>file_download</mat-icon>
        </button>
        <button mat-menu-item (click)="exportAsCSV($event)">
          Datensatz Exportieren <mat-icon>file_download</mat-icon>
        </button>
      </mat-menu>
    </div>
  </mat-card-header>

  <mat-card-content>
    <div class="relative">
      <div *ngIf="isLoading">
        <ngx-spinner [fullScreen]="false" type="ball-scale-multiple" size="medium" class="text-white">
          Daten werden geladen ..
        </ngx-spinner>
      </div>
      <div [ngStyle]="{ width: chartWidth }">
        <canvas #chart id="chart" baseChart [datasets]="chartData" [labels]="chartLabels" [type]="'line'" [plugins]="[]"
          [options]="chartOptions">
        </canvas>
        <!-- </div> -->

        <div class="filter-group">
          <form [formGroup]="correlationForm">
            <mat-form-field appearance="fill">
              <mat-label>Parameter</mat-label>
              <mat-select (selectionChange)="selectionParameterChanged($event)" [formControl]="selectedFieldControl"
                name="selectedField">
                <mat-option matInput *ngFor="let field of availableFields | keyvalue" [value]="field.value">{{
                  field.value.field_description }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Date Range Options -->
            <mat-form-field appearance="fill">
              <mat-label>Darstellungszeitraum</mat-label>
              <mat-select (selectionChange)="dateRangeSelectionChanged($event)" [formControl]="preDefinedDatePeriod"
                name="preDefinedDateField">
                <mat-option matInput *ngFor="let timePeriod of selectableTimePeriods" [value]="timePeriod.value">{{
                  timePeriod.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Date Range Filter -->
            <mat-form-field [color]="'accent'" appearance="fill" *ngIf="customDateRange">
              <mat-label>Zeitraum filtern</mat-label>
              <mat-date-range-input [formGroup]="correlationForm" [rangePicker]="datepicker" [min]="minDate"
                [max]="maxDate">
                <input matStartDate type="text" matInput formControlName="filterDateStart" placeholder="dd.mm.yyyy" />
                <input matEndDate type="text" matInput formControlName="filterDateEnd" placeholder="dd.mm.yyyy"
                  (dateChange)="customDateFilterChanged($event)" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-date-range-picker #datepicker>
                <mat-datepicker-actions>
                  <button mat-button matDatepickerCancel>Schließen</button>
                  <button mat-raised-button color="primary" matDatepickerApply>
                    Auswählen
                  </button>
                </mat-datepicker-actions>
              </mat-date-range-picker>

              <!-- <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('required')
              "
            >
              Messzeitpunkt wird benötigt
            </mat-error>
            <mat-error
              *ngIf="
                orthofotoForm.controls.measuringDateTime.hasError('pattern')
              "
            >
              Bitte im Format [dd.mm.yyyy] eingeben
            </mat-error> -->
            </mat-form-field>

            <!-- Low Pass Filter Options -->
            <mat-form-field appearance="fill">
              <mat-label>Glättungszeitraum</mat-label>
              <mat-select (selectionChange)="selectionChanged($event)" [formControl]="preDefinedLowPassFilterPeriods"
                name="preDefinedLowPassFilter">
                <mat-option matInput *ngFor="
                    let lowPassFilterOption of selectableLowPassFilterPeriods
                  " [value]="lowPassFilterOption.value">{{ lowPassFilterOption.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="flex">
              <!-- Texts Container -->
              <div class="flex flex-col mr-2 justify-center align-middle">
                <span *ngIf="config.relative_zero">Absolute Verformung</span>
                <span>Alle Daten darstellen</span>
              </div>
              <!-- Slide Toggles Container -->
              <div class="flex flex-col">
                <mat-slide-toggle *ngIf="config.relative_zero" (change)="relativeZeroChanged($event)">
                  Relative Verformung
                </mat-slide-toggle>
                <mat-slide-toggle formControlName="aggregation" (change)="aggregationChanged($event)">
                  Daten ausdünnen
                </mat-slide-toggle>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button mat-button (click)="reportModuleService.openEditReportModuleForm(config.guid)">
      BEARBEITEN
    </button>
    <button (click)="reportModuleService.removeModuleFormReport(config.guid)" mat-button>
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>