import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface DisplayImageDialogData {}

@Component({
  selector: 'app-display-image',
  templateUrl: './display-info-image.component.html',
  styleUrls: ['./display-info-image.component.scss'],
})
export class DisplayInfoImageComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DisplayImageDialogData) {}

  ngOnInit(): void {}
}
