import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReportModuleComponent } from '../report-container/report.component';
import { ReportDirective } from '../report-container/report.directive';
import { ReportModule } from './report-module';

@Component({
  selector: 'report-module-loader',
  templateUrl: './report-module-loader.component.html',
  styleUrls: ['./report-module-loader.component.scss'],
})
export class ReportModuleLoaderComponent implements OnInit {
  @Input() reportModule: ReportModule;
  @ViewChild(ReportDirective, { static: true })
  reportModuleHost: ReportDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initalizeModule();
  }

  private initalizeModule() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.reportModule.component
    );

    const viewContainerRef = this.reportModuleHost.viewContainerRef;
    viewContainerRef.clear;

    const componentRef = viewContainerRef.createComponent<ReportModuleComponent>(
      componentFactory
    );
    componentRef.instance.config = this.reportModule.config;
  }
}
