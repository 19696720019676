import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Clients, ManagementService, Projects } from 'src/app/api/generated';
import { ProjectDialogComponent } from 'src/app/dialogs/project-dialog/project-dialog.component';
import {
  DataChangedService,
  HEADLINE_TYPE,
} from 'src/app/data-changed.service';
import { MatSort } from '@angular/material/sort';

interface TableClientsProjectsOverview {
  position: number;
  customer_name: string;
  project_name: string;
  active: boolean;
  project_id: string;
}

let TABLE_DATA: TableClientsProjectsOverview[] = [];

@Component({
  selector: 'app-projects-clients-config',
  templateUrl: './projects-clients-config.component.html',
  styleUrls: ['./projects-clients-config.component.scss'],
})
export class ProjectsClientsConfigComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource(TABLE_DATA);
  dataComplete: Clients[];
  displayedColumns: string[] = [
    'position',
    'customer_name',
    'project_name',
    'active',
  ];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private managementService: ManagementService,
    private dialog: MatDialog,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit() {
    this.dataChangedService.setHeadline({
      headline: 'Einstellungen - Projekte',
      subheadline: 'Auftraggeber verwalten',
      type: HEADLINE_TYPE.GENERIC,
      image: undefined,
    });
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  loadData() {
    TABLE_DATA = [];
    this.managementService
      .managementClientsWithProjectsList(true)
      .subscribe((clients) => {
        this.dataComplete = clients;
        let position = 1;
        for (let client of clients) {
          for (let project of client.projects) {
            TABLE_DATA.push({
              position: position,
              customer_name: client.name,
              project_name: project.name,
              active: project.active,
              project_id: project.id,
            });
            position++;
          }
        }
        this.dataSource.data = TABLE_DATA;
      });
  }

  clickedRow(element: TableClientsProjectsOverview) {
    let filteredProjects: Projects[] = [];
    this.dataComplete.forEach((client) => {
      client.projects
        .filter((project) => project.id == element.project_id)
        .forEach((filteredProject) => filteredProjects.push(filteredProject));
    });
    this.dialog
      .open(ProjectDialogComponent, {
        data: filteredProjects[0],
      })
      .afterClosed()
      .subscribe(() => {
        TABLE_DATA = [];
        this.dataSource.data = [];
        this.loadData();
        this.dataChangedService.dataUpdated('project');
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
