<div class="container">
  <mat-form-field class="w-full">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Benutzer filtern"
      #input
    />
  </mat-form-field>
  <table
    mat-table
    matSort
    [dataSource]="availableUsers"
    class="mat-elevation-z2 w-100"
    *ngIf="availableUsers"
  >
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>E-Mail</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Admin</th>
      <td mat-cell *matCellDef="let element">
        {{ element.admin }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let user; columns: displayedColumns"
      (click)="userClicked(user)"
    ></tr>
  </table>
</div>

<div class="justify-content-center d-flex">
  <div class="add-user-button d-flex flex-row align-items-center">
    <div class="p-2 button-text">Benutzer hinzufügen</div>
    <button
      class="ml-auto"
      mat-fab
      color="primary"
      aria-label="Element hinzufügen"
      (click)="userClicked(null)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
