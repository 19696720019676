import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable } from 'rxjs';
import { ManagementService, User } from 'src/app/api/generated';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DataChangedService } from 'src/app/data-changed.service';

import * as uuid from 'uuid';
import { isUserAdmin } from '../tools';
import { UserConfigComponent } from '../user-config/user-config.component';

@Component({
  selector: 'app-user-config-dialog',
  templateUrl: './user-config-dialog.component.html',
  styleUrls: ['./user-config-dialog.component.scss'],
})
export class UserConfigDialogComponent implements OnInit {
  public editMode = false;
  public userForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<User>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.id !== undefined;
    this.userForm = this.formBuilder.group({
      id: [this.data?.id ? this.data?.id : uuid.v4()],
      email: [this.data?.email ? this.data?.email : ''],
      firstName: [this.data?.first_name ? this.data?.first_name : ''],
      lastName: [this.data?.last_name ? this.data?.last_name : ''],
      admin: [this.data?.roles ? isUserAdmin(this.data) : false],
      roles: [this.data?.roles ? this.data?.roles : ''],
    });
  }

  deleteUser() {
    // Confirm deletion
    let deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        headlineText: `Benutzer ${this.data.email} endgültig entfernen? `,
        confirmationButtonText: 'Ja',
        rejectButtonText: 'Nein',
        displayText: `ACHTUNG: Benutzer wird auch von zentralen Authentifizierungsservice gelöscht.`,
      },
    });
    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const user = <User>{
          id: this.userForm.value.id,
          username: this.userForm.value.email,
          email: this.userForm.value.email,
          first_name: this.userForm.value.firstName,
          last_name: this.userForm.value.lastName,
          roles: this.userForm.value.admin,
        };
        this.managementService
          .managementUsersDetailDelete(user.id)
          .subscribe((res) => {
            this.dataChangedService.dataUpdated('user');
            this.snackBar.open(`Benutzer ${this.data.email} gelöscht.`, null, {
              duration: 1500,
            });
            this.dialogRef.close();
          });
      }
    });
  }

  resetPassword() {
    if (!this.editMode) {
      return;
    }

    this.managementService
      .managementUsersDetailPasswordResetUpdate(this.userForm.value.id, {
        email: '',
        id: '',
        username: '',
      })
      .subscribe((user) => {
        if (user.password_tmp) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: <ConfirmationDialogData>{
              headlineText: `Temporäres Passwort für ${user.email}`,
              confirmationButtonText: 'OK',
              rejectButtonText: '',
              displayText: `Temporäres Passwort: ${user.password_tmp}`,
            },
            hasBackdrop: false,
          });
        }
      });
  }

  async updateOrCreateUser() {
    if (this.userForm.invalid) {
      return;
    }

    const user = <User>{
      id: this.userForm.value.id,
      username: this.userForm.value.email,
      email: this.userForm.value.email,
      first_name: this.userForm.value.firstName,
      last_name: this.userForm.value.lastName,
      roles: this.userForm.value.admin,
    };

    let updateOrCreateObservable: Observable<User>;
    if (this.editMode) {
      updateOrCreateObservable =
        this.managementService.managementUsersDetailUpdate(user.id, user);
    } else {
      updateOrCreateObservable =
        this.managementService.managementUsersDetailCreate(user.id, user);
    }
    updateOrCreateObservable.subscribe((user) => {
      if (user.password_tmp) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: <ConfirmationDialogData>{
            headlineText: `Temporäres Passwort für ${user.email}`,
            confirmationButtonText: 'OK',
            rejectButtonText: '',
            displayText: `Temporäres Passwort: ${user.password_tmp}`,
          },
          hasBackdrop: false,
        });
      }
      this.dataChangedService.dataUpdated('user');
      this.snackBar.open(
        `Benutzer ${this.userForm.value.email} aktualisiert.`,
        null,
        {
          duration: 1500,
        }
      );
      this.dialogRef.close();
    });
  }
}
