import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import {
  DataChangedService,
  HEADLINE_TYPE,
} from 'src/app/data-changed.service';
import { ClientsSelection, ManagementService } from '../../api/generated';
import { TrigonosDatasourceSerializerComplete } from '../../api/generated/model/trigonosDatasourceSerializerComplete';
import { DatasourceConfigDialogComponent } from '../datasource-config-dialog/datasource-config-dialog.component';

interface test {
  datasources: Observable<TrigonosDatasourceSerializerComplete[]>;
  clients: Observable<ClientsSelection[]>;
}

@Component({
  selector: 'app-datasource-config',
  templateUrl: './datasource-config.component.html',
  styleUrls: ['./datasource-config.component.scss'],
})
export class DatasourceConfigComponent implements OnInit {
  private availableDatasources: TrigonosDatasourceSerializerComplete[];
  public availableClients: ClientsSelection[];
  public selectedDatasource: TrigonosDatasourceSerializerComplete;

  constructor(
    private managementService: ManagementService,
    private matDialog: MatDialog,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.dataChangedService.setHeadline({
      headline: 'Datenquellen Verwaltung',
      image: null,
      type: HEADLINE_TYPE.GENERIC,
    });
    this.buildDatasourceList();
    this.dataChangedService.adminConfigChangedObservable().subscribe((next) => {
      this.availableDatasources = [];
      this.availableClients = [];
      this.selectedDatasource = undefined;
      this.buildDatasourceList();
    });
  }

  private async buildDatasourceList() {
    const dataObservable = [
      this.managementService.managementDatasourceListList(),
      this.managementService.managementClientsList(),
    ];

    forkJoin(dataObservable).subscribe({
      next: ([datasources, clients]: [
        TrigonosDatasourceSerializerComplete[],
        ClientsSelection[]
      ]) => {
        this.availableDatasources = datasources;
        this.availableClients = clients;
      },
      complete: () => {},
    });
  }

  editOrCreateDataSource(datasource: TrigonosDatasourceSerializerComplete) {
    this.matDialog.open(DatasourceConfigDialogComponent, {
      data: datasource,
    });
  }

  public datasourcesForClient(
    client: ClientsSelection
  ): TrigonosDatasourceSerializerComplete[] {
    return this.availableDatasources.filter(
      (datasource) => client.id === datasource.assigned_client
    );
  }
}
