import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataChangedService } from './data-changed.service';
import { ClientDialogComponent } from './dialogs/client-dialog/client-dialog.component';
import {
  ProjectDialogComponent,
  ProjectDialogData,
} from './dialogs/project-dialog/project-dialog.component';
import { SettingsDialogComponent } from './dialogs/settings-dialog/settings-dialog.component';
import { UserService } from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public userData = {
    email: 'Nicht angemeldet',
    name: '',
  };

  SIDENAV_EXPANDEND_WIDTH = 350;
  SIDENAV_COLLAPSED_WIDTH = 250;

  public headline = this.dataChangedService.healdine;
  public sidenavWidth = this.SIDENAV_COLLAPSED_WIDTH;

  constructor(
    private keycloakService: KeycloakService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private dataChangedService: DataChangedService,
    public userService: UserService
  ) {}

  public ngOnInit() {
    this.userData.name = this.userService.fullname;
    this.userData.email = this.userService.email;

    this.sidenavWidth = this.SIDENAV_COLLAPSED_WIDTH;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Tablet)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  /**
   * logout
   */
  public logout() {
    this.keycloakService.logout();
  }

  public addProject() {
    this.dialog.open(ProjectDialogComponent, {
      data: <ProjectDialogData>{},
    });
  }

  public addClient() {
    this.dialog.open(ClientDialogComponent, {
      data: {},
    });
  }

  public userLogout() {
    this.keycloakService.logout();
  }

  public sidenavWidthChange() {
    if (this.sideNavIsExpanded) {
      this.sidenavWidth = this.SIDENAV_COLLAPSED_WIDTH;
    } else {
      this.sidenavWidth = this.SIDENAV_EXPANDEND_WIDTH;
    }
  }

  get sideNavIsExpanded(): boolean {
    return this.sidenavWidth === this.SIDENAV_EXPANDEND_WIDTH;
  }
}
