import { DataPoint } from 'src/app/api/generated';

export interface Filter {
  enabled: boolean;
  from: string;
  till: string;
}

export interface UserSettings {
  start_date: string;
  end_date: string;
}

export interface CorrelationFissurometerConfig {
  guid: string;
  type: string;
  headline: string;
  dataset: string[];
  selected_fields: ('mm' | 'mA' | 'dmm' | 'dmA')[];
  x_label: string;
  y_label: string;
  filter: Filter;
  user_settings: UserSettings;
  min_max: MinMaxValue[];
  relative_zero: boolean;
}

export interface MinMaxValue {
  id: string;
  min: number;
  max: number;
}

export interface DataPointGnss {
  epoch: Date;
  dx?: number | null;
  dy?: number | null;
  dz?: number | null;
  dl?: number | null;
  dq?: number | null;
  d2d?: number | null;
  d3d?: number | null;
}

export function parseGNSSData(data: any[]): any[] {
  return data.map((value) => {
    // console.log(new Date(Date.parse(value.epoch)).getTimezoneOffset());
    return {
      epoch: new Date(value.epoch),
      mm: Number(value.mm),
      mA: Number(value.mA),
      dmm: Number(value.dmm),
      dmA: Number(value.dmA),
    };
  });
}

const oneDay = 1000 * 60 * 60 * 24;
const oneHour = 1000 * 60 * 60;
export const selectableTimePeriods = [
  { viewValue: 'letzte 24 Stunden', value: new Date(Date.now() - oneDay) },
  { viewValue: 'letzte Woche', value: new Date(Date.now() - oneDay * 7) },
  { viewValue: 'letzte 2 Wochen', value: new Date(Date.now() - oneDay * 14) },
  {
    viewValue: 'letztes Monat',
    value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
  {
    viewValue: 'letztes Jahr',
    value: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  },
  {
    viewValue: 'Von Nullmessung',
    value: new Date(0),
  },
  {
    viewValue: 'Zeitbereich auswählen',
    value: -1,
  },
];

export const selectableLowPassFilterPeriods = [
  { viewValue: 'Keine Glättung', value: new Date(0) },
  { viewValue: '12 Stunden', value: new Date(Date.now() - oneHour * 12) },
  { viewValue: '1 Tag', value: new Date(Date.now() - oneDay) },
  { viewValue: '1 Woche', value: new Date(Date.now() - oneDay * 7) },
  { viewValue: '2 Wochen', value: new Date(Date.now() - oneDay * 14) },
  {
    viewValue: '1 Monat',
    value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
];

export function getMinMaxFromConfig(
  fieldName: string,
  config: MinMaxValue[]
): MinMaxValue {
  if (!config) {
    config = [];
  }
  let minMaxArr = config.filter((field) => {
    if (field.id == fieldName) {
      return true;
    }
  });

  if (minMaxArr.length === 0) {
    return <MinMaxValue>{
      id: fieldName,
      min: 0,
      max: 0,
    };
  }

  return minMaxArr[0];
}

export function getParameterDisplayName(parameter: string): string {
  return {
    mm: 'Millimeter',
    mA: 'mA',
    dmm: 'Änderung Rissweite',
    dmA: 'dmA',
  }[parameter];
}

export const CHART_COLORS = [
  'rgb(255, 99, 132)', //  red
  'rgb(54, 162, 235)', //  blue
  'rgb(255, 159, 64)', //  orange
  'rgb(75, 192, 192)', //  green
  'rgb(153, 102, 255)', // purple
  'rgb(255, 205, 86)', //  yellow
  'rgb(201, 203, 207)', //  grey
];
