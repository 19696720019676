import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import {
  ClientsSelection,
  ManagementService,
  TrigonosDatasource,
  UsersSelection,
} from 'src/app/api/generated';
import { TrigonosDatasourceSerializerComplete } from 'src/app/api/generated/model/trigonosDatasourceSerializerComplete';
import { DataChangedService } from 'src/app/data-changed.service';

import * as uuid from 'uuid';

@Component({
  selector: 'app-datasource-config-dialog',
  templateUrl: './datasource-config-dialog.component.html',
  styleUrls: ['./datasource-config-dialog.component.scss'],
})
export class DatasourceConfigDialogComponent implements OnInit {
  public datasourceForm: UntypedFormGroup;
  public editMode = false;
  public availableClients: ClientsSelection[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<TrigonosDatasourceSerializerComplete>,
    @Inject(MAT_DIALOG_DATA) public data: TrigonosDatasourceSerializerComplete,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit(): void {
    this.editMode = this.data?.id !== undefined;

    this.managementService.managementClientsList().subscribe((clients) => {
      this.availableClients = clients;
    });

    this.datasourceForm = this.formBuilder.group({
      id: [this.data?.id ? this.data?.id : uuid.v4()],
      name: [this.data?.name ? this.data?.name : ''],
      table_name: [this.data?.table_name ? this.data?.table_name : ''],
      ftp_file_path: [this.data?.ftp_file_path ? this.data?.ftp_file_path : ''],
      location_x: [this.data?.location_x ? this.data?.location_x : ''],
      location_y: [this.data?.location_y ? this.data?.location_y : ''],
      assigned_client: [
        this.data?.assigned_client ? this.data?.assigned_client : '',
      ],
    });
  }

  public updateOrCreateDatasource() {
    if (this.datasourceForm.invalid) {
      return;
    }

    const datasource: TrigonosDatasource = {
      id: this.datasourceForm.value.id,
      name: this.datasourceForm.value.name,
      table_name: this.datasourceForm.value.table_name,
      ftp_file_path: this.datasourceForm.value.ftp_file_path,
      location_x: this.datasourceForm.value.location_x,
      location_y: this.datasourceForm.value.location_y,
      assigned_client: this.datasourceForm.value.assigned_client,
    };

    let updateOrCreateObservable: Observable<TrigonosDatasource>;
    if (this.editMode) {
      updateOrCreateObservable =
        this.managementService.managementDatasourceDetailUpdate(
          this.datasourceForm.value.id,
          datasource
        );
    } else {
      updateOrCreateObservable =
        this.managementService.managementDatasourceDetailCreate(
          this.datasourceForm.value.id,
          datasource
        );
    }

    updateOrCreateObservable.subscribe((success) => {
      this.dialogRef.close();
      this.dataChangedService.adminConfigUpdated();
    });
  }
}
