<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    [style.width.px]="sidenavWidth"
    [disableClose]="(isHandset$ | async) === false"
  >
    <mat-icon
      (click)="sidenavWidthChange()"
      class="absolute top-5 right-0 cursor-pointer"
      >{{ sideNavIsExpanded ? "chevron_left" : " chevron_right" }}</mat-icon
    >
    <div class="sidenav-inner-container">
      <mat-toolbar id="navbar-header-image" class="flex flex-col">
        <a routerLink="/"
          ><img class="header-image" src="assets/tirvis-logo-4c.svg"
        /></a>
      </mat-toolbar>
      <mat-divider></mat-divider>

      <mat-nav-list>
        <div class="row w-100">
          <div class="col-3 text-center pr-4">
            <mat-icon
              class="account-circle"
              fontIcon="account_circle"
            ></mat-icon>
          </div>
          <div
            class="col-9 text-left d-flex flex-column justify-content-center font-weight-bold"
            style="padding-bottom: 12px"
          >
            <div class="font-weight-bold">{{ userData.name }}</div>
            <div class="text-muted" style="font-size: 66%">
              {{ userData.email }}
            </div>
          </div>
        </div>
      </mat-nav-list>
      <mat-divider></mat-divider>
      <side-navbar class="mr-2"></side-navbar>
      <div class="sidenav-settings" *ngIf="userService.userIsAdmin">
        <!-- <h5>Administration</h5> -->
        <!-- <button mat-raised-button color="primary" (click)="addProject()">
          <mat-icon>add</mat-icon> Projekt
        </button>
        <button mat-raised-button color="primary" (click)="addClient()">
          <mat-icon>add</mat-icon> Auftraggeber
        </button> -->
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/', 'settings']"
        >
          <mat-icon>settings</mat-icon> Einstellungen
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [style.marginLeft.px]="(isHandset$ | async) ? '' : sidenavWidth"
  >
    <mat-toolbar color="">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <img
        *ngIf="(headline | async)?.image"
        src="{{ (headline | async)?.image }}"
      />
      <div class="h-100 d-flex flex-column justify-content-center">
        <span innerHTML="{{ (headline | async)?.headline }}"></span>
        <ol class="breadcrumb" *ngIf="(headline | async)?.subheadline">
          <li
            class="breadcrumb-item active"
            *ngFor="let headline_text of (headline | async)?.subheadline"
          >
            {{ headline_text }}
          </li>
          <!-- <li class="breadcrumb-item active" aria-current="page">
            Mittelstation
          </li> -->
        </ol>
      </div>
      <div
        (click)="userLogout()"
        id="logout-button"
        class="d-flex flex-row align-items-center"
      >
        <span>Abmelden</span>
        <mat-icon>logout</mat-icon>
      </div>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
