<div class="container w-25 h-100">
  <div class="d-flex flex-column w-100">
    <h3>Einstellungen</h3>
    <!-- <button
      class="m-2"
      mat-raised-button
      [routerLink]="['/', 'settings', 'datasource']"
    >
      <mat-icon>file_present</mat-icon> Datenquellen
    </button> -->
    <button
      class="m-2"
      mat-raised-button
      [routerLink]="['/', 'settings', 'user']"
    >
      <mat-icon>manage_accounts</mat-icon> Benutzer
    </button>

    <button
      class="m-2"
      mat-raised-button
      [routerLink]="['/', 'settings', 'projects']"
    >
      <mat-icon>folder</mat-icon> Projekte
    </button>

    <button
      class="m-2"
      mat-raised-button
      [routerLink]="['/', 'settings', 'clients']"
    >
      <mat-icon>folder</mat-icon> Auftraggeber
    </button>
  </div>
</div>
