import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Clients, ManagementService, Projects } from 'src/app/api/generated';
import { ProjectDialogComponent } from 'src/app/dialogs/project-dialog/project-dialog.component';
import {
  DataChangedService,
  HEADLINE_TYPE,
} from 'src/app/data-changed.service';
import { MatSort } from '@angular/material/sort';
import { ClientDialogComponent } from 'src/app/dialogs/client-dialog/client-dialog.component';

interface TableClientsProjectsOverview {
  position: number;
  customer_name: string;
  customer_id: string;
}

let TABLE_DATA: TableClientsProjectsOverview[] = [];

@Component({
  selector: 'app-clients-config',
  templateUrl: './clients-config.component.html',
  styleUrls: ['./clients-config.component.scss'],
})
export class ClientsConfigComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource(TABLE_DATA);
  dataComplete: Clients[];
  displayedColumns: string[] = ['position', 'customer_name'];
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private managementService: ManagementService,
    private dialog: MatDialog,
    private dataChangedService: DataChangedService
  ) {}

  ngOnInit() {
    this.dataChangedService.setHeadline({
      headline: 'Einstellungen - Auftraggeber',
      subheadline: 'Auftraggeber verwalten',
      type: HEADLINE_TYPE.GENERIC,
      image: undefined,
    });
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  loadData() {
    TABLE_DATA = [];
    this.managementService
      .managementClientsWithProjectsList(true)
      .subscribe((clients) => {
        this.dataComplete = clients;
        let position = 1;
        for (let client of clients) {
          TABLE_DATA.push({
            position: position,
            customer_name: client.name,
            customer_id: client.id,
          });
          position++;
        }
        this.dataSource.data = TABLE_DATA;
      });
  }

  clickedRow(element: TableClientsProjectsOverview) {
    let filteredClients: Clients[] = [];
    this.dataComplete
      .filter((client) => client.id == element.customer_id)
      .forEach((filteredClient) => filteredClients.push(filteredClient));

    this.dialog
      .open(ClientDialogComponent, {
        data: filteredClients[0],
      })
      .afterClosed()
      .subscribe(() => {
        TABLE_DATA = [];
        this.dataSource.data = [];
        this.loadData();
        this.dataChangedService.dataUpdated('project');
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
