import {
  DataPoint,
  SensorsSensor,
  SensorsSensorfield,
  SensorsService,
} from 'src/app/api/generated';
import { VisualisableField } from './config/correlation-config/correlation-config.component';
import { Plugin } from 'chart.js';

export class DoubleKeyMap<K1, K2, V> {
  public map = new Map<K1, Map<K2, V>>();

  clear(): void {
    this.map.clear();
  }

  set(key1: K1, key2: K2, value: V): void {
    if (!this.map.has(key1)) {
      this.map.set(key1, new Map<K2, V>());
    }
    this.map.get(key1)!.set(key2, value);
  }

  get(key1: K1, key2: K2): V | undefined {
    return this.map.get(key1)?.get(key2);
  }

  delete(key1: K1, key2: K2): boolean {
    const innerMap = this.map.get(key1);
    if (innerMap) {
      const result = innerMap.delete(key2);
      if (innerMap.size === 0) {
        this.map.delete(key1);
      }
      return result;
    }
    return false;
  }

  has(key1: K1, key2: K2): boolean {
    return this.map.has(key1) && this.map.get(key1)!.has(key2);
  }
}

// Usage
const map = new DoubleKeyMap<string, string, number>();
map.set('key1a', 'key2a', 100);
console.log(map.has('key1a', 'key2a')); // true

export interface Filter {
  enabled: boolean;
  hourStart: number;
  hourEnd: number;
}

export interface UserSettings {
  start_date: string;
  end_date: string;
}

export function computeZeroFilteredDataset(
  datapoints: any[],
  field: string,
  numMeanEntries: number = 10
): any[] {
  // Create deep copy of datapoints
  datapoints = JSON.parse(JSON.stringify(datapoints));

  /* Compute the mean of the first numMeanEntries entries and subtract it from all entries */
  datapoints.forEach((item) => {
    item._time = new Date(item._time);
  });

  // Sort by time
  datapoints.sort((a, b) => a._time.getTime() - b._time.getTime());

  const meanOfFirstN =
    datapoints
      .slice(0, numMeanEntries)
      .reduce((sum, item) => sum + item[field], 0) / numMeanEntries;

  console.log(meanOfFirstN);

  datapoints.forEach((item) => {
    item[field] -= meanOfFirstN;
  });

  return datapoints;
}

export interface GenericCorrelationConfig {
  guid: string;
  type: string;
  headline: string;
  x_label: string;
  y_label: string;
  filter: Filter;
  user_settings: UserSettings;
  min_max: MinMaxValue[];
  relative_zero: boolean;
  sensorFields: SensorField[];
  defaultSelectedField: string;
}

export interface Sensor {
  checked: boolean;
  id: string;
  name: string;
  influx_name: string;
}

export interface SensorField {
  fieldName: string;
  sensors: Sensor[];
  allSelected: boolean;
}

export interface MinMaxValue {
  id: string;
  min: number;
  max: number;
}

export interface DataPointGnss {
  epoch: Date;
  dx?: number | null;
  dy?: number | null;
  dz?: number | null;
  dl?: number | null;
  dq?: number | null;
  d2d?: number | null;
  d3d?: number | null;
}

export const ChartJSNoDataPlugin = () => {
  return <Plugin>{
    id: 'textPlugin',
    beforeDraw: function (chart, args, options) {
      // Check if chart.data.dataset contains any visible datapoints
      const datasets = chart.data.datasets;

      if (datasets.length === 0) {
        return;
      }
      let visible = false;
      for (let dataset of datasets) {
        if (dataset.data.length > 0 && dataset.hidden !== true) {
          visible = true;
          break;
        }
      }

      if (visible) {
        return;
      }

      const ctx = chart.ctx;
      const text = options.text || '';
      const fontColor = options.fontColor || 'black';
      const fontSize = options.fontSize || '16px';
      const fontFamily = options.fontFamily || 'Arial';

      // Calculate the position to center the text
      const textWidth = ctx.measureText(text as string).width;
      const x = (chart.width - textWidth) / 2;
      const y = (chart.height + parseInt(fontSize as string, 10)) / 2.4;

      ctx.save();
      ctx.font = `${fontSize} ${fontFamily}`;
      ctx.fillStyle = fontColor as string;
      ctx.fillText(text as string, x, y);
      ctx.restore();
    },
  };
};

export function parseGNSSData(data: DataPoint[]): DataPointGnss[] {
  return data.map((value) => {
    // console.log(new Date(Date.parse(value.epoch)).getTimezoneOffset());
    return {
      epoch: new Date(value.epoch),
      dx: Number(value.dx),
      dy: Number(value.dy),
      dz: Number(value.dz),
      dl: Number(value.dl),
      dq: Number(value.dq),
      d2d: Number(value.d2d),
      d3d: Number(value.d3d),

      dy_k: Number(value.dy_k),
      dx_k: Number(value.dx_k),
      dz_k: Number(value.dz_k),
      d2d_k: Number(value.d2d_k),
      dl_k: Number(value.dl_k),
      dq_k: Number(value.dq_k),
      d3d_k: Number(value.d3d_k),
      vl: Number(value.vl),
      vq: Number(value.vq),
      vz: Number(value.vz),
    };
  });
}

const oneDay = 1000 * 60 * 60 * 24;
const oneHour = 1000 * 60 * 60;
export const selectableTimePeriods = [
  { viewValue: 'letzte 24 Stunden', value: new Date(Date.now() - oneDay) },
  { viewValue: 'letzte Woche', value: new Date(Date.now() - oneDay * 7) },
  { viewValue: 'letzte 2 Wochen', value: new Date(Date.now() - oneDay * 14) },
  {
    viewValue: 'letztes Monat',
    value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  },
  {
    viewValue: 'letztes Jahr',
    value: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
  },
  {
    viewValue: 'Von Nullmessung',
    value: new Date(0),
  },
  {
    viewValue: 'Zeitbereich auswählen',
    value: -1,
  },
];

export const selectableLowPassFilterPeriods = [
  { viewValue: 'Keine Glättung', value: '0' },
  { viewValue: '12 Stunden', value: '12h' },
  { viewValue: '1 Tag', value: '1d' },
  { viewValue: '1 Woche', value: '7d' },
  { viewValue: '2 Wochen', value: '14d' },
  { viewValue: '1 Monat', value: '30d' },
];

export function getMinMaxFromConfig(
  fieldName: string,
  config: MinMaxValue[]
): MinMaxValue {
  if (!config) {
    config = [];
  }
  let minMaxArr = config.filter((field) => {
    if (field.id == fieldName) {
      return true;
    }
  });

  if (minMaxArr.length === 0) {
    return <MinMaxValue>{
      id: fieldName,
      min: 0,
      max: 0,
    };
  }

  return minMaxArr[0];
}

export const CHART_COLORS = [
  'rgb(255, 99, 132)', //  red
  'rgb(54, 162, 235)', //  blue
  'rgb(255, 159, 64)', //  orange
  'rgb(75, 192, 192)', //  green
  'rgb(153, 102, 255)', // purple
  'rgb(255, 205, 86)', //  yellow
  'rgb(201, 203, 207)', //  grey
];

export function sensor_to_field_map(
  sensors: SensorsSensor[]
): Map<string, SensorsSensor[]> {
  const map = new Map<string, SensorsSensor[]>();
  for (let sensor of sensors) {
    for (let field of sensor.available_fields) {
      if (!map.has(field.identifier)) {
        map.set(field.identifier, []);
      }
      map.get(field.identifier).push(sensor);
    }
  }
  return map;
}

export function fieldsToOldRepresentation(
  sensors: SensorsSensor[]
): VisualisableField[] {
  const uniqueFields = new Set<SensorsSensorfield>();
  sensors.map((sensor) => {
    sensor.available_fields.map((field) => {
      uniqueFields.add(field);
    });
  });

  const fields = Array.from(uniqueFields);

  const newFields = fields.map((field) => {
    return <VisualisableField>{
      id: field.identifier,
      unit: field.field_unit,
      label: field.field_description,
    };
  });

  return newFields;
}
