import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ClientsSelection,
  ManagementService,
  TransformationChoices,
  UsersSelection,
} from 'src/app/api/generated';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DataChangedService } from 'src/app/data-changed.service';
import { requiresAtLeastOneUser } from 'src/app/tools/validators';
import { UserService } from 'src/app/user.service';

export interface ProjectDialogData {
  id: string;
  name: string;
  description: string;
  transformation_from: string;
  transformation_to: string;
  assigned_client: string;
  active: boolean;
}

@Component({
  selector: 'app-project-dialog',
  templateUrl: './project-dialog.component.html',
  styleUrls: ['./project-dialog.component.scss'],
})
export class ProjectDialogComponent implements OnInit {
  public projectForm: UntypedFormGroup;
  public availableClients: ClientsSelection[] = [];
  public availableTransformations: TransformationChoices[] = [];
  public availableUsers: UsersSelection[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ProjectDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ProjectDialogData,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.projectForm = this.formBuilder.group({
      id: [!this.data.id ? '' : this.data.id],
      name: [this.data.name === '' ? '' : this.data.name, Validators.required],
      description: [
        this.data.description === '' ? '' : this.data.description,
        '',
      ],
      active: [
        this.data.active == undefined ? true : this.data.active,
        Validators.required,
      ],
      client: new UntypedFormControl(),
      transformationFrom: new UntypedFormControl(),
      transformationTo: new UntypedFormControl(),
      users: this.formBuilder.array([], []),
    });

    this.projectForm.get('client').valueChanges.subscribe((id: string) => {
      // Remove items one by one to preserve subscriptions in view
      // https://stackoverflow.com/questions/41852183/remove-all-items-from-a-formarray-in-angular
      while (this.usersFromFormGroup().length !== 0) {
        this.usersFromFormGroup().removeAt(0);
      }
      // Load users based on project id
      if (this.projectForm.value.id !== '') {
        this.managementService
          .managementUsersInProjectRead(this.projectForm.value.id)
          .subscribe((users) => {
            // Sort Use by email
            users.sort((a, b) => a.email.localeCompare(b.email));

            this.availableUsers = users.filter(
              (user) => !UserService.userHasAdminRole(user)
            );
            this.availableUsers.forEach((user) =>
              this.usersFromFormGroup().push(
                new UntypedFormControl(user.user_is_in_project)
              )
            );
          });
      } else {
        // Load users based on client id
        this.managementService
          .managementUsersInClientRead(id)
          .subscribe((users) => {
            this.availableUsers = users;
            this.availableUsers = users.filter(
              (user) => !UserService.userHasAdminRole(user)
            );
            this.availableUsers.forEach((user) =>
              this.usersFromFormGroup().push(
                new UntypedFormControl(user.user_is_in_project)
              )
            );
          });
      }
    });

    this.managementService
      .managementTransformationsList()
      .subscribe((values) => {
        this.availableTransformations = values;
        if (this.data?.transformation_from) {
          this.projectForm
            .get('transformationFrom')
            .setValue(this.data.transformation_from);
        } else {
          this.projectForm.get('transformationFrom').setValue(values[0].key);
        }

        if (this.data?.transformation_to) {
          this.projectForm
            .get('transformationTo')
            .setValue(this.data.transformation_to);
        } else {
          this.projectForm.get('transformationTo').setValue(values[0].key);
        }
      });

    this.managementService.managementClientsList().subscribe((values) => {
      this.availableClients = values;
      if (this.data?.assigned_client) {
        this.projectForm.get('client').setValue(this.data.assigned_client);
      } else {
        this.projectForm.get('client').setValue(values[0].id);
      }
    });
  }

  public async updateOrCreateProject() {
    if (!this.projectForm.valid) {
      console.error(this.projectForm.errors);
      return;
    }
    let assignedUsers = this.projectForm.value.users
      .map((enabled, index) => {
        if (enabled) {
          return this.availableUsers[index].id;
        }
      })
      .filter(Boolean);

    // assignedUsers = new Set(assignedUsers);
    if (!this.data?.id) {
      this.managementService
        .managementProjectsCreate('nan', {
          name: this.projectForm.value.name,
          description: this.projectForm.value.description,
          assigned_client: this.projectForm.value.client,
          assigned_users: assignedUsers,
          transformation_from: this.projectForm.value.transformationFrom,
          transformation_to: this.projectForm.value.transformationTo,
          active: this.projectForm.value.active,
        })
        .subscribe((value) => {
          console.log(value);
          // Notify other components
          this.dataChangedService.dataUpdated('projects');
          this.snackBar.open(
            `Projekt ${this.projectForm.value.name} erstellt.`,
            null,
            {
              duration: 1500,
            }
          );
          this.dialogRef.close();
        });
    } else {
      this.managementService
        .managementProjectsUpdate(this.projectForm.value.id, {
          id: this.projectForm.value.id,
          name: this.projectForm.value.name,
          description: this.projectForm.value.description,
          assigned_client: this.projectForm.value.client,
          assigned_users: assignedUsers,
          transformation_from: this.projectForm.value.transformationFrom,
          transformation_to: this.projectForm.value.transformationTo,
          active: this.projectForm.value.active,
        })
        .subscribe((value) => {
          console.log(value);
          this.dataChangedService.dataUpdated('projects');
          this.snackBar.open(
            `Projekt ${this.projectForm.value.name} aktualisiert.`,
            null,
            {
              duration: 1500,
            }
          );
          this.dialogRef.close();
        });
    }
  }

  deleteProject(event: MouseEvent) {
    if (!this.data.id) {
      return;
    }

    // Confirm deletion
    let deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        headlineText: `Projekt ${this.data.name} endgültig entfernen? `,
        confirmationButtonText: 'Ja',
        rejectButtonText: 'Nein',
        displayText: `ACHTUNG: Es werden ALLE Reports welche dem Projekt zugewiesen sind gelöscht.`,
      },
    });
    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.managementService
          .managementProjectsDelete(this.data.id)
          .subscribe((res) => {
            this.dataChangedService.dataUpdated('projects');
          });
      }
    });

    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private usersFromFormGroup(): UntypedFormArray {
    return this.projectForm.get('users') as UntypedFormArray;
  }
}
