<h2 *ngIf="!editMode" mat-dialog-title>Modul Webcam / Bild hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>Modul Webcam / Bild editieren</h2>
<mat-dialog-content>
  <!-- <pre>Form values: {{ webcamForm?.value | json }}</pre> -->
  <div>
    <form
      [formGroup]="webcamForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" required />
        <mat-error *ngIf="webcamForm.controls.headline.hasError('required')"
          >Überschrift angeben</mat-error
        >
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>Bildbeschreibung</mat-label>
        <input matInput formControlName="imageCaption" />
      </mat-form-field> -->

      <mat-label class="mb-3 mt-2">Verfügbare Stationen</mat-label>
      <mat-radio-group formControlName="location" class="flex flex-col mb-6">
        <mat-radio-button
          class="mb-4"
          *ngFor="let loc of availableLocations"
          [value]="loc.id"
        >
          <p class="mb-1 p-0 leading-none text-lg">{{ loc.name }}</p>
          <p class="m-0 p-0 leading-none text-gray-600">
            {{ loc.auftraggeber }}
          </p>
        </mat-radio-button>
      </mat-radio-group>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!webcamForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
