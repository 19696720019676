import { Component, Input, OnInit } from '@angular/core';
import { MdEditorOption } from 'ngx-markdown-editor';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { MarkdownModuleConfigComponent } from '../config/markdown-module-config/markdown-module-config.component';

export interface MarkdownModuleConfig {
  guid: string;
  type: string;
  headline: string;
  content: string;
}

@Component({
  selector: 'app-markdown-module',
  templateUrl: './markdown-module.component.html',
  styleUrls: ['./markdown-module.component.scss'],
})
// See for detailed config
// https://github.com/lon-yang/ngx-markdown-editor/tree/master/demo/src/app
export class MarkdownModuleComponent implements OnInit {
  @Input() config: MarkdownModuleConfig;

  public static moduleDetails: ModuleConfiguration = {
    displayName: 'Text',
    previewImagePath: 'assets/markdown_preview.png',
    settingsComponent: MarkdownModuleConfigComponent,
  };

  public editorOptions: MdEditorOption = {
    showPreviewPanel: true,
    enablePreviewContentClick: true,
    resizable: false,
    showBorder: false,
    markedjsOpt: {
      sanitize: true,
    },
  };
  public content: string;
  public mode: string = 'preview';
  constructor(
    public readonly reportModuleService: ReportModulesService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.content = this.config.content;
  }
}
