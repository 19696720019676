<div class="client-headline d-flex" *ngIf="userService.userIsAdmin">
  <h5>Auftraggeber</h5>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node
    matTreeNodeToggle
    [matTreeNodeToogle]="true"
    [matTreeNodeToggleRecursive]="true"
    *matTreeNodeDef="let node"
    matTreeNodePadding
    [matTreeNodePaddingIndent]="20"
    [ngClass]="{
      'h-0':
        !userService.userIsAdmin &&
        node.type === _SidenavElement.ADD_REPORT_BUTTON
    }"
  >
    <div class="icon-align" [ngClass]="{ active: node.id === activeRouteID }">
      <button mat-icon-button disabled></button>

      <!-- Report -->
      <ng-template [ngIf]="node.type === _SidenavElement.REPORT">
        <mat-icon [routerLink]="['/report', node.id]">description</mat-icon>
        <a class="report-name-tree" [routerLink]="['/report', node.id]">{{
          node.name
        }}</a>
        <mat-icon
          *ngIf="userService.userIsAdmin && node.id === activeRouteID"
          class="edit-icon"
          (click)="
            !userService.userIsAdmin || addReport(node.id, $event, 'edit')
          "
          >edit</mat-icon
        >
      </ng-template>

      <!-- Add Report -->
      <ng-template
        [ngIf]="
          node.type === _SidenavElement.ADD_REPORT_BUTTON &&
          userService.userIsAdmin
        "
      >
        <div class="add-report-button" (click)="addReport(node.id, $event)">
          <mat-icon>note_add</mat-icon>
          <div>{{ node.name }}</div>
        </div>
      </ng-template>

      <!-- Project -->
      <ng-template [ngIf]="node.level == 1">
        <mat-icon>folder</mat-icon>
        <div>
          {{ node.name }}
        </div>
        <mat-icon
          *ngIf="userService.userIsAdmin"
          class="edit-icon"
          (click)="!userService.userIsAdmin || editProject(node.id, $event)"
          >edit</mat-icon
        >
      </ng-template>

      <!-- Client -->
      <ng-template [ngIf]="node.level == 0">
        <div>
          {{ node.name }}
        </div>
        <mat-icon
          *ngIf="userService.userIsAdmin"
          class="edit-icon"
          (click)="!userService.userIsAdmin || editClient(node.id, $event)"
          >edit</mat-icon
        >
      </ng-template>
    </div>
  </mat-tree-node>
  <mat-tree-node
    matTreeNodeToggle
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding
    [matTreeNodePaddingIndent]="20"
    [matTreeNodeToogle]="true"
    [matTreeNodeToggleRecursive]="true"
    [ngClass]="{
      'h-0':
        !userService.userIsAdmin &&
        node.type === _SidenavElement.ADD_REPORT_BUTTON
    }"
  >
    <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>
    <!-- @ToDo duplicated code -->
    <!-- Report -->
    <ng-template [ngIf]="node.type === _SidenavElement.REPORT">
      <mat-icon [routerLink]="['/report', node.id]">description</mat-icon>
      <a class="report-name-tree" [routerLink]="['/report', node.id]">{{
        node.name
      }}</a>
      <mat-icon
        *ngIf="userService.userIsAdmin"
        class="edit-icon"
        (click)="!userService.userIsAdmin || addReport(node.id, $event)"
        >edit</mat-icon
      >
    </ng-template>

    <!-- Add Report -->
    <ng-template
      [ngIf]="
        node.type === _SidenavElement.ADD_REPORT_BUTTON &&
        userService.userIsAdmin
      "
    >
      <div class="add-report-button" (click)="addReport(node.id, $event)">
        <mat-icon>note_add</mat-icon>
        <div>{{ node.name }}</div>
      </div>
    </ng-template>

    <!-- Project -->
    <ng-template [ngIf]="node.level == 1">
      <mat-icon>folder</mat-icon>
      <div>
        {{ node.name }}
      </div>
      <mat-icon
        *ngIf="userService.userIsAdmin"
        class="edit-icon"
        (click)="!userService.userIsAdmin || editProject(node.id, $event)"
        >edit</mat-icon
      >
    </ng-template>

    <!-- Client -->
    <ng-template [ngIf]="node.level == 0">
      <div>
        {{ node.name }}
      </div>
      <mat-icon
        *ngIf="userService.userIsAdmin"
        class="edit-icon"
        (click)="!userService.userIsAdmin || editClient(node.id, $event)"
        >edit</mat-icon
      >
    </ng-template>
  </mat-tree-node>
</mat-tree>
