import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { User, UsersSelection } from './api/generated';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private keycloakService: KeycloakService) {
    this.keycloakService.loadUserProfile().then((res) => {});
  }

  public get userIsAdmin(): boolean {
    return this.keycloakService.isUserInRole('trigonos-admin');
  }

  public get fullname(): string {
    return `${this.keycloakService.getKeycloakInstance().profile.firstName} ${
      this.keycloakService.getKeycloakInstance().profile.lastName
    }`;
  }

  public get email(): string {
    return `${this.keycloakService.getKeycloakInstance().profile.email}`;
  }

  public static userHasAdminRole(user: User | UsersSelection): boolean {
    // User has property .roles which contains a list of roles. If one role has the property .name equal to admin return true, otherwise false
    return (user.roles as any).some((role) => role.name === 'trigonos-admin');
  }
}
