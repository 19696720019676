import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-add-select-dialog',
  templateUrl: './add-select-dialog.component.html',
  styleUrls: ['./add-select-dialog.component.scss'],
})
export class AddSelectDialogComponent implements OnInit {
  public availableModules: any[] = [];

  constructor(
    private readonly moduleService: ReportModulesService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    for (let key of this.moduleService.getRegisteredModuleKeys()) {
      this.availableModules.push(this.moduleService.getModule(key));
    }
  }
  public addModule(moduleID: number): void {
    this.dialog.open(
      (this.availableModules[moduleID].moduleDetails as ModuleConfiguration)
        .settingsComponent,
      {}
    );
    this.dialogRef.close();
  }
}
