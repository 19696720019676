<form [formGroup]="clientForm" (ngSubmit)="updateOrCreateClient()">
  <mat-dialog-content>
    <div>
      <h4 mat-dialog-title *ngIf="!data.id">Neuen Auftraggeber anlegen</h4>
      <h4 mat-dialog-title *ngIf="data.id">
        Auftraggeber {{ data.name }} editieren
      </h4>
      <mat-divider></mat-divider>
      <div>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
          <mat-error *ngIf="clientForm.controls.name.hasError('required')">
            Name des Auftraggebers angeben
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Beschreibung</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
        <!-- <mat-form-field>
          <mat-label>Primäre Farbe</mat-label>
          <input
            matInput
            formControlName="primary_color"
            placeholder="#000000"
          />
          <mat-error
            *ngIf="clientForm.controls.primary_color.hasError('validColor')"
          >
            Farbe im HEX #000000 Format angeben
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Sekundäre Farbe</mat-label>
          <input
            matInput
            formControlName="secondary_color"
            placeholder="#000000"
          />
          <mat-error
            *ngIf="clientForm.controls.secondary_color.hasError('validColor')"
          >
            Farbe im HEX #000000 Format angeben
          </mat-error>
        </mat-form-field> -->

        <!-- <mat-label>Ausgewählte Benutzer</mat-label>
        <div class="user-selection-group">
          <ng-container formArrayName="users">
            <div *ngFor="let user of availableUsers; let i = index">
              <mat-checkbox [formControlName]="i">
                {{ user.email }}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
        <mat-error
          *ngIf="clientForm.controls.users.hasError('requiresAtLeastOneUser')"
        >
          Mindestens einen Benutzer angeben
        </mat-error> -->

        <div style="display: none">
          <mat-label>Logo</mat-label>
          <input
            type="file"
            (change)="onFileChange($event)"
            name="Logo"
            accept="image/*"
            #file
          />
        </div>
        <mat-label>Logo des Auftraggebers*</mat-label>
        <div class="image-preview-wrapper" (click)="file.click()">
          <ng-template [ngIf]="logoPreview">
            <div class="image-preview">
              <img [src]="logoPreview" />
            </div>
          </ng-template>
          <ng-template [ngIf]="!logoPreview">
            <div class="image-placeholder">
              <mat-icon>file_upload</mat-icon>
              Logo auswählen
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!clientForm.valid"
      cdkFocusInitial
    >
      <ng-template [ngIf]="!data.id">Auftraggeber anlegen</ng-template>
      <ng-template [ngIf]="data.id">Speichern</ng-template>
    </button>
    <!-- Buttons are disabled to avoid confusion when re-creating clients -->
    <!-- <button mat-raised-button *ngIf="data.id" (click)="deleteClient($event)">
      {{ data.name }} löschen
    </button> -->
    <button mat-raised-button [mat-dialog-close]="true">Schließen</button>
  </mat-dialog-actions>
</form>
