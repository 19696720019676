import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { DatasourceConfigComponent } from './datasource/datasource-config/datasource-config.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivateComponent } from './private/private.component';
import { PublicComponent } from './public/public.component';
import { ReportContainerComponent } from './report-container/report-container.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { ClientsConfigComponent } from './settings_components/clients-config/clients-config.component';
import { ProjectsClientsConfigComponent } from './settings_components/projects-clients-config/projects-clients-config.component';
import { UserConfigComponent } from './user/user-config/user-config.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report/:reportID',
    component: ReportContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsPageComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['trigonos-admin'],
    },
  },
  {
    path: 'settings/datasource',
    component: DatasourceConfigComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['trigonos-admin'],
    },
  },
  {
    path: 'settings/user',
    component: UserConfigComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['trigonos-admin'],
    },
  },
  {
    path: 'settings/projects',
    component: ProjectsClientsConfigComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['trigonos-admin'],
    },
  },
  {
    path: 'settings/clients',
    component: ClientsConfigComponent,
    canActivate: [AuthGuard],
    data: {
      roles: ['trigonos-admin'],
    },
  },
  { path: '404', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
