<h2 *ngIf="!editMode" mat-dialog-title>
  Modul Bewegungs Scatterplot hinzufügen
</h2>
<h2 *ngIf="editMode" mat-dialog-title>Modul Bewegungs Scatterplot editieren</h2>
<mat-dialog-content>
  <div>
    <form
      [formGroup]="correlationForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput formControlName="headline" required />
        <mat-error
          *ngIf="correlationForm.controls.headline.hasError('required')"
          >Überschrift angeben</mat-error
        >
      </mat-form-field>

      <mat-label>Visualisierbare Daten</mat-label>
      <div class="user-selection-group">
        <ng-container formArrayName="datasources">
          <div *ngFor="let datasource of availableDataSources; let i = index">
            <mat-checkbox [formControlName]="i">
              {{ datasource.name }}
            </mat-checkbox>
          </div>
        </ng-container>
      </div>

      <mat-slide-toggle formControlName="timePeriodFiltered"
        >Zeitraum filtern</mat-slide-toggle
      >
      <div class="d-flex flex-row">
        <mat-form-field class="p-2">
          <mat-label>Startzeit</mat-label>
          <input
            [attr.disabled]="timeFilterDisabled"
            matInput
            formControlName="timePeriodFilteredFrom"
          />
          <mat-error
            *ngIf="
              correlationForm.controls.timePeriodFilteredFrom.hasError(
                'validTime'
              )
            "
            >Zeitraum im Format [hh:mm] angeben</mat-error
          >
        </mat-form-field>

        <mat-form-field class="p-2">
          <mat-label>Endzeit</mat-label>
          <input
            [attr.disabled]="timeFilterDisabled"
            matInput
            formControlName="timePeriodFilteredTill"
          />
          <mat-error
            *ngIf="
              correlationForm.controls.timePeriodFilteredTill.hasError(
                'validTime'
              )
            "
          >
            Zeitraum im Format [hh:mm] angeben</mat-error
          >
        </mat-form-field>
      </div>

      <div>
        <div style="display: none">
          <mat-label>Bild</mat-label>
          <input
            type="file"
            (change)="onFileChange($event)"
            name="Bild"
            accept="image/*"
            #file
          />
        </div>
        <mat-lab></mat-lab>
        <div
          class="image-preview-wrapper d-flex justify-content-center"
          (click)="file.click()"
        >
          <div class="image-preview">
            <ng-template [ngIf]="imagePreviewURL">
              <img #image [src]="imagePreviewURL" />
            </ng-template>
            <ng-template [ngIf]="!imagePreviewURL">
              <div class="image-placeholder">
                <mat-icon>file_upload</mat-icon>
                Bild auswählen
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!correlationForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
