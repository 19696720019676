<h2 *ngIf="!editMode" mat-dialog-title>
  Modul Zeit-Verformungsgraphik NEU hinzufügen
</h2>
<h2 *ngIf="editMode" mat-dialog-title>
  Modul Zeit-Verformungsgraphik NEU editieren
</h2>
<mat-dialog-content>
  <div class="container">
    <form
      [formGroup]="correlationForm"
      (ngSubmit)="updateOrCreateModule()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <h5>Konfiguration Graphik</h5>
      <div class="ml-2 flex flex-col">
        <mat-form-field>
          <mat-label>Überschrift</mat-label>
          <input matInput formControlName="headline" required />
          <mat-error
            *ngIf="correlationForm.controls.headline.hasError('required')"
            >Überschrift angeben</mat-error
          >
        </mat-form-field>

        <!-- <pre>Form values: {{ correlationForm?.value | json }}</pre> -->

        <mat-form-field>
          <mat-label>X-Achse Beschriftung</mat-label>
          <input matInput formControlName="labelXAxis" required />
          <mat-error
            *ngIf="correlationForm.controls.labelXAxis.hasError('required')"
          >
            Beschriftung für X-Achse angeben
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Y-Achse Beschriftung</mat-label>
          <input matInput formControlName="labelYAxis" required />
          <mat-error
            *ngIf="correlationForm.controls.labelYAxis.hasError('required')"
          >
            Beschriftung für Y-Achse angeben
          </mat-error>
        </mat-form-field>

        <mat-slide-toggle formControlName="relativeZero" class="mb-2"
          >Darstellung der relativen Verformung erlauben</mat-slide-toggle
        >
      </div>

      <!-- <h5 class="mt-2">Zeitraum</h5> -->
      <div class="mt-1 mb-1 ml-2">
        <mat-slide-toggle formControlName="timePeriodFiltered"
          >Daten zwischen bestimmten Uhrzeiten anzeigen
        </mat-slide-toggle>
        <div class="d-flex flex-row">
          <mat-form-field class="p-2">
            <mat-label>Filter Start [Stunde]</mat-label>
            <input
              [attr.disabled]="timeFilterDisabled"
              matInput
              formControlName="timePeriodFilteredFrom"
            />
            <mat-error
              *ngIf="
                correlationForm.controls.timePeriodFilteredFrom.hasError(
                  'validTime'
                )
              "
              >Zeitraum im Format [hh] angeben</mat-error
            >
          </mat-form-field>

          <mat-form-field class="p-2">
            <mat-label>Filter Ende [Stunde]</mat-label>
            <input
              [attr.disabled]="timeFilterDisabled"
              matInput
              formControlName="timePeriodFilteredTill"
              type="number"
              min="0"
              max="23"
            />
            <mat-error
              *ngIf="
                correlationForm.controls.timePeriodFilteredTill.hasError(
                  'validTime'
                )
              "
            >
              Zeitraum im Format [hh] angeben</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <h5 class="mt-1 mb-0">Auswahl Daten</h5>
      <div formArrayName="sensorFields" class="mb-1 ml-2">
        <div
          *ngFor="let fieldCtrl of sensorFields.controls; let i = index"
          [formGroupName]="i"
          class="mb-2"
        >
          <mat-checkbox
            formControlName="allSelected"
            (change)="onAllSelectedChange(i, $event.checked)"
          >
            {{ fieldCtrl.value.displayName }}
          </mat-checkbox>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title
                  >{{
                    selectedStationsForField(fieldCtrl.get("sensors").controls)
                  }}
                  / {{ fieldCtrl.get("sensors").controls.length }} Stationen
                  ausgewählt
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- BEGIN FORM -->
              <div formArrayName="sensors" class="pl-5">
                <div
                  *ngFor="
                    let sensorCtrl of fieldCtrl.get('sensors').controls;
                    let j = index
                  "
                  [formGroupName]="j"
                >
                  <mat-checkbox
                    formControlName="checked"
                    (change)="onSensorChange(i, j)"
                  >
                    {{ sensorCtrl.get("name").value }}
                  </mat-checkbox>
                </div>
              </div>
              <!-- END FORM -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <h5 class="mb-0 mt-1">Minimal- und Maximalwert Ordinate</h5>
      <div class="user-selection-group mt-0">
        <ng-container formArrayName="yAxisMinValues">
          <div
            *ngFor="
              let field of correlationForm.get('yAxisMinValues')['controls']
                | MinMaxOrdinateFilter : selectedFields;
              let i = index
            "
            class=""
          >
            <div
              [formGroup]="field"
              class="flex flex-row space-x-2 justify-end"
            >
              <div
                class="mr-auto flex flex-col-rev justify-end content-center pt-3 w-full h-full"
              >
                <mat-label>{{
                  sensorInfoForId(field.value.id).label
                }}</mat-label>
              </div>

              <div>
                <mat-form-field>
                  <mat-label
                    >Min [{{ sensorInfoForId(field.value.id).unit }}]</mat-label
                  >
                  <input
                    step="0.0001"
                    type="number"
                    matInput
                    formControlName="min"
                  />
                </mat-form-field>
              </div>

              <div class="mr-16">
                <mat-form-field>
                  <mat-label
                    >Max [{{ sensorInfoForId(field.value.id).unit }}]</mat-label
                  >
                  <input
                    step="0.0001"
                    type="number"
                    matInput
                    formControlName="max"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <h5 class="mb-0 mt-1">Standardmäßig ausgewählter Parameter</h5>
      <mat-form-field appearance="fill">
        <mat-label>Default Feld</mat-label>
        <mat-select
          [formControl]="defaultSelectedFieldControl"
          name="defaultSelectedField"
        >
          <mat-option
            matInput
            *ngFor="let field of selectedFields"
            [value]="field.fieldName"
            required
            >{{ sensorInfoForId(field.fieldName).label }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!correlationForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
