import {
  AbstractControl,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function createDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValidDate =
      /^([0][1-9]|[12][0-9]|3[01])[\.]([0][1-9]|1[012])[\.]\d{4}( (0?[1-9]|[1][0-9]|[2][0-3])[:]([0-5][0-9])?)?$/.test(
        value
      );

    return !isValidDate ? { validDate: true } : null;
  };
}

export function hexColorValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }
    const isValidHexColorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
    const isValid = isValidHexColorRegex.test(value);
    return !isValid ? { validColor: { value: control.value } } : null;
  };
}

export function requiresAtLeastOneUser(): ValidatorFn {
  return (array: UntypedFormArray): ValidationErrors | null => {
    // const value = control.value;
    const controlValues: boolean[] = array.controls
      .map((control) => {
        return control.value;
      })
      .filter(Boolean);

    const isValid = controlValues.length > 0;
    return !isValid ? { requiresAtLeastOneUser: { value: isValid } } : null;
  };
}

export function validTime(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }
    const isValidHexColorRegex = /\b([01]?[0-9]|2[0-3]):[0-5][0-9]\b/;
    const isValid = isValidHexColorRegex.test(value);
    return !isValid ? { validTime: { value: control.value } } : null;
  };
}

export function validURL(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validUrl = true;

    try {
      new URL(control.value);
    } catch {
      validUrl = false;
    }

    return validUrl ? null : { invalidUrl: true };
  };
}
