<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ config.headline }}
    </mat-card-title>
    <mat-card-subtitle>
      <div *ngIf="config.refresh" class="ml-auto update-time">
        Letztes Update: {{ lastUpdate | date: "mediumTime" }}
      </div></mat-card-subtitle
    >
  </mat-card-header>

  <img #image mat-card-image [attr.src]="imageURL" />
  <mat-card-content>{{ config.description }}</mat-card-content>
  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button
      mat-button
      (click)="reportModuleService.openEditReportModuleForm(config.guid)"
    >
      BEARBEITEN
    </button>
    <button
      (click)="reportModuleService.removeModuleFormReport(config.guid)"
      mat-button
    >
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
