import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { environment } from 'src/environments/environment';
import { ImageViewerConfigComponent } from '../config/image-viewer-config/image-viewer-config.component';
import { ImageViewerConfig } from '../image-viewer.tools';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, AfterViewInit {
  @Input() config: ImageViewerConfig;
  @ViewChild('image') image: ElementRef;

  public static moduleDetails: ModuleConfiguration = {
    displayName: 'Bild / Webcam',
    previewImagePath: 'assets/image_viewer_preview.png',
    settingsComponent: ImageViewerConfigComponent,
  };

  public lastUpdate = new Date(Date.now());
  private updateInterval;
  public imageURL: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer,
    public reportModuleService: ReportModulesService,
    public userService: UserService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.config.refresh === true) {
      this.updateInterval = setInterval(() => {
        this.lastUpdate = new Date(Date.now());
        this.image.nativeElement.src = this.config.resource_url;
      }, this.config.refresh_timer * 1000);
    }
    if (!this.config.refresh) {
      this.imageURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.apiEndpoint}${this.config.resource_url}`
      );
    } else {
      this.imageURL = this.config.resource_url;
    }

    this.image.nativeElement.src = this.imageURL;
  }
}
