import { Component, OnInit } from '@angular/core';
import { DataChangedService, HEADLINE_TYPE } from '../data-changed.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent implements OnInit {
  constructor(private dataChangedService: DataChangedService) {}

  ngOnInit(): void {
    this.dataChangedService.setHeadline({
      headline: 'Einstellungen',
      image: null,
      type: HEADLINE_TYPE.GENERIC,
    });
  }
}
