<h2 *ngIf="!editMode" mat-dialog-title>Datenquelle hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>Datenquelle editieren</h2>
<mat-dialog-content>
  <div>
    <form
      [formGroup]="datasourceForm"
      (ngSubmit)="updateOrCreateDatasource()"
      ngNativeValidate
      class="d-flex flex-column"
    >
      <mat-form-field>
        <mat-label>Name der Datenquelle</mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf="datasourceForm.controls.name.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Name der Tabelle</mat-label>
        <input matInput formControlName="table_name" required />
        <mat-error
          *ngIf="datasourceForm.controls.table_name.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>FTP Pfad</mat-label>
        <input matInput formControlName="ftp_file_path" required />
        <mat-error
          *ngIf="datasourceForm.controls.ftp_file_path.hasError('required')"
          >Feld wird benötigt</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Auftraggeber</mat-label>
        <mat-select formControlName="assigned_client" required>
          <mat-option
            matInput
            *ngFor="let client of availableClients"
            [value]="client.id"
            >{{ client.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!datasourceForm.valid"
      >
        <ng-template [ngIf]="editMode">Speichern</ng-template>
        <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
      </button>
    </form>
  </div>
</mat-dialog-content>
