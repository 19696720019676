import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ChartOptions, LinearScale, Plugin } from 'chart.js';

import Gradient from 'javascript-color-gradient';

import { BaseChartDirective } from 'ng2-charts';
import { Observable, merge, of, timer } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import {
  DataService,
  ManagementService,
  SensorsSensor,
  SensorsSensorfield,
  SensorsService,
} from 'src/app/api/generated';
import 'chartjs-adapter-moment';

import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { GenericMovementScatterplotConfigComponent } from '../config/movement_scatterplot-config/movement_scatterplot-config.component';
import {
  parseGNSSData,
  DataPointGnss,
  selectableTimePeriods,
  CHART_COLORS,
  ChartJSImagePlugin,
  htmlLegendPlugin,
  round,
  MOVEMENT_SCATTER_FIELDS,
  GenericMovementScatterplotConfig,
  Datasource,
} from '../movement_scatter.tools';
import * as moment from 'moment';

import { CsvExportService } from 'src/app/csv-export.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

type DataPoint = {
  dl: number;
  dq: number;
  epoch: Date;
};

@Component({
  selector: 'app-movement-scatterplot-generic',
  templateUrl: './movement_scatterplot.component.html',
  styleUrls: ['./movement_scatterplot.component.scss'],
})
export class GenericMovementScatterplotComponent implements OnInit {
  @Input() config: GenericMovementScatterplotConfig;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @ViewChild('legendCanvas') legendCanvas: ElementRef<HTMLCanvasElement>;
  public legendCanvasContext: CanvasRenderingContext2D;

  @ViewChild('legendDiv') legendDiv: ElementRef<HTMLDivElement>;

  public static moduleDetails: ModuleConfiguration = {
    displayName: '[NEU] Bewegungs Scatterplot',
    previewImagePath: 'assets/movement_scatter_preview.png',
    settingsComponent: GenericMovementScatterplotConfigComponent,
  };

  public chartData: [] = [];
  public chartLabels: any = [];
  private labelsComplete: any = [];
  private loadedDatasets: Map<string, DataPoint[]> = new Map<
    string,
    DataPoint[]
  >();
  public chartWidth = '100%';
  public legendDivHeight = '100%';

  public settingsForm: UntypedFormGroup;
  public selectedFieldControl = new UntypedFormControl();
  public lowPassDateRange = new UntypedFormControl();

  public selectableTimePeriods = selectableTimePeriods;
  public preDefinedDatePeriod = new UntypedFormControl(
    selectableTimePeriods[5].value
  );

  public datasetDetails: Map<string, SensorsSensor> = new Map<
    string,
    SensorsSensor
  >();

  public maxDates: Map<string, Date> = new Map();
  public minDates: Map<string, Date> = new Map();
  public customDateRange = false;
  private lastCustomDateRange = Date.now();
  public relativeZeroFiltering = false;
  public gradientColors = {
    start: '#3F2CAF',
    end: '#e9FF6a',
  };

  public htmlLegendPlugin = htmlLegendPlugin;

  public chartOptions: ChartOptions = {};
  onInitFinished: boolean = false;

  public availableFields: Map<string, SensorsSensorfield> = new Map<
    string,
    SensorsSensorfield
  >();

  public availableSensors: Map<string, SensorsSensor> = new Map<
    string,
    SensorsSensor
  >();

  constructor(
    private readonly dataService: DataService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder,
    public readonly reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    public userService: UserService,
    private csvExportService: CsvExportService,
    private sensorService: SensorsService,
    private spinner: NgxSpinnerService
  ) {}

  private getlargestValueFromDataset(): number {
    let data = this.loadedDatasets.get(this.selectedFieldControl.value);
    if (!data) {
      return 0;
    }
    const maxdl = Math.max(...data.map((o) => Math.abs((o as any).dl)));
    const maxdq = Math.max(...data.map((o) => Math.abs((o as any).dq)));
    const max = Math.max(...[maxdl, maxdq]);
    return round(max * 1.2, 10);
  }

  private beforeXScaleFit(scale: LinearScale) {
    const max = this.getlargestValueFromDataset();
    if (scale.options.max !== max) {
      scale.options.max = max;
      scale.options.min = -max;
      if (this.chart) {
        this.chart.update();
      }
    }
  }

  private beforeYScaleFit(scale: LinearScale) {
    const max = this.getlargestValueFromDataset();
    console.log('Max on Y-Axis: ', max);
    if (scale.options.max !== max) {
      scale.options.max = max;
      scale.options.min = -max;
      if (this.chart) {
        this.chart.update();
      }
    }
  }

  public async loadData(initialLoading: boolean) {
    let allRequests = [];

    if (initialLoading) {
      // Load Information about the sensors
      const sensorInfoRequests = this.config.dataset.map((sensor) => {
        return this.sensorService.sensorsSensorRead(sensor.id).pipe(
          tap((result) => {
            if (sensor.checked) {
              this.availableSensors.set(sensor.id, result);
            }
          })
        );
      });

      // Load date ranges
      const dateRangeRequests = this.config.dataset
        .map((sensor) =>
          MOVEMENT_SCATTER_FIELDS.map((field) => {
            return this.dataService
              .dataDaterangeInfluxList(sensor.id, field)
              .pipe(
                tap((result) => {
                  const _result = {
                    start_date: new Date(result.start_date),
                    end_date: new Date(result.end_date),
                  };

                  if (!this.minDates.has(sensor.id)) {
                    this.minDates.set(sensor.id, _result.start_date);
                  } else if (
                    _result.start_date > this.minDates.get(sensor.id)
                  ) {
                    this.minDates.set(sensor.id, _result.start_date);
                  }

                  if (!this.maxDates.has(sensor.id)) {
                    this.maxDates.set(sensor.id, _result.end_date);
                  } else if (_result.end_date < this.maxDates.get(sensor.id)) {
                    this.maxDates.set(sensor.id, _result.end_date);
                  }
                })
              );
          })
        )
        .flat();

      const { startDate, endDate } = this.getStartEndDate();
      const dataRequests = this.config.dataset
        .map((sensor) => {
          return this.dataService
            .dataMeasurementsFieldsList(
              sensor.id,
              startDate,
              endDate,
              MOVEMENT_SCATTER_FIELDS
            )
            .pipe(
              tap((result) => {
                if (sensor.checked) {
                  this.loadedDatasets.set(sensor.id, result as any);
                }
              })
            );
          // })
        })
        .flat();

      if (!this.customDateRange) allRequests.push(...dateRangeRequests);
      // allRequests.push(...sensorFieldInfoRequests);
      allRequests.push(...dataRequests);
      allRequests.push(...sensorInfoRequests);
    }

    const allRequestsHandled = allRequests.map((req$) =>
      req$.pipe(
        catchError((err) => {
          // Probably fix this in the future
          return of(null);
        })
      )
    );

    const merged$ = merge(...allRequestsHandled);
    merged$.subscribe({
      complete: () => {
        console.log('complete');

        // Deterministically set the selected field as the first in the config
        const fieldNames = this.config.dataset.filter((field) => field.checked);

        if (fieldNames.length > 0) {
          this.selectedFieldControl.setValue(fieldNames[0].id);
        }

        if (!this.customDateRange) {
          this.settingsForm.controls['filterDateStart'].setValue(
            this.minDates.get(fieldNames[0].id)
          );
          this.settingsForm.controls['filterDateEnd'].setValue(
            this.maxDates.get(fieldNames[0].id)
          );
        }

        this.afterDataLoaded();

        this.isLoading = false;
        this.spinner.hide();
      },
    });
  }

  public isLoading = true;

  async ngOnInit() {
    this.settingsForm = this.formBuilder.group({
      preDefinedDateField: this.preDefinedDatePeriod,
      filterDateStart: [new Date(0)],
      filterDateEnd: [new Date()],
    });

    this.spinner.show();

    this.loadData(true);

    this.beforeXScaleFit = this.beforeXScaleFit.bind(this);
    this.beforeYScaleFit = this.beforeYScaleFit.bind(this);

    this.chartOptions = {
      elements: {
        point: {},
      },
      plugins: {
        legend: {
          display: false,
          onClick: (event, legendItem, legend) => {},
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          intersect: true,
          callbacks: {},
        },
      },

      animation: {
        duration: 0,
      },
      hover: {
        mode: null,
      },
      responsive: true,
      scales: {
        y: {
          // min: -0.1,
          // max: 0.1,
          beforeFit: this.beforeYScaleFit,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value: string | number, index, values) => {
              let range = Math.abs(
                values[0].value - values[values.length - 1].value
              );

              value = parseFloat(value as string);

              const labels = values.map((v) => v.label);
              // Check if labels has duplicates
              new Set(labels).size !== labels.length;

              if (range <= 20) {
                if (new Set(labels).size !== labels.length) {
                  value = value.toFixed(3);
                } else {
                  value = value.toFixed(2);
                }
              } else {
                value = value.toFixed(0);
              }

              let suffix = this.selectedFieldControl.value?.field_unit || '';
              return `${value} ${suffix}`;
            },
            includeBounds: false,
          },
          title: {
            text: 'Verschiebung Quer',
            display: true,
          },
        },
        x: {
          // min: -0.1,
          // max: 0.1,
          beforeFit: this.beforeXScaleFit,
          grid: {
            display: false,
          },
          ticks: {
            callback: (value: string | number, index, values) => {
              let range = Math.abs(
                values[0].value - values[values.length - 1].value
              );

              value = parseFloat(value as string);

              const labels = values.map((v) => v.label);
              // Check if labels has duplicates
              new Set(labels).size !== labels.length;

              if (range <= 20) {
                if (new Set(labels).size !== labels.length) {
                  value = value.toFixed(3);
                } else {
                  value = value.toFixed(2);
                }
              } else {
                value = value.toFixed(0);
              }

              let suffix = this.selectedFieldControl.value?.field_unit || '';
              return `${value} ${suffix}`;
            },
            includeBounds: false,
          },
          title: {
            text: 'Verschiebung Längs',
            display: true,
          },
        },
      },
    };

    this.onInitFinished = true;
  }

  private afterDataLoaded() {
    // this.reloadGraph();
    this.legendCanvasContext = this.legendCanvas.nativeElement.getContext('2d');
    const gradient = this.legendCanvasContext.createLinearGradient(
      0,
      0,
      0,
      170
    );
    gradient.addColorStop(0, this.gradientColors.start);
    gradient.addColorStop(1, this.gradientColors.end);
    this.legendCanvasContext.fillStyle = gradient;
    this.legendCanvasContext.fillRect(0, 0, 9999, 9999);

    // Register background image plugin
    const imagePlugin: Plugin = ChartJSImagePlugin(
      `${environment.apiEndpoint}${this.config.bg_image_resource_url}`
    );
    this.chart.plugins.push(imagePlugin);

    // Make sure that the legend has the right height
    this.onChartResize(undefined);
    this.loadDatasetAndVisualise();
  }

  private getStartEndDate(): { startDate: string; endDate: string } {
    let startDate: string;
    let endDate: string;
    if (this.customDateRange || this.preDefinedDatePeriod.value === -1) {
      let filterDateStartValue =
        this.settingsForm.controls['filterDateStart'].value;
      let filterDateEndValue =
        this.settingsForm.controls['filterDateEnd'].value;

      // Convert the date object to a moment object
      filterDateStartValue = moment(filterDateStartValue);
      filterDateEndValue = moment(filterDateEndValue);

      startDate = filterDateStartValue
        ? filterDateStartValue.startOf('day').utcOffset(0, true).toISOString()
        : new Date(0).toISOString();
      endDate = filterDateEndValue
        ? filterDateEndValue.startOf('day').utcOffset(0, true).toISOString()
        : new Date(0).toISOString();
    } else {
      startDate = this.preDefinedDatePeriod.value.toISOString();
      endDate = new Date(Date.now()).toISOString();
    }
    return { startDate, endDate };
  }

  private loadDatasetAndVisualise() {
    for (let dataset of this.loadedDatasets.keys()) {
      this.updateGraph(dataset);
    }

    this.chart.chart.update();
  }

  public updateGraph(datasetName: string) {
    let dataset = this.loadedDatasets.get(datasetName);
    if (!dataset) {
      return;
    }

    const datapoints = dataset.map(
      (datapoint) =>
        <any>{
          x: datapoint.dl,
          y: datapoint.dq,
        }
    );

    const datapointColor = this.availableSensors.get(datasetName).color;

    const data = {
      meta: datasetName,
      hidden: this.selectedFieldControl.value != datasetName,
      data: datapoints,
      label: `${this.availableSensors.get(datasetName).display_name}`,
      fill: false,
      lineTension: 0,
      pointBorderWidth: 0.2,
      pointBorderColor: 'black',
      pointRadius: 2.2,
      borderWidth: 0.5,
      hoverRadius: 3,
      pointBackgroundColor: (context) => {
        const gradientArray = new Gradient()
          .setColorGradient('#3F2CAF', '#e9FF6a')
          .setMidpoint(context.dataset.data.length)
          .getColors();

        return gradientArray[context.dataIndex];
      },

      backgroundColor: datapointColor,
      borderColor: 'black',
      showLine: false,
    };

    this.chartData.push(data as never);

    if (this.chart) {
      this.chart.chart.update();
    }
  }

  /**
   * Shows only selected dataset on the plot
   * @param event Dropdown menu selection changed
   */
  public selectionChanged(event) {
    for (let dataset of this.chart.chart.data.datasets) {
      if ((dataset as any).meta == event.value) {
        dataset.hidden = false;
      } else {
        dataset.hidden = true;
      }
    }
    this.chart.update();
  }

  public dateRangeSelectionChanged(event) {
    if (event.value === -1) {
      this.customDateRange = true;
    } else {
      this.customDateRange = false;
    }
    this.reloadGraph(true);
  }

  public customDateFilterChanged(event) {
    if (Date.now() - this.lastCustomDateRange > 100) {
      this.lastCustomDateRange = Date.now();
      this.reloadGraph(true);
    }
  }

  private reloadGraph(dateChange: boolean = false) {
    this.emptyArray(this.chartData);
    this.emptyArray(this.chartLabels);
    this.emptyArray(this.labelsComplete);

    this.loadedDatasets.clear();

    // Fetch all the data again with the new date range
    if (dateChange) {
      this.isLoading = true;
      this.spinner.show();
      this.availableFields.clear();
      this.availableSensors.clear();
      this.loadedDatasets.clear();

      this.loadData(true);
    }

    // this.loadDatasetAndVisualise();
  }

  private emptyArray(array: any[]) {
    while (array.length) {
      array.pop();
    }
  }

  // Set white background color so the download looks nice
  // https://stackoverflow.com/a/50126796/
  private fillCanvasBackgroundWithColor(canvas, color) {
    const context = canvas.getContext('2d');
    context.save();
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  public onChartResize(event) {
    // Subtract 64px for the height of the legend
    this.legendDivHeight = `${this.chart.chart.height - 64}px`;
  }

  public async exportCurrentView(event) {
    this.chart.chart.options.devicePixelRatio = 2;
    this.chartWidth = '1920px';
    let countdown = 0;
    while (countdown < 2) {
      await timer(100).pipe(take(1)).toPromise();
      countdown++;
    }
    this.fillCanvasBackgroundWithColor(this.chart.chart.canvas, '#FFFFFF');

    let a = document.createElement('a');
    a.href = this.chart.chart.toBase64Image('image/png', 1);
    let date = new Date();
    a.download = `${this.reportModuleService.currentReport.name}_${date
      .toLocaleDateString('de-AT')
      .replace(/\./g, '_')}.png`;

    a.click();
    this.chart.chart.options.devicePixelRatio = 1;
    this.chartWidth = '100%';
  }

  public getParameterDisplayName(field): string {
    if (this.datasetDetails.size === 0) {
      return '';
    } else if (this.datasetDetails.has(field)) {
      return this.availableSensors.get(field).display_name;
    }
    return 'N/A';
  }

  public getMinDate(): Date {
    let result = this.minDates.get(this.selectedFieldControl.value);
    if (!result) {
      result = new Date(0);
    }

    return result;
  }

  public getMaxDate(): Date {
    let result = this.maxDates.get(this.selectedFieldControl.value);
    if (!result) {
      result = new Date();
    }

    return result;
  }

  public fieldHasEnabledSensors(field: string): boolean {
    let sensors = this.config.dataset.filter((f) => f.id === field);
    if (!sensors) {
      return false;
    }

    let enabledSensors = sensors.filter((s) => s.checked);
    return enabledSensors.length > 0;
  }
}
