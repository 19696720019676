import { User } from '../api/generated';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'MinMaxOrdinateFilter',
  pure: false,
})
export class MinMaxOrdinateFilter implements PipeTransform {
  transform(items: any[], filter: any[]): any {
    if (!items || !filter) {
      return items;
    }

    filter = filter.map((item) => item.fieldName);

    return items.filter((item) => filter.includes(item.value.id));
  }
}

export function isUserAdmin(user: User): boolean {
  if (!user.roles) {
    return false;
  }

  for (let role of user.roles as any) {
    if (role?.name == 'trigonos-admin') {
      return true;
    }
  }
  return false;
}

export function toNumber(number: string | number): number {
  if (typeof number !== 'string') {
    number = number.toString();
  }

  const n = (number as string).replace(/\./g, '').replace(',', '.');
  return Number(n);
}

export function toNumberFromENString(number: string | number): number {
  if (typeof number !== 'string') {
    number = number.toString();
  }

  const n = number.replace(',', '.');
  return parseFloat(n);
}

export function toNumberStringDE(number: string | number): string {
  if (typeof number !== 'string') {
    number = number.toString();
  }

  return number.replace('.', ',');
}
