import { ReadVarExpr } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ManagementService, UsersSelection } from 'src/app/api/generated';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DataChangedService } from 'src/app/data-changed.service';
import {
  hexColorValidator,
  requiresAtLeastOneUser,
} from 'src/app/tools/validators';
import { UserService } from 'src/app/user.service';
import { environment } from 'src/environments/environment';

import * as uuid from 'uuid';

declare module ClientDialogSettings {
  export interface UserSettings {
    measuring_date: string;
    measured_margin: string;
    margin_warning: string;
  }

  export interface Config {
    guid: string;
    type: string;
    dataset: string;
    headline: string;
    user_settings: UserSettings;
  }

  export interface Report {
    id: string;
    name: string;
    config: Config[];
    project: string;
  }

  export interface Project {
    id: string;
    name: string;
    description: string;
    transformation_from: string;
    transformation_to: string;
    assigned_client: string;
    reports: Report[];
  }

  export interface Client {
    id: string;
    name: string;
    description: string;
    logo_path?: any;
    primary_color?: any;
    secondary_color?: any;
    projects: Project[];
  }
}

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss'],
})
export class ClientDialogComponent implements OnInit {
  public clientForm: UntypedFormGroup;
  public availableUsers: UsersSelection[];
  public logoPreview;
  public logoFormControl = new UntypedFormControl();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClientDialogSettings.Client,
    private managementService: ManagementService,
    private dataChangedService: DataChangedService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      id: [!this.data.id ? '' : this.data.id],
      name: [!this.data.name ? '' : this.data.name, [Validators.required]],
      description: [!this.data.description ? '' : this.data.description],
      primary_color: [
        !this.data.primary_color ? '' : this.data.primary_color,
        [hexColorValidator()],
      ],
      secondary_color: [
        !this.data.secondary_color ? '' : this.data.secondary_color,
        [hexColorValidator()],
      ],
      users: this.formBuilder.array([]),
      logo: this.logoFormControl,
      logoSource: [''],
    });

    if (this.data.logo_path) {
      const logoURL = this.sanitizer.bypassSecurityTrustUrl(
        `${this.data.logo_path}`
      );
      this.logoPreview = logoURL;
    }

    this.managementService
      .managementUsersInClientRead(
        !this.data.id ? '00000000-0000-0000-0000-000000000000' : this.data.id
      )
      .subscribe((users) => {
        this.availableUsers = users;
        this.availableUsers.forEach((user) =>
          this.usersFromFormGroup().push(new UntypedFormControl(true))
        );
      });
  }

  public onFileChange(event) {
    const fileReader = new FileReader();

    if (event.target.files?.length > 0) {
      const [file] = event.target.files;
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result]);
        const url = URL.createObjectURL(blob);
        const sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.logoPreview = sanitizedUrl;
        this.clientForm.get('logoSource').patchValue(blob);
      };
    }
  }

  public updateOrCreateClient() {
    if (!this.clientForm.valid) {
      console.error(this.clientForm.errors);
      return;
    }
    let assignedUsers = this.clientForm.value.users
      .map((enabled, index) => {
        if (enabled) {
          return this.availableUsers[index].id;
        }
      })
      .filter(Boolean);
    let assignedUsersString: Set<string> = new Set([assignedUsers.join(';')]);
    if (this.data.id) {
      this.managementService
        .managementClientUpdate(
          this.clientForm.value.id,
          this.clientForm.value.name,
          assignedUsersString,
          this.clientForm.value.description,
          this.clientForm.value.logoSource,
          this.clientForm.value.primary_color,
          this.clientForm.value.secondary_color
        )
        .subscribe((success) => {
          this.dataChangedService.dataUpdated('clients');
          this.snackBar.open(
            `Auftraggeber ${this.clientForm.value.name} aktualisiert.`,
            null,
            {
              duration: 1500,
            }
          );
          this.dialogRef.close();
        });
    } else {
      this.managementService
        .createTrigonosClient(
          // UUID v4,
          uuid.v4(),
          this.clientForm.value.name,
          assignedUsersString,
          this.clientForm.value.description,
          this.clientForm.value.logoSource,
          this.clientForm.value.primary_color,
          this.clientForm.value.secondary_color,
          this.clientForm.value.logoSource
        )
        .subscribe((success) => {
          this.dataChangedService.dataUpdated('clients');
          this.snackBar.open(
            `Auftraggeber ${this.clientForm.value.name} erstellt.`,
            null,
            {
              duration: 1500,
            }
          );
          this.dialogRef.close();
        });
    }
  }

  public deleteClient(event: MouseEvent) {
    if (!this.data.id) {
      return;
    }

    // Confirm deletion
    let deleteDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        headlineText: `Auftraggeber ${this.data.name} endgültig entfernen? `,
        confirmationButtonText: 'Ja',
        rejectButtonText: 'Nein',
        displayText: `ACHTUNG: Es werden ALLE Projekte und Reports des Auftraggebers gelöscht.`,
      },
    });
    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.managementService
          .managementClientDelete(this.data.id)
          .subscribe((res) => {
            this.dataChangedService.dataUpdated('clients');
          });
      }
    });
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private usersFromFormGroup(): UntypedFormArray {
    return this.clientForm.get('users') as UntypedFormArray;
  }
}
