<div class="container">
  <mat-form-field class="w-full">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Projekt oder Auftraggeber filtern"
      #input
    />
  </mat-form-field>

  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z8 w-full"
  >
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr.</th>
      <td mat-cell *matCellDef="let element">{{ element.position }}</td>
    </ng-container>

    <ng-container matColumnDef="customer_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Auftraggeber</th>
      <td mat-cell *matCellDef="let element">{{ element.customer_name }}</td>
    </ng-container>

    <ng-container matColumnDef="project_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Projekt</th>
      <td mat-cell *matCellDef="let element">{{ element.project_name }}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktiv</th>
      <td mat-cell *matCellDef="let element">
        {{ element.active ? "Ja" : "Nein" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="clickedRow(row)"
    ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        Keine Daten gefunden für Filter: "{{ input.value }}"
      </td>
    </tr>
  </table>
</div>
