import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { environment } from 'src/environments/environment';
import { WebcamConfigComponent } from '../config/webcam-config/webcam-config.component';
import {
  WebcamLocationTimestamp,
  WebcamLocationDetails,
  WebcamConfig,
} from '../webcam.tools';
import { DefaultService } from 'src/app/api/webcam';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-webcam-viewer',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss'],
})
export class WebcamComponent implements OnInit, AfterViewInit {
  @Input() config: WebcamConfig;
  @ViewChild('image') image: ElementRef;

  public availableLocation: WebcamLocationDetails;
  public availableTimestamps: WebcamLocationTimestamp[] = [];
  public currentTimestamp: number = 0;

  public imageToShow: SafeUrl = '';
  public autoPlayInterval: any;

  public static moduleDetails: ModuleConfiguration = {
    displayName: 'Webcam mit Historie',
    previewImagePath: 'assets/image_viewer_preview.png',
    settingsComponent: WebcamConfigComponent,
  };

  private autoPlaySubject = new BehaviorSubject<boolean>(false);
  autoPlayIsRunning$ = this.autoPlaySubject.asObservable();

  constructor(
    private domSanitizer: DomSanitizer,
    public reportModuleService: ReportModulesService,
    public userService: UserService,
    private webcamService: DefaultService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {}

  skipToNextFrame = () => {
    if (this.currentTimestamp < this.availableTimestamps.length - 1) {
      this.currentTimestamp++;
      this.onSliderChange(null);
    } else {
      this.currentTimestamp = 0;
      this.onSliderChange(null);
    }
  };

  skipToPreviousFrame = () => {
    if (this.currentTimestamp > 0) {
      this.currentTimestamp--;
      this.onSliderChange(null);
    } else {
      this.currentTimestamp = this.availableTimestamps.length;
      this.onSliderChange(null);
    }
  };

  toggleAutplay = () => {
    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
      this.autoPlayInterval = null;
    } else {
      this.autoPlayInterval = setInterval(() => {
        if (this.currentTimestamp < this.availableTimestamps.length - 1) {
          this.currentTimestamp++;
          this.onSliderChange(null);
        } else {
          this.currentTimestamp = 0;
          this.onSliderChange(null);
        }
      }, 1000);
    }

    // Whenever autoPlayInterval changes
    this.autoPlaySubject.next(this.autoPlayInterval !== null);
  };

  get autoPlayIsRunning() {
    return this.autoPlayInterval !== null;
  }

  formatSliderLabel = (value: number) => {
    if (!this.availableTimestamps || this.availableTimestamps.length === 0) {
      return '';
    }

    return this.datePipe.transform(
      this.availableTimestamps[value].timestamp,
      'dd.MM.yy HH:mm'
    );
  };

  onSliderChange = ($event) => {
    if (!this.availableTimestamps || this.availableTimestamps.length === 0) {
      return;
    }

    this.webcamService
      .getLocationsIdTimestampApiLocationsLocationIdTimestampGet(
        this.availableLocation.id,
        this.availableTimestamps[this.currentTimestamp].timestamp
      )
      .subscribe((data) => {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(
              reader.result as string
            );
          },
          false
        );

        if (data) {
          reader.readAsDataURL(data);
        }
      });
  };

  ngOnInit(): void {
    this.webcamService
      .getLocationsApiLocationsGet()
      .subscribe((locations: WebcamLocationDetails[]) => {
        for (const location of locations) {
          if (location.id === this.config.location) {
            this.availableLocation = location;
          }
        }
      });

    this.webcamService
      .getLocationsIdTimestampsApiLocationsLocationIdTimestampsGet(
        this.config.location
      )
      .subscribe((timestamps: WebcamLocationTimestamp[]) => {
        // Sort by timestamp string
        timestamps.sort((a, b) => {
          return a.timestamp.localeCompare(b.timestamp);
        });
        this.availableTimestamps.push(...timestamps);
        this.currentTimestamp = timestamps.length - 1;
        this.onSliderChange(null);
      });
    this.formatSliderLabel.bind(this);
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
    }
  }
}
