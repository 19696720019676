<h2 mat-dialog-title>Element hinzufügen</h2>
<mat-dialog-content>
  <!-- <div class="card-deck">
    <div class="card m-3" *ngFor="let module of availableModules">
      <img class="card-img-top" [src]="module.moduleDetails.previewImagePath" />
      <div class="card-body">
        <button mat-raised-button color="primary">
          {{ module.moduleDetails.displayName }}
        </button>
      </div>
    </div>
  </div> -->
  <div class="card-deck">
    <mat-card
      class="m-2"
      *ngFor="let module of availableModules; let i = index"
    >
      <mat-card-header></mat-card-header>
      <img mat-card-image [src]="module.moduleDetails.previewImagePath" />
      <mat-card-content>
        <button mat-raised-button color="primary" (click)="addModule(i)">
          <div class="d-flex flex-direction-column align-items-center">
            {{ module.moduleDetails.displayName }}
            <mat-icon class="ml-auto">add</mat-icon>
          </div>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    Schließen
  </button>
</mat-dialog-actions>
