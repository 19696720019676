import { Component, OnInit } from '@angular/core';
import {
  DataChangedService,
  HEADLINE_TYPE,
} from 'src/app/data-changed.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private dataChangedService: DataChangedService) {}

  ngOnInit(): void {
    this.dataChangedService.setHeadline({
      headline: '',
      type: HEADLINE_TYPE.GENERIC,
      image: null,
      subheadline: null,
    });
  }
}
