<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ config.headline }}
    </mat-card-title>
    <mat-card-subtitle> {{ availableLocation?.name }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content class="max-w-5xl">
    <img class="max-w-full" [src]="imageToShow" *ngIf="imageToShow" />
    <div class="flex flex-row items-center justify-around mt-4">
      <mat-icon class="cursor-pointer" (click)="skipToPreviousFrame()">
        skip_previous
      </mat-icon>
      <mat-icon class="cursor-pointer" (click)="toggleAutplay()">
        {{ (autoPlayIsRunning$ | async) ? "pause_circle" : "play_circle" }}
      </mat-icon>
      <mat-icon class="cursor-pointer" (click)="skipToNextFrame()">
        skip_next
      </mat-icon>
    </div>

    <mat-slider
      min="0"
      [max]="availableTimestamps?.length - 1"
      step="1"
      showTickMarks
      discrete
      [displayWith]="formatSliderLabel"
      *ngIf="availableTimestamps?.length > 0"
      style="width: 100%"
      (change)="onSliderChange($event)"
    >
      <input matSliderThumb [(ngModel)]="currentTimestamp" #slider />
    </mat-slider>
    <div class="flex flex-row justify-center">
      <p>
        {{ availableTimestamps[currentTimestamp]?.timestamp | date : "medium" }}
        Uhr
      </p>
    </div>
  </mat-card-content>

  <mat-card-actions *ngIf="userService.userIsAdmin">
    <button
      mat-button
      (click)="reportModuleService.openEditReportModuleForm(config.guid)"
    >
      BEARBEITEN
    </button>
    <button
      (click)="reportModuleService.removeModuleFormReport(config.guid)"
      mat-button
    >
      LÖSCHEN
    </button>
  </mat-card-actions>
</mat-card>
