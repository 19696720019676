import { Injectable } from '@angular/core';
// import { concat, DataFrame, toCSV, toJSON } from 'danfojs';
import { forkJoin, Observable } from 'rxjs';
import {
  DataPoint,
  DataService,
  ManagementService,
  TrigonosDatasource,
} from './api/generated';
import {
  CorrelationConfig,
  DataPointGnss,
} from './modules/correlation/correlation.tools';
import { CorrelationFissurometerConfig } from './modules/correlation_fissurometer/correlation_fissurometer.tools';
import {
  InverseVelocityConfig,
  parseGNSSData,
} from './modules/inverse_velocity/inverse_velocity.tools';
import { ReportModulesService } from './services/report-modules/report-modules.service';

interface startEndDate {
  startDate: string;
  endDate: string;
}
type DataEntry = {
  _time: string;
  [key: string]: string | number;
};

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  constructor(
    private dataService: DataService,
    private managementService: ManagementService,
    private reportModuleService: ReportModulesService
  ) {}

  // Merges two objects with unique keys
  private mergeObjects(obj1: DataEntry, obj2: DataEntry): DataEntry {
    return { ...obj1, ...obj2 };
  }

  // Combines and sorts lists based on _time property
  private combineAndSortLists(lists: DataEntry[][]): DataEntry[] {
    const combined: DataEntry[] = [].concat(...lists);
    return combined.sort(
      (a, b) => new Date(a._time).getTime() - new Date(b._time).getTime()
    );
  }

  // Performs a full outer join on the sorted list
  private outerJoin(sortedList: DataEntry[]): DataEntry[] {
    const result: DataEntry[] = [];
    const timesSeen: { [key: string]: boolean } = {};

    for (let item of sortedList) {
      if (timesSeen[item._time]) {
        result[result.length - 1] = this.mergeObjects(
          result[result.length - 1],
          item
        );
      } else {
        result.push(item);
        timesSeen[item._time] = true;
      }
    }

    return result;
  }

  // Converts the joined list to CSV format
  private listToCSV(joinedList: DataEntry[]): string {
    const allKeys: Set<string> = new Set();
    joinedList.forEach((item) => {
      Object.keys(item).forEach((key) => allKeys.add(key));
    });

    const header: string = [...allKeys].join(',');
    const rows: string[] = joinedList.map((item) => {
      return [...allKeys].map((key) => item[key] || '').join(',');
    });

    return [header, ...rows].join('\n');
  }

  private downloadCSV(csvContent: string, filename: string) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = filename || 'data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async generateCSVExport(datasets: DataEntry[][]) {
    const sortedList: DataEntry[] = this.combineAndSortLists(datasets);
    const joinedList: DataEntry[] = this.outerJoin(sortedList);
    const csv: string = this.listToCSV(joinedList);
    this.downloadCSV(csv, 'data.csv');
  }
}
