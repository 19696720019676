export interface UserSettings {
  measuring_date: string;
  measured_margin: string;
  margin_warning: string;
}

export interface Config {
  guid: string;
  type: string;
  dataset: string;
  headline: string;
  user_settings: UserSettings;
}

export interface Report {
  id: string;
  config: Config[];
  project: string;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  transformation_from: string;
  transformation_to: string;
  assigned_client: string;
  reports: Report[];
}

export interface Clients {
  id: string;
  name: string;
  description: string;
  logo_path?: any;
  primary_color?: any;
  secondary_color?: any;
  projects: Project[];
}

export interface TreeNode {
  name: string;
  children: TreeNode[];
  id: string;
  type: SidenavElement;
}

export interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: string;
}

export enum SidenavElement {
  CLIENT,
  PROJECT,
  REPORT,
  ADD_REPORT_BUTTON,
}
