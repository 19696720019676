<h2 *ngIf="!editMode" mat-dialog-title>Modul Beschreibung hinzufügen</h2>
<h2 *ngIf="editMode" mat-dialog-title>Modul Beschreibung editieren</h2>
<mat-dialog-content>
  Editor unterstützt Markdown Syntax -
  <a href="https://www.markdownguide.org/cheat-sheet/">Dokumentation</a>
  <md-editor
    #md="ngModel"
    name="content"
    [(ngModel)]="content"
    [mode]="mode"
    [options]="editorOptions"
    required
    maxlength="600"
    height="400px"
  ></md-editor>
  <form
    [formGroup]="markdownForm"
    (ngSubmit)="updateOrCreateModule()"
    ngNativeValidate
    class="d-flex flex-column"
  >
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!markdownForm.valid"
    >
      <ng-template [ngIf]="editMode">Speichern</ng-template>
      <ng-template [ngIf]="!editMode">Hinzufügen</ng-template>
    </button>
  </form>
</mat-dialog-content>
